import {LetturaAttivita} from './lettura_attivita';
import {LetturaRilevazione} from './lettura_rilevazione';

export interface Lettura {
    id: number;
    extra: string;
    lavorazione_id: number;
    appalto_id: number;
    chiave_univoca: string;
    progressivo: number;
    sequenza: number;
    stato: string;
    carico: number;
    terminale: number;
    pdr: number;
    zona: number;
    libro: number;
    pagina: number;
    codice_utente: number;
    utente: string;
    utente_nuovo?: any;
    ordine_vie: number;
    ordine_civici: number;
    ordine_utenti: number;
    toponomastica: string;
    indirizzo: string;
    indirizzo_nuovo?: any;
    civico: string;
    civico_nuovo?: any;
    barrato: string;
    barrato_nuovo?: any;
    scala: string;
    piano: string;
    interno: string;
    localita: string;
    localita_nuova?: any;
    comune: string;
    comune_nuovo?: any;
    cap: string;
    provincia?: string;
    progmis: number;
    periodicita: string;
    fornitura: string;
    misuratore: string;
    matricola: string;
    matricola_nuova?: any;
    cifre: string;
    cifre_nuove?: any;
    cifre_correttore?: any;
    cifre_correttore_nuove?: any;
    anno?: any;
    anno_nuovo?: any;
    acc: string;
    acc_nuova?: any;
    locazione?: any;
    locazione_nuova?: any;
    classe: number;
    classe_nuova?: any;
    categoria_merceologica?: any;
    categoria_merceologica_nuova?: any;
    fabbricante?: any;
    fabbricante_nuovo?: any;
    modello: number;
    modello_nuovo?: any;
    marca: number;
    marca_nuova?: any;
    matricola_correttore?: any;
    matricola_correttore_nuova?: any;
    tipo_quadrante?: any;
    tipo_quadrante_nuovo?: any;
    portata?: any;
    portata_nuova?: any;
    note?: any;
    note_nuove?: any;
    ubicazione: string;
    ubicazione_nuova?: any;
    stato_contatore: string;
    lettura_precedente_ente: string;
    data_lettura_precedente_ente: string;
    lettura_precedente_ditta?: any;
    data_lettura_precedente_ditta?: any;
    lettura_precedente_correttore?: any;
    consumo_annuo?: any;
    consumo_gg?: any;
    letmin: string;
    letmax: string;
    data_primo_passaggio?: any;
    ora_primo_passaggio?: any;
    data_lettura?: any;
    ora_lettura?: any;
    lettura?: any;
    lettura_correttore?: any;
    lettura_corretta?: any;
    consumo?: any;
    segn1?: any;
    segn1_causa?: any;
    segn2?: any;
    segn2_causa?: any;
    segn3?: any;
    segn3_causa?: any;
    flag?: any;
    nota?: any;
    tentativi?: any;
    flag_foto_1: number;
    flag_foto_2: number;
    flag_foto_3: number;
    oltre_al_max: number;
    delta_tempo: number;
    editata_da_programma?: any;
    numero_satelliti?: any;
    latitudine: string;
    longitudine: string;
    indice_file: number;
    codice_ente: number;
    numero_invio: number;
    autolettura: number;
    lettura_eseguita?: any;
    flag_controllata?: any;
    data_reale_lettura?: any;
    censimenti?: any;
    numero_invio_autoletture?: any;
    base_di_computo: string;
    profilo_di_prelievo: string;
    ANNOMESE_STIPENDIO: string;
    materiale: number;
    materiale_nuovo: number;
    data_secondo_passaggio: string;
    ora_secondo_passaggio: string;
    data_inizio: string;
    data_fine: string;
    flag_contatore_padre: number;
    flag_contatore_figlio: number;
    matricola_contatore_padre: string;
    presenza_sigillo: number;
    controllata_da?: any;
    progressivo_istruzione: number;
    modalita_preavviso: number;
    contatto_preavviso: string;
    fascia_oraria: string;
    esito_contatto?: any;
    altitudine?: any;
    accuratezza_gps?: any;
    batteria?: any;
    flag_foto_4: number;
    flag_foto_5: number;
    flag_fuori_giro: number;
    retry_lettura_anomala: number;
    data_ora_appuntamento: string;
    col1?: any;
    col2?: any;
    col3?: any;
    col4?: any;
    col5?: any;
    col6?: any;
    col7?: any;
    col8?: any;
    col9?: any;
    col10?: any;
    attivita: LetturaAttivita[];
    rilevazioni: LetturaRilevazione[];
}

export enum LetturaStato {
    NUL = 'NUL', ASS = 'ASS', RIS = 'RIS', CAR = 'CAR', LET = 'LET', SPE = 'SPE', TEL = 'TEL'
}

export enum LetturaModalitaPreavviso {
  NONE, SMS, EMAIL, CALL, CARTEL
}

