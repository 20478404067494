import { Component, OnInit } from '@angular/core';
import { BaseTableComponent } from '../base-table/base-table.component';
import { Lettura } from '../../../../models/raw/lettura';
import { Letturista } from '../../../../models/raw/letturista';
import { Router } from '@angular/router';
import { LettureService } from '../../../services/letture.service';
import { SortDirection } from '@angular/material/sort';
import { And, Logical } from '../../../../models/query-lang/logical';
import { EnteService } from '../../../services/ente.service';
import { ColumnFilter, ColumnFilterType } from '../../partials/filters/filters.component';

@Component({
  selector: 'app-letture',
  templateUrl: './letture.component.html',
  styleUrls: ['../base-table/base-table.component.scss', './letture.component.scss']
})
export class LettureComponent extends BaseTableComponent<Lettura> implements OnInit {

  public filters: ColumnFilter[] = [
    { column: 'zona', label: 'Zona', type: ColumnFilterType.STRING },
    { column: 'libro', label: 'Libro', type: ColumnFilterType.STRING },
    { column: 'pagina', label: 'Pagina', type: ColumnFilterType.STRING },
    { column: 'matricola', label: 'Matricola', type: ColumnFilterType.STRING },
    { column: 'data_installazione_contatore', label: 'Data installazione', type: ColumnFilterType.DATE },
  ];

  constructor(private enteService: EnteService, public lettureService: LettureService, private router: Router) {
    super(
      [
        { name: 'id', isFilterable: false },
        { name: 'progressivo', isFilterable: true },
        { name: 'sequenza', isFilterable: true },
        { name: 'utente', isFilterable: true },
        { name: 'matricola', isFilterable: true },
        { name: 'data_ora_appuntamento', isFilterable: false },
      ],
      { active: 'id', direction: 'desc' as SortDirection }
    );

    this.selectOnlyColumns = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.loader = this.lettureService.loader;
  }

  public async onFiltersUpdated(filters: Logical) {
    this.baseQuerySubject.next(new And([...filters.args]));
    this.loader.loadNext();
  }

  public onLetturistaSelected(letturista: Letturista): void {
    console.log(letturista);
  }

  public onLetturaSelected(lettura: Lettura): void {
    this.lettureService.loader = this.loader;
    this.router.navigate(['letture', lettura.id]);
  }
}
