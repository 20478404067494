import {Component, OnInit} from '@angular/core';
import {ColumnFilter, ColumnFilterType} from '../../partials/filters/filters.component';
import {DataLoaderProviderService} from '../../../services/data-loader-provider.service';
import {MatDialog} from '@angular/material/dialog';
import {EnteService} from '../../../services/ente.service';
import {LoadingOverlayService} from '../../../services/loading-overlay.service';
import {HttpClient} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SortDirection} from '@angular/material/sort';
import {Ente} from '../../../../models/raw/ente';
import {And, Logical} from '../../../../models/query-lang/logical';
import {Equal} from '../../../../models/query-lang/comparison';
import {BaseTableComponent} from '../base-table/base-table.component';
import {MagazzinoConsumabile} from '../../../../models/raw/magazzino-consumabile';
import {FormFieldType, SimpleFormDialogComponent} from '../../dialogs/simple-form-dialog/simple-form-dialog.component';
import {environment} from '../../../../environments/environment';
import {SimpleAlertDialogComponent} from '../../dialogs/simple-alert-dialog/simple-alert-dialog.component';
import {ConsumabiliTerminaliDialogComponent} from '../../dialogs/consumabili-terminali-dialog/consumabili-terminali-dialog.component';

@Component({
  selector: 'app-magazzino-consumabili',
  templateUrl: './magazzino-consumabili.component.html',
  styleUrls: ['./magazzino-consumabili.component.scss', '../base-table/base-table.component.scss']
})
export class MagazzinoConsumabiliComponent extends BaseTableComponent<MagazzinoConsumabile> implements OnInit {
  public filters: ColumnFilter[] = [
    {column: 'id', label: 'ID', type: ColumnFilterType.NUMBER},
    {column: 'articolo', label: 'Articolo', type: ColumnFilterType.STRING}
  ];

  constructor(private dataLoaderProvider: DataLoaderProviderService,
              private dialog: MatDialog, private enteService: EnteService,
              private loadingOverlay: LoadingOverlayService,
              private http: HttpClient,
              private snackBar: MatSnackBar) {
    super(
      [
        {name: 'id', isFilterable: false},
        {name: 'articolo', isFilterable: true},
        {name: 'quantita', isFilterable: false},
        {name: 'quantitaDisponibile', isFilterable: false},
      ],
      {active: 'id', direction: 'asc' as SortDirection}
    );
    this.selectOnlyColumns = false;
    this.loader = this.dataLoaderProvider.getMagazzinoConsumabileLoader();
    this.displayedColumns.push('actions');

    this.onEnteChanged(this.enteService.ente.value);
    this.enteService.ente.subscribe(ente => {
      this.onEnteChanged(ente);
    });
  }

  public async onAddButtonClicked(): Promise<void> {
    const result = await this.dialog.open(SimpleFormDialogComponent, {
      data: {
        fields: [
          {
            column: 'articolo',
            label: 'Articolo',
            type: FormFieldType.STRING,
            required: true
          },
          {
            column: 'quantita',
            label: 'Quantità',
            type: FormFieldType.NUMBER,
            required: true
          }
        ]
      }
    }).afterClosed().toPromise();

    if (result) {
      this.loadingOverlay.show();

      try {
        await this.http.post(`${environment.baseUrl}api/client/magazzino_consumabili`, {
          articolo: result.articolo,
          quantita: result.quantita,
          ente_id: this.enteService.ente.value.ente_id
        }).toPromise();
        this.snackBar.open('Articolo aggiunto con successo!', null, {duration: 5000});

      } catch (e) {
        this.snackBar.open('Impossibili completare l\' operazione dell\' articolo inserito. Riprova più tardi.', null, {duration: 5000});
      }
      this.loadingOverlay.hide();

      this.loader = this.loader.newQuery(this.loader.currentQuery);
      this.loader.loadNext();
    }
  }

  public async onEditButtonButtonClicked(consumabile: MagazzinoConsumabile): Promise<void> {
    const result = await this.dialog.open(SimpleFormDialogComponent, {
      data: {
        fields: [
          {
            column: 'articolo',
            label: 'Articolo',
            type: FormFieldType.STRING,
            required: true,
            defaultValue: consumabile.articolo
          },
          {
            column: 'quantita',
            label: 'Quantità',
            type: FormFieldType.NUMBER,
            required: true,
            defaultValue: consumabile.quantita
          }
        ]
      }
    }).afterClosed().toPromise();

    if (result) {
      this.loadingOverlay.show();

      try {
        await this.http.patch(`${environment.baseUrl}api/client/magazzino_consumabili/${consumabile.id}`, {
          articolo: result.articolo,
          quantita: result.quantita,
          ente_id: this.enteService.ente.value.ente_id
        }).toPromise();
        this.snackBar.open('Articolo modificato con successo!', null, {duration: 5000});

      } catch (e) {
        this.snackBar.open('Impossibili completare l\' operazione dell\' articolo selezionato. Riprova più tardi.', null, {duration: 5000});
      }
      this.loadingOverlay.hide();

      this.loader = this.loader.newQuery(this.loader.currentQuery);
      this.loader.loadNext();
    }
  }

  public async onDeleteButtonClicked(consumabile: MagazzinoConsumabile): Promise<void> {
    const result = await this.dialog.open(SimpleAlertDialogComponent, {
      data: {
        title: 'Attenzione!',
        content: 'Eliminando l\' articolo, verranno eliminati anche quelli assegnati ai terminali'
      }

    }).afterClosed().toPromise();

    if (result) {
      this.loadingOverlay.show();

      try {
        await this.http.delete(`${environment.baseUrl}api/client/magazzino_consumabili/${consumabile.id}`).toPromise();
        this.snackBar.open('Articolo cancellato con successo!', null, {duration: 5000});

      } catch (e) {
        this.snackBar.open('Impossibili completare l\' operazione dell\' articolo selezionato. Riprova più tardi.', null, {duration: 5000});
      }
      this.loadingOverlay.hide();

      this.loader = this.loader.newQuery(this.loader.currentQuery);
      this.loader.loadNext();
    }

  }

  public async onAddTerminalButtonButtonClicked(consumabile: MagazzinoConsumabile): Promise<void> {
    const result = await this.dialog.open(SimpleFormDialogComponent, {
      data: {
        fields: [
          {
            column: 'terminale',
            label: 'Terminale',
            type: FormFieldType.STRING,
            required: true,
          },
          {
            column: 'quantita',
            label: 'Quantità',
            type: FormFieldType.NUMBER,
            required: true,
          }
        ]
      }
    }).afterClosed().toPromise();

    if (result) {
      this.loadingOverlay.show();

      try {

        if (result.quantita > consumabile.quantitaDisponibile) {
          this.snackBar.open('Quantità indicata superiore alla giacenza', null, {duration: 5000});
          this.loadingOverlay.hide();
          return;
        }

        if (result.quantita < 0) {
          this.snackBar.open('Quantità indicata minore di 0', null, {duration: 5000});
          this.loadingOverlay.hide();
          return;
        }

        await this.http.post(`${environment.baseUrl}api/client/magazzino_consumabili_terminali/${consumabile.id}/aggiungi_rimuovi_quantita_terminale`, {
          quantita: result.quantita,
          terminale: result.terminale
        }).toPromise();
        this.snackBar.open('Articolo assegnato con successo!', null, {duration: 5000});

      } catch (e) {
        this.snackBar.open('Impossibili completare l\' operazione dell\' articolo selezionato. Riprova più tardi.', null, {duration: 5000});
      }
      this.loadingOverlay.hide();

      this.loader = this.loader.newQuery(this.loader.currentQuery);
      this.loader.loadNext();
    }
  }

  public async onGetArticleTerminalsButtonClicked(consumabile: MagazzinoConsumabile): Promise<void> {
    let articoli;
    try {
      this.loadingOverlay.show();
      articoli = await this.http.get(`${environment.baseUrl}api/client/magazzino_consumabili_terminali/${consumabile.id}`).toPromise();
      this.loadingOverlay.hide();
      const removeToTerminal = await this.dialog.open(ConsumabiliTerminaliDialogComponent, {
        data: {
          articoli,
          consumabile
        }
      }).afterClosed().toPromise();

      if (removeToTerminal) {
        this.loader = this.loader.newQuery(this.loader.currentQuery);
        this.loader.loadNext();
      }

    } catch (e) {
      this.snackBar.open('Impossibili completare l\' operazione dell\' articolo selezionato. Riprova più tardi.', null, {duration: 5000});
      this.loadingOverlay.hide();
    }
  }

  public onFiltersUpdated(filters: Logical): void {
    this.baseQuerySubject.next(new And([...filters.args, new Equal('ente_id', this.enteService.ente.value.ente_id)]));
    this.loader.loadNext();
  }

  private onEnteChanged(ente: Ente): void {
    this.baseQuerySubject.next(new And([new Equal('ente_id', ente.ente_id)]));
  }

}
