import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {FormFieldType, SimpleFormDialogComponent} from '../../dialogs/simple-form-dialog/simple-form-dialog.component';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {LoadingOverlayService} from '../../../services/loading-overlay.service';
import {Lavoro} from '../../../../models/raw/lavoro';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-functions',
  templateUrl: './functions.component.html',
  styleUrls: ['./functions.component.scss']
})
export class FunctionsComponent {
  get hasFunctions(): boolean {
    return this.userService.user.ufficio.config?.functions?.length > 0 ?? false;
  }

  constructor(private dialog: MatDialog,
              private http: HttpClient,
              private loadingOverlay: LoadingOverlayService,
              private snackBar: MatSnackBar,
              public userService: UserService) { }

  public checkFunction(func: string): boolean {
    return this.userService.user.ufficio.config?.functions?.includes(func) ?? false;
  }

  public async a2aDailyReport(): Promise<void> {
    const answer = await this.dialog.open(SimpleFormDialogComponent, {data: {
      fields: [
        {
          column: 'date',
          label: 'Data',
          required: true,
          type: FormFieldType.DATE,
        }
      ]
    }}).afterClosed().toPromise();
    if (answer) {
      this.loadingOverlay.show();
      try {
        const lavoro = await this.http.get<Lavoro>(`${environment.baseUrl}api/client/customizations/sologas/a2a_daily_report?date=${answer.date.format('YYYY-MM-DD')}`).toPromise();
        if (lavoro?.id) {
          this.snackBar.open('Operazione avviata con successo. Riceverai una notifica una volta completata.',  null,{ duration: 5000 });
        } else {
          this.snackBar.open('Errore durante l’avvio dell’operazione. Riprova più tardi.',  null,{ duration: 5000 });
        }
      } catch (e) {
        this.snackBar.open('Errore durante l’avvio dell’operazione. Riprova più tardi.',  null,{ duration: 5000 });
      } finally {
        this.loadingOverlay.hide();
      }
    }
  }
}
