import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ImmutableDataLoader} from '../../../../models/data-loader';
import {FormControl} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss']
})
export class SearchDialogComponent<T> implements OnInit {
  private querySubject: BehaviorSubject<string> = new BehaviorSubject('');
  public columnsToDisplay: string[];
  public dataLoader: ImmutableDataLoader<T>;

  public searchFormControl: FormControl = new FormControl('');

  constructor(@Inject(MAT_DIALOG_DATA) public data: PayLoad<T>, private dialogRef: MatDialogRef<SearchDialogComponent<T>>) { }

  ngOnInit() {
    this.columnsToDisplay = this.data.columns.map(c => c.databaseName);
    this.dataLoader = this.data.dataLoaderBuilder('');
    this.dataLoader.loadNext();

    this.querySubject.pipe(debounceTime(300)).subscribe(value => {
      this.dataLoader.cancel();
      this.dataLoader = this.data.dataLoaderBuilder(value);
      this.dataLoader.loadNext();
    });
  }

  public onSearchChanged(value: string): void {
    this.querySubject.next(value);
  }

  public onTableScrolled(): void {
    this.dataLoader.loadNext();
  }

  public onItemSelected(item: T) {
    this.dialogRef.close(item);
  }
}

export interface PayLoad<T> {
  dataLoaderBuilder: (search: string) => ImmutableDataLoader<T>;
  columns: Column[];
}

export interface Column {
  databaseName: string;
  label: string;
}
