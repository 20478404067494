import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { COMMA, TAB } from '@angular/cdk/keycodes';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { MatChipInputEvent } from '@angular/material/chips';
import { And, Logical, Or } from '../../../../models/query-lang/logical';
import { Equal, Like } from '../../../../models/query-lang/comparison';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.css']
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class FiltersComponent<T> implements OnInit {
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  public chipContents = [];
  public formGroup: FormGroup;

  @Input()
  public filters: (ColumnFilter)[] = [];

  @Output()
  public filtersChanged = new EventEmitter<Logical>();

  readonly separatorKeysCodes: number[] = [TAB, COMMA];

  constructor(private formBuilder: FormBuilder) {
  }

  private static isColumnFilter(filter: any): filter is ColumnFilter {
    // eslint-disable-next-line no-prototype-builtins
    return filter.hasOwnProperty('type');
  }

  ngOnInit(): void {
    const group = {};
    for (const filter of this.filters) {
      group[filter.column] = [FiltersComponent.isColumnFilter(filter) && filter.defaultValue !== undefined
        ? filter.defaultValue
        : ''
      ];
    }

    this.formGroup = this.formBuilder.group(group);
  }

  public onClearButtonClicked(): void {
    this.formGroup.reset();
    this.filtersChanged.emit(new Or([]));
  }

  public onConfirmButtonClicked(): void {
    const orArgs = [];
    for (const filter of this.filters) {

      if (FiltersComponent.isColumnFilter(filter) && filter.type === ColumnFilterType.MULTIPLE_STRING) {
        orArgs.push(new Equal(filter.column, filter.trim ? this.chipContents.join(',').replace(/\s/g, '').trim() : this.chipContents.join(',')));

      } else if (this.formGroup.value[filter.column]?.toString()?.length > 0) {
        let search = this.formGroup.value[filter.column];
        if (FiltersComponent.isColumnFilter(filter) && filter.type === ColumnFilterType.DATE) {
          search = moment(this.formGroup.value[filter.column]).format('YYYY-MM-DD');//new Equal(filter.column, moment(this.formGroup.value[filter.column]).format('YYYY-MM-DD'));
          orArgs.push(new Like(filter.column, '%' + search + '%'));
        } else
          orArgs.push(new Equal(filter.column, search));
      }

    }

    this.filtersChanged.emit(new And(orArgs));
  }

  public addChip(event: MatChipInputEvent): void {
    const input = event.input;
    if (input) {
      input.value = '';
    }
  }

  public addPasteChip(event: ClipboardEvent): void {
    event.preventDefault();
    event.clipboardData.getData('Text')
      .split(/\r?\n/)
      .forEach(value => {
        if (value.trim()) {
          this.chipContents.push(value);
        }
      });
  }

  public removeChip(string: any): void {
    const index = this.chipContents.indexOf(string);

    if (index >= 0) {
      this.chipContents.splice(index, 1);
    }
  }

  public clearAllChips() {
    this.chipContents = [];
  }

}

export interface ColumnFilter {
  column: string;
  label: string;
  type: ColumnFilterType;
  defaultValue?: any;
  values?: ArrayValue[];
  trim?: boolean;
}

export interface ArrayValue {
  label: string;
  value: any;
}

export enum ColumnFilterType {
  STRING = 'string', NUMBER = 'number', BOOLEAN = 'boolean', DATE = 'date', ARRAY = 'array', MULTIPLE_STRING = 'multiple_string'
}

