import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CaricoTerminale } from '../../../../models/raw/carico_terminale';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Lavoro } from '../../../../models/raw/lavoro';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-carico-export-dialog',
  templateUrl: './carico-export-dialog.component.html',
  styleUrls: ['./carico-export-dialog.component.scss']
})
export class CaricoExportDialogComponent {
  public formGroup = new FormGroup({
    export_type: new FormControl('IN', Validators.required),
    status: new FormControl(['NUL', 'RIS', 'ASS', 'CAR'], Validators.required),
  });
  public isLoading = new BehaviorSubject<boolean>(false);

  constructor(@Inject(MAT_DIALOG_DATA) public carico: CaricoTerminale,
    private dialogRef: MatDialogRef<CaricoExportDialogComponent>,
    private http: HttpClient,
    private snackBar: MatSnackBar) { }

  public async onConfirmButtonClicked(): Promise<void> {
    this.isLoading.next(true);
    const lavoro = await this.http.post<Lavoro>(
      `${environment.baseUrl}api/client/carichi_terminali/${this.carico.caricoterminali_id}/export`,
      this.formGroup.value
    ).toPromise();
    this.isLoading.next(false);

    if (lavoro?.id) {
      this.snackBar.open('Operazione avviata con successo. Riceverai una notifica una volta completata.', null, { duration: 5000 });
      this.dialogRef.close();
    } else {
      this.snackBar.open('Errore durante l’avvio dell’operazione. Riprova più tardi.', null, { duration: 5000 });
    }
  }
}
