<h1 mat-dialog-title>Associazione magazzino</h1>
<form mat-dialog-content fxLayout="column" fxLayoutGap="16px" [formGroup]="formGroup">
  <mat-form-field appearance="outline">
    <mat-label>Numero terminale</mat-label>
    <input matInput type="number" formControlName="terminale" min="0" max="999">
    <mat-error>Questo campo è obbligatorio</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Codici</mat-label>
    <textarea matInput formControlName="codes"></textarea>
    <mat-error>Questo campo è obbligatorio</mat-error>
  </mat-form-field>
</form>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
  <div>
    <mat-progress-spinner *ngIf="isLoading | async" diameter="24" color="accent" mode="indeterminate"></mat-progress-spinner>
  </div>

  <div>
    <button mat-button mat-dialog-close>Annulla</button>
    <button mat-raised-button color="accent" [disabled]="formGroup.invalid || (isLoading | async)" (click)="onConfirmButtonClicked()">Conferma</button>
  </div>
</mat-dialog-actions>
