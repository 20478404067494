<mat-card appearance="outlined">
  <mat-card-content>
    <div *ngFor="let lettura of letture; let i = index"
         class="w100">
      <mat-divider *ngIf="i > 0" inset="true"></mat-divider>
      <div class="lettura-container w100"
           (click)="onLetturaClicked(lettura)"
           [ngClass]="{selected: mapService.selectedLetture.includes(lettura)}"
           mat-ripple>
        <table class="w100">
          <tr>
            <th>Utente</th>
            <td>{{lettura.utente}}</td>
          </tr>
          <tr>
            <th>Indirizzo</th>
            <td>{{lettura | letturaAddress}}</td>
          </tr>
          <tr *ngIf="classi.get(lettura.codice_ente + '_' + lettura.classe)">
            <th>Classe</th>
            <td>
              <span>
                {{classi.get(lettura.codice_ente + '_' + lettura.classe).descrizione}}
              </span>
            </td>
          </tr>
          <tr *ngIf="!!lettura.fascia_oraria && lettura.fascia_oraria.length > 0">
            <th>Data Fine</th>
            <td>{{lettura.data_fine | momentDate}}</td>
          </tr>
          <tr>
            <th
              *ngIf="!!lettura.fascia_oraria && lettura.fascia_oraria.length > 0 && !!lettura.data_fine && lettura.data_fine.length > 0">
              Fascia
            </th>
            <td>{{lettura.fascia_oraria | timeSlot}}</td>
          </tr>
          <tr>
            <th>Matricola</th>
            <td>{{lettura.matricola}}</td>
          </tr>
          <tr>
            <th>Stato</th>
            <td>{{lettura.stato}}</td>
          </tr>
          <tr>
            <th>Terminale</th>
            <td>{{lettura.terminale}}</td>
          </tr>
        </table>

        <button mat-icon-button *ngIf="showActions" [matMenuTriggerFor]="letturaMenu" (click)="onOpenMenuButtonClicked($event)">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #letturaMenu>
          <button mat-menu-item (click)="onEditLocationButtonClicked(lettura)">Geolocalizza manualmente</button>
        </mat-menu>
      </div>
    </div>
  </mat-card-content>
</mat-card>
