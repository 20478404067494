import { Page } from './page';
import { HttpClient } from '@angular/common/http';
import { Query } from './query-lang/query';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';

export enum LoaderStatus {
  IDLE,
  LOADING,
  CANCELLING,
  CANCELLED,
  DONE,
}

export class DataLoader<T> {
  items: T[] = [];
  private _isLoading = false;
  private lastPage: Page<T> = null;
  _itemsEmitter: BehaviorSubject<T[]> = new BehaviorSubject([]);
  _numItemsEmitter: BehaviorSubject<number> = new BehaviorSubject(0);
  _queryEmitter: BehaviorSubject<Query> = new BehaviorSubject(null);
  _statusEmitter: BehaviorSubject<LoaderStatus> = new BehaviorSubject(LoaderStatus.IDLE);
  _pageEmitter: BehaviorSubject<Page<T>> = new BehaviorSubject(null);

  get isLoading() {
    return this._isLoading;
  }

  set query(q: Query) {
    this.items = [];
    this._isLoading = false;
    this.lastPage = null;
    this._queryEmitter.next(q);
    this._statusEmitter.next(LoaderStatus.LOADING);
    this.loadMore();
  }

  constructor(private http: HttpClient, private url: string, private pageSize = 10) {

  }

  public init(): void {
    this.loadMore();
  }

  // returns true if all the data_fine has been loaded.
  public async loadMore(): Promise<boolean> {
    if (this._isLoading ||
      (this.lastPage !== null && this.lastPage.next_page_url === null) ||
      this._statusEmitter.value === LoaderStatus.CANCELLED) {
      return true;
    }
    if (this._statusEmitter.value === LoaderStatus.CANCELLING) {
      this._statusEmitter.next(LoaderStatus.CANCELLED);
      return true;
    }
    this._isLoading = true;
    this._statusEmitter.next(LoaderStatus.LOADING);
    if (this.lastPage === null) {
      this.lastPage = await this.http
        .post<Page<T>>(this.url, this._queryEmitter.value, { params: { page_size: this.pageSize.toString() } }).toPromise();
    } else {
      this.lastPage = await this.http.post<Page<T>>(this.lastPage.next_page_url, this._queryEmitter.value,
        { params: { page_size: this.pageSize.toString() } }).toPromise();
    }
    this.items.push(...this.lastPage.data);
    this._isLoading = false;
    const finished = this.lastPage !== null && this.items.length === this.lastPage.total;
    if (finished) {
      this._statusEmitter.next(LoaderStatus.DONE);
    }
    this._itemsEmitter.next(this.items);
    this._pageEmitter.next(this.lastPage);
    this._numItemsEmitter.next(this.items.length);
    return finished;
  }

  public cancel() {
    if (this._statusEmitter.value === LoaderStatus.LOADING) {
      this._statusEmitter.next(LoaderStatus.CANCELLING);
    } else {
      this._statusEmitter.next(LoaderStatus.CANCELLED);
    }
  }

  get itemsEmitter(): Observable<T[]> {
    return this._itemsEmitter.asObservable();
  }

  get pageEmitter(): Observable<Page<T>> {
    return this._pageEmitter.asObservable();
  }

  get numItemsEmitter(): Observable<number> {
    return this._numItemsEmitter.asObservable();
  }
}

export class ImmutableDataLoader<T> implements DataSource<T> {
  private _items = new BehaviorSubject<T[]>([]);
  private _numLoaded = new BehaviorSubject<number>(0);
  public _total = new BehaviorSubject<number>(0);
  public totalObs: Observable<number> = this._total.asObservable();
  private _to = new BehaviorSubject<number>(0);
  private _page = new BehaviorSubject<Page<T>>(null);
  private _status = new BehaviorSubject(LoaderStatus.IDLE);
  private _loaded = new BehaviorSubject<number>(null);

  constructor(
    private http: HttpClient,
    private url: string,
    private query: Query = null,
    private pageSize = 10,
    private requesta: 'get' | 'post') {

    this._page.subscribe(value => {
      if (value !== null) {
        // console.log('_page subscriber su dataloader');
        if (value.data !== null) {
          const items = this._items.value;
          items.push(...value.data);
          this._items.next(items);
        }
        this._loaded.next(100 * (value.current_page / value.total));
        this._status.next(value.next_page_url === null ? LoaderStatus.DONE : LoaderStatus.IDLE);
      }
    });
  }

  get page() {
    return this._page.asObservable();
  }

  get items(): BehaviorSubject<T[]> {
    return this._items;
  }

  get status() {
    return this._status.asObservable();
  }

  get loaded() {
    return this._loaded.asObservable();
  }

  get numLoaded(): number {
    return this._numLoaded.getValue();
  }

  get to(): number {
    return this._to.getValue();
  }

  get loading() {
    return this._status.pipe(
      map(value => value === LoaderStatus.LOADING)
    );
  }

  get currentQuery() {
    return this.query;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  setStatus(status: LoaderStatus) {
    this._status.next(status);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  connect(_collectionViewer: CollectionViewer): Observable<T[] | ReadonlyArray<T>> {
    return this.items.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  disconnect(_collectionViewer: CollectionViewer): void {
  }

  async loadNext() {
    if (this._status.value !== LoaderStatus.LOADING &&
      this._status.value !== LoaderStatus.CANCELLING &&
      this._status.value !== LoaderStatus.DONE) {

      let promise: Promise<Page<T>> = null;
      this._status.next(LoaderStatus.LOADING);
      if (this._page.value === null) {
        if (this.requesta === 'post')
          promise = this.http.post<Page<T>>(
            this.url,
            this.query,
            { params: { page_size: this.pageSize.toString() } }
          ).toPromise();
        else
          promise = this.http.get<Page<T>>(
            this.url,
            { params: { page_size: this.pageSize.toString() } }
          ).toPromise();
      } else if (this._page.value.next_page_url !== null) {
        if (this.requesta === 'post')
          promise = this.http.post<Page<T>>(
            this.url,
            this.query,
            { params: { page_size: this.pageSize.toString(), page: `${this._page.value.current_page + 1}` } }
          ).toPromise();
        else
          promise = this.http.get<Page<T>>(
            this.url,
            { params: { page_size: this.pageSize.toString(), page: `${this._page.value.current_page + 1}` } }
          ).toPromise();
      }
      if (promise !== null) {
        const page = await promise;
        // console.log('page', page);
        this._page.next(page);
        this._total.next(page.total);
        // console.log('new total', this._total.getValue());
        this._to.next(page.to);
      }
    } else if (this._status.value === LoaderStatus.CANCELLING) {
      this._status.next(LoaderStatus.CANCELLED);
    }
  }

  update(items: T[]) {
    this._items.next(items);
    this._numLoaded.next(items.length);
  }

  cancel() {
    this._status.next(LoaderStatus.CANCELLING);
    this._page.complete();
    this._items.complete();
  }

  newQuery(query: Query): ImmutableDataLoader<T> {
    this.cancel();
    return new ImmutableDataLoader<T>(this.http, this.url, query, this.pageSize, this.requesta);
  }
}
