import {Component, Inject, OnInit} from '@angular/core';
import {Lettura} from '../../../../models/raw/lettura';
import {ImmutableDataLoader} from '../../../../models/data-loader';
import {GeoService} from '../../../services/geo.service';
import {GeolocateManualDialogComponent, Payload} from '../geolocate-manual-dialog/geolocate-manual-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSelectionListChange} from '@angular/material/list';
import { Swm } from 'src/models/raw/swm';

@Component({
    selector: 'app-geolocate-dialog',
    templateUrl: './geolocate-dialog.component.html',
    styleUrls: ['./geolocate-dialog.component.scss']
})
export class GeolocateDialogComponent implements OnInit {

    public toGeoreference: Swm[] = [];
    public selectedLetture: Swm[] = [];

    public loading = false;

    constructor(private dialog: MatDialog,
                private dialogRef: MatDialogRef<GeolocateDialogComponent>,
                private snackBar: MatSnackBar,
                public geoService: GeoService,
                @Inject(MAT_DIALOG_DATA) public data: ImmutableDataLoader<Swm>) {
    }

    ngOnInit() {
        this.data.items.subscribe(value => {
            this.toGeoreference = value.filter(item => !GeoService.isGeoreferenced(item));
        });
        this.selectedLetture = [...this.toGeoreference];
    }

    async startGeoreference() {
        if (!this.loading) {
            this.loading = true;
            try {
                const selected = [...this.selectedLetture];
                const total = selected.length;
                const result = [];
                while (selected.length > 0) {
                    const page = selected.splice(0, 100);
                    result.push(...(await this.geoService.georeference(page)));
                    const untouched = this.data.items.value.filter(value => result.findIndex(item => item.id === value.id) < 0);
                    this.data.update([...untouched, ...result]);
                }
                this.dialogRef.close(result);
                this.snackBar.open(
                    `${result.length} di ${total} attività aggiornate con successo.`,
                    null,
                    { duration: 5000 }
                );
            } catch (e) {
                this.snackBar.open(
                    'Errore durante la geolocalizzazione. Controlla la configurazione nelle impostazioni e verifica che l’app sia in esecuzione.',
                    null,
                    { duration: 5000 }
                );
            } finally {
                this.loading = false;
            }
        }
    }

    async manualGeo(lettura: Swm) {
        const result = await this.dialog.open<GeolocateManualDialogComponent, Payload, any>(GeolocateManualDialogComponent, {
            data: {
                lettura: lettura,
            }
        }).afterClosed().toPromise();

        if (result === true) {
            this.data.update([...this.data.items.value.filter(l => l.id !== lettura.id), lettura]);
        }
    }

    public onSelectionChanged(event: MatSelectionListChange): void {
        this.selectedLetture = event.source.selectedOptions.selected.map(item => item.value);
    }
}
