import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Type, ValidationDialogComponent} from '../components/dialogs/validation-dialog/validation-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private dialog: MatDialog) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 422) {
            const messages = [];
            for (const message of Object.values(error.error.errors)) {
              messages.push(message);
            }
            this.dialog.open(ValidationDialogComponent, {
              data: {
                type: Type.ERROR,
                messages: messages,
              }
            });
          }
          if (error.status === 400) {
            const messages = [];
            messages.push(error.error.message);
            this.dialog.open(ValidationDialogComponent, {
              data: {
                type: Type.ERROR,
                messages: messages,
              }
            });
          }

          return throwError(error);
        })
      );
  }
}
