<h1 mat-dialog-title>{{data.type === 0 ? 'Attenzione!' : 'Errore!'}}</h1>
<mat-dialog-content>
  <p>Sono stati riscontrati i seguenti problemi:</p>

  <ul>
    <li *ngFor="let message of data.messages">{{message}}</li>
  </ul>

  <p *ngIf="data.type === 0">Vuoi continuare ugualmente?</p>
</mat-dialog-content>
<mat-dialog-actions *ngIf="data.type === 0">
  <button mat-button mat-dialog-close>Annulla</button>
  <button mat-raised-button [mat-dialog-close]="true" color="accent">Continua</button>
</mat-dialog-actions>
