<mat-toolbar class="sub-toolbar mat-elevation-z4" fxLayout="row" fxLayoutAlign="space-between center">
  <div></div>

  <app-search-input (search)="onSearchChanged($event)"></app-search-input>

  <div>
    <button mat-icon-button (click)="onAddButtonClicked()" matTooltip="Nuovo Stato">
      <mat-icon>add</mat-icon>
    </button>

  </div>
</mat-toolbar>

<mat-sidenav-container>

  <mat-sidenav-content *ngIf="loader">

    <div class="h100 container">

      <mat-progress-bar *ngIf="(loader.loading | async)" mode="indeterminate" color="accent"></mat-progress-bar>

      <div infiniteScroll
           [scrollWindow]="false"
           (scrolled)="onTableScrolled()"
           [infiniteScrollDistance]="1"
           class="table-container">
        <mat-table matSort
                   matSortActive="id"
                   matSortDirection="asc"
                   (matSortChange)="onSort($event)"
                   [dataSource]="loader"
                   class="mat-elevation-z8">
          <ng-container matColumnDef="id">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Id</mat-header-cell>
            <mat-cell *matCellDef="let stato">{{stato.id}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="stato">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Stato</mat-header-cell>
            <mat-cell *matCellDef="let stato">{{stato.stato}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let stato">
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="onEditButtonButtonClicked(stato)">Modifica Stato</button>
                <button mat-menu-item (click)="onDeleteButtonClicked(stato)">Elimina Stato</button>
              </mat-menu>

              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
          <mat-row *matRowDef="let stato; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

