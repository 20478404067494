<div class="h100 container" fxLayout="row">
  <div class="h100 dashboard-container" [class.flex-column-fill]="operatorsLoader.loaded | async">
    <mat-toolbar>
      <mat-form-field class="w100">
        <input #search matInput type="text" [formControl]="searchFormControl" (ngModelChange)="onSearchChanged($event)">
        <button mat-icon-button matSuffix (click)="searchFormControl.setValue('')">
          <mat-icon>{{search.value.length > 0 ? 'clear' : 'search'}}</mat-icon>
        </button>
      </mat-form-field>
    </mat-toolbar>
    <mat-progress-bar *ngIf="(operatorsLoader.loading | async) || workService.loading" mode="indeterminate" color="accent"></mat-progress-bar>

    <div infiniteScroll
         class="list-container"
         [scrollWindow]="false"
         [infiniteScrollDistance]="2"
         (scrolled)="letturistiScrolled()">
      <div *ngFor="let operator of operators | geoOrder:(this.orderReference | async):workLoads">
        <mat-divider></mat-divider>
        <mat-card appearance="outlined" (click)="click(operator)"
                  matRippleColor="primary"
                  class="mat-elevation-z0"
                  mat-ripple>
          <mat-card-header>
            <mat-card-title>
              {{operator.letturista_nome}}
            </mat-card-title>
            <mat-card-subtitle>
              Terminale: {{operator.letturista_numero}}
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <mat-expansion-panel class="mat-elevation-z0" *ngIf="letturistasDeadlines(this.workLoads, operator).length > 0">
              <mat-expansion-panel-header (click)="$event.stopPropagation()"></mat-expansion-panel-header>
              <div *ngFor="let data of dateDaWorkLoads(this.workLoads, operator)">
                <table class="workload-table" *ngIf="fasceForTerminale(this.workLoads, operator); let fasce">
                  <tr>
                    <th [colSpan]="2">
                      {{data | momentDate}}
                    </th>
                  </tr>
                  <tr *ngFor="let fascia of fasce">
                    <td>{{fascia | timeSlot}}</td>
                    <td>{{loadForTerminaleData(this.workLoads, operator, data, fascia)}}</td>
                  </tr>
                </table>
              </div>
            </mat-expansion-panel>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
  <div class="vertical-actions-container" fxLayout="column">
    <button mat-icon-button (click)="onClearButtonClicked()" *ngIf="workLoads.length > 0">
      <mat-icon>clear</mat-icon>
    </button>
    <button mat-icon-button (click)="onRangeButtonClicked()" *ngIf="workLoads.length === 0" [disabled]="operatorsLoader.loading | async">
      <mat-icon>date_range</mat-icon>
    </button>
    <button mat-icon-button (click)="onCalendarButtonClicked()" [disabled]="(operatorsLoader.loading | async) || workLoads.length === 0">
      <mat-icon>view_list</mat-icon>
    </button>
  </div>
</div>
