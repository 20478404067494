import { Injectable } from '@angular/core';
import { Georeferenced } from '../../models/georeferenced';
import { LocalStorage } from 'ngx-webstorage';
//import { MapTypeStyle } from '@agm/core';
import { GeoService } from './geo.service';
import { computeDistanceBetween } from 'spherical-geometry-js';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImmutableDataLoader } from '../../models/data-loader';
import { DataLoaderProviderService } from './data-loader-provider.service';
import { Subscription } from 'rxjs';
import { Query } from '../../models/query-lang/query';
import { Swm } from 'src/models/raw/swm';

enum MarkersThemes {
  PLANNING, MODALITA_PREAVVISO, SMAT
}

@Injectable({
  providedIn: 'root'
})
export class MapService {
  public readonly mapStyles: google.maps.MapTypeStyle[] = [
    {
      'featureType': 'administrative',
      'elementType': 'geometry',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'poi',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'road',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'transit',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    }
  ];

  @LocalStorage('HomeMapLatitude', 43.082027)
  public latitude: number;
  @LocalStorage('HomeMapLongitude', 12.444571)
  public longitude: number;
  @LocalStorage('HomeMapZoom', 7)
  public zoom: number;
  @LocalStorage('HomeMapMarkersTheme', MarkersThemes.PLANNING)
  public markersTheme: MarkersThemes;

  public loader: ImmutableDataLoader<Swm>;
  public coordinates: Georeferenced<Swm[]>[] = [];
  public unlocatedLetture: Swm[] = [];
  public selectedLetture: Swm[] = [];

  private cancelSub: Subscription = null;

  constructor(private dataLoaderProvider: DataLoaderProviderService, private snackBar: MatSnackBar) {
    this.loader = this.dataLoaderProvider.getSwmLoader(500);
  }

  public newQuery(query: Query): void {    
    this.bindLoaderLetture(this.loader.newQuery(query));
    this.loader.loadNext();
  }

  public reset(): void {
    this.coordinates = [];
    this.unlocatedLetture = [];
    this.selectedLetture = [];
  }

  public unselectAll(): void {
    this.selectedLetture = [];
  }

  public groupLettureIntoCoordinates(letture: Swm[]): void {
    const georeferenziabili = letture.filter(GeoService.isGeoreferenced);
    this.unlocatedLetture.push(...letture.filter(item => !GeoService.isGeoreferenced(item)));

    for (const Swm of georeferenziabili) {
       const lat = parseFloat(Swm.latitudine.replace(',', '.'));
       const lng = parseFloat(Swm.longitudine.replace(',', '.'));
      // let found = false;
      // for (const coordinate of this.coordinates) {
      //   if (computeDistanceBetween(coordinate, { lat: lat, lng: lng }) <= 5) {
      //     found = true;
      //     coordinate.body.push(Swm);
      //     break;
      //   }
      // }
      // if (!found) {
      //   this.coordinates.push({
      //     lat: lat,
      //     lng: lng,
      //     markerIcon: null,
      //     body: [Swm],
      //   });
      // }
      this.coordinates.push({
        lat: lat,
        lng: lng,
        markerIcon: null,
        body: [Swm],
      });
    }
    this.refreshMarkersIcons();
  }

  public lettureUpdated(letture: Swm | Swm[]): void {
    if (letture instanceof Array) {
      for (const Swm of letture) {
        this.removeSwmFromCoordinates(Swm);
        this.removeSwmFromUnlocatedLetture(Swm);
      }
      this.groupLettureIntoCoordinates(letture);
    } else {
      this.removeSwmFromCoordinates(letture);
      this.removeSwmFromUnlocatedLetture(letture);
      this.groupLettureIntoCoordinates([letture]);
    }
  }

  private bindLoaderLetture(loader: ImmutableDataLoader<Swm>): void {
    this.cancelSub?.unsubscribe();
    this.loader = loader;
    this.reset();
    this.cancelSub = loader.page.subscribe(async value => {
      if (value !== null && value.data !== null) {
        this.groupLettureIntoCoordinates(value.data);
        await loader.loadNext();
      }
    });
  }

  private removeSwmFromUnlocatedLetture(Swm: Swm): void {
    if (Swm.latitudine && Swm.longitudine) {
      const index = this.unlocatedLetture.findIndex(l => l.id === Swm.id);
      if (index > -1) {
        this.unlocatedLetture.splice(index, 1);
      }
    }
  }

  private removeSwmFromCoordinates(Swm: Swm): void {
    for (let i = 0; i < this.coordinates.length; i++) {
      const coordinate = this.coordinates[i];
      const index = coordinate.body.findIndex(l => l.id === Swm.id);
      if (index > -1) {
        coordinate.body.splice(index, 1);
        if (coordinate.body.length === 0) {
          this.coordinates.splice(i, 1);
        }
        return;
      }
    }
  }

  public selectCoordinates(coordinates: Georeferenced<Swm[]>[]): void {
    for (const coordinate of coordinates) {
      for (const Swm of coordinate.body) {
        if (this.canSwmBeSelected(Swm)) {
          this.selectedLetture.push(Swm);
        }
      }
    }
    this.refreshMarkersIcons();
  }

  public unselectCoordinates(coordinates: Georeferenced<Swm[]>[]): void {
    for (const coordinate of coordinates) {
      for (const Swm of coordinate.body) {
        const index = this.selectedLetture.indexOf(Swm);
        if (index >= 0) {
          this.selectedLetture.splice(index, 1);
        }
      }
    }
    this.refreshMarkersIcons();
  }

  public toggle(letture: Swm | Swm[]): void {
    // if (letture instanceof Array) {
    //   let allIncluded = true;
    //   for (const Swm of letture) {
    //     if (!this.selectedLetture.includes(Swm)) {
    //       allIncluded = false;
    //       break;
    //     }
    //   }
    //   if (allIncluded) {
    //     for (const Swm of letture) {
    //       const index = this.selectedLetture.indexOf(Swm);
    //       this.selectedLetture.splice(index, 1);
    //     }
    //   } else {
    //     let allLettureAllowed = true;
    //     for (const Swm of letture) {
    //       if (this.canSwmBeSelected(Swm)) {
    //         this.selectedLetture.push(Swm);
    //       } else {
    //         allLettureAllowed = false;
    //       }
    //     }
    //     if (!allLettureAllowed) {
    //       this.snackBar.open(
    //         'Non tutte le attività possono essere selezionate in quanto incompatibili con lo stato di quelle già selezionate.',
    //         null,
    //         { duration: 5000 }
    //       );
    //     }
    //   }
    // } else {
    //   const Swm = letture as Swm;
    //   const index = this.selectedLetture.indexOf(Swm);
    //   if (index >= 0) {
    //     this.selectedLetture.splice(index, 1);
    //   } else if (this.canSwmBeSelected(Swm)) {
    //     this.selectedLetture.push(Swm);
    //   } else {
    //     this.snackBar.open(
    //       'Questa attività non può essere selezionata in quanto incompatibile con lo stato di quelle già selezionate.',
    //       null,
    //       { duration: 5000 }
    //     );
    //   }
    // }
    // this.refreshMarkersIcons();
  }

  public changeMarkersTheme(theme: MarkersThemes): void {
    this.markersTheme = theme;
    this.refreshMarkersIcons();
  }

  private canSwmBeSelected(Swm: Swm): boolean {
    return !this.selectedLetture.includes(Swm);// && (!this.selectedLetture.length || this.selectedLetture[0].stato === Swm.stato);
  }

  private refreshMarkersIcons(): void {
    for (const coordinate of this.coordinates) {
      coordinate.markerIcon = this.getMarkerIcon(coordinate);
    }
  }

  private getMarkerIcon(coordinate: Georeferenced<Swm[]>): string {
    if (this.selectedLetture.filter(l => coordinate.body.includes(l)).length > 0) {
      return 'assets/markers/letture/selected.png';
    }

    // switch (this.markersTheme) {
    //   case MarkersThemes.PLANNING:
    //     let date = null;
    //     for (const Swm of coordinate.body) {
    //       const newDate = moment(Swm.data_fine, 'YYYY-MM-DD', true);
    //       if (date === null) {
    //         date = newDate;
    //       }
    //       if (!newDate.isSame(date)) {
    //         return 'assets/markers/letture/unselected.png';
    //       }
    //     }

    //     switch (date.weekday()) {
    //       case 1: // lunedì
    //         return 'assets/markers/letture/monday.png';
    //       case 2: // martedì
    //         return 'assets/markers/letture/tuesday.png';
    //       case 3: // mercoledì
    //         return 'assets/markers/letture/wednesday.png';
    //       case 4: // giovedì
    //         return 'assets/markers/letture/thursday.png';
    //       case 5: // venerdì
    //         return 'assets/markers/letture/friday.png';
    //       case 6: // sabato
    //         return 'assets/markers/letture/saturday.png';
    //       default: // domenica o resto
    //         return 'assets/markers/letture/sunday.png';
    //     }

    //   case MarkersThemes.MODALITA_PREAVVISO:
    //     let modalitaPreavviso = 0;
    //     let allDone = true;
    //     for (const Swm of coordinate.body) {
    //       if (Swm.esito_contatto !== null && Swm.esito_contatto !== '') {
    //         continue;
    //       }

    //       allDone = false;
    //       if (modalitaPreavviso !== 0 && Swm.modalita_preavviso !== modalitaPreavviso) {
    //         modalitaPreavviso = -1;
    //         break;
    //       }
    //       modalitaPreavviso = Swm.modalita_preavviso;
    //     }

    //     if (allDone) {
    //       return 'assets/markers/letture/sunday.png';
    //     }
    //     switch (modalitaPreavviso) {
    //       case -1:
    //         return 'assets/markers/letture/monday.png';
    //       case SwmModalitaPreavviso.SMS:
    //         return 'assets/markers/letture/thursday.png';
    //       case SwmModalitaPreavviso.EMAIL:
    //         return 'assets/markers/letture/tuesday.png';
    //       case SwmModalitaPreavviso.CALL:
    //         return 'assets/markers/letture/friday.png';
    //       case SwmModalitaPreavviso.CARTEL:
    //         return 'assets/markers/letture/wednesday.png';
    //       default: // nessun preavviso
    //         return 'assets/markers/letture/unselected.png';
    //     }

    //   case MarkersThemes.SMAT:
    //     let modPreavviso = 0;
    //     let acc = null;
    //     for (const Swm of coordinate.body) {
    //       if ((modPreavviso !== 0 && Swm.modalita_preavviso !== modPreavviso) || (acc !== null && Swm.acc !== acc)) {
    //         modPreavviso = -1;
    //         acc = null;
    //         break;
    //       }
    //       modPreavviso = Swm.modalita_preavviso;
    //       acc = Swm.acc;
    //     }
    //     if (modPreavviso === 4) {
    //       return 'assets/markers/letture/wednesday.png';
    //     } else if (modPreavviso > 0) {
    //       return 'assets/markers/letture/monday.png';
    //     } else if (acc === 'A') {
    //       return 'assets/markers/letture/tuesday.png';
    //     }
    //     return 'assets/markers/letture/unselected.png';
    // }

    return 'assets/markers/letture/unselected.png';
  }
}
