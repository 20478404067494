import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-simple-alert-dialog',
    templateUrl: './simple-alert-dialog.component.html',
    styleUrls: ['./simple-alert-dialog.component.scss']
})
export class SimpleAlertDialogComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: PayLoad) {
    }
}

export interface PayLoad {
    title: string;
    content: string;
}
