import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-simple-form-dialog',
  templateUrl: './simple-form-dialog.component.html',
  styleUrls: ['./simple-form-dialog.component.scss']
})
export class SimpleFormDialogComponent implements OnInit {
  public formGroup: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: PayLoad,
              private dialogRef: MatDialogRef<SimpleFormDialogComponent>,
              private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    const group = {};
    for (const field of this.data.fields) {
      group[field.column] = [field.defaultValue, field.required ? [Validators.required] : []];
    }
    this.formGroup = this.formBuilder.group(group);
  }

  public onConfirmButtonClicked(): void {
    if (this.formGroup.invalid) {
      return;
    }

    this.dialogRef.close(this.formGroup.value);
  }
}

export interface PayLoad {
  fields: FormField[];
}

export interface FormField {
  column: string;
  label: string;
  type: FormFieldType;
  required?: boolean;
  defaultValue?: any;
  values?: ArrayValue[];
}

export enum FormFieldType {
  STRING = 'string', NUMBER = 'number', BOOLEAN = 'boolean', DATE = 'date', ARRAY = 'array'
}

export interface ArrayValue {
  label: string;
  value: any;
}
