import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss']
})
export class PhotoComponent implements OnInit {
  public data: string;

  @Input()
  public url: string;

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.http.get(this.url, { responseType: 'blob' }).toPromise().then((data: Blob) => {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const me = this;
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        me.data = reader.result as string;
      };
    });
  }
}
