import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {StatoMagazzino} from '../../../../models/raw/stato-magazzino';
import {ImmutableDataLoader, LoaderStatus} from '../../../../models/data-loader';
import {DataLoaderProviderService} from '../../../services/data-loader-provider.service';
import {And} from '../../../../models/query-lang/logical';
import {Equal} from '../../../../models/query-lang/comparison';

@Component({
  selector: 'app-stati-magazzino-entity-dialog',
  templateUrl: './stati-magazzino-entity-dialog.component.html',
  styleUrls: ['./stati-magazzino-entity-dialog.component.scss']
})
export class StatiMagazzinoEntityDialogComponent implements OnInit {
  public stati: StatoMagazzino[] = [];
  public stato: StatoMagazzino;
  public dataLoader: ImmutableDataLoader<StatoMagazzino>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { stato: StatoMagazzino, ente_id: number },
              private _dataLoaderProvider: DataLoaderProviderService,
              private _dialogRef: MatDialogRef<StatiMagazzinoEntityDialogComponent>) {
  }

  ngOnInit(): void {

    this.stato = this.data.stato;

    const query = {
      fields: null,
      filters: new And([new Equal('ente_id', this.data.ente_id)]),
      orders: null
    };

    this.dataLoader = this._dataLoaderProvider.getStatoMagazzinoLoader().newQuery(query);

    this.dataLoader.status.subscribe(status => {
      switch (status) {
        case LoaderStatus.IDLE:
          this.dataLoader.loadNext();
          break;

        case LoaderStatus.DONE:
          this.stati = this.dataLoader.items.value;
          break;
      }
    });
    this.dataLoader.loadNext();
  }

  public onConfirmButtonClicked(): void {
    this._dialogRef.close(this.stato);
  }

  public compareStato(object1: any, object2: any) {
    return JSON.stringify(object1) === JSON.stringify(object2);
  }

}
