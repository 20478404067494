export interface Lavoro {
  id: number;
  user_id: number;
  dati: any;
  nome: string;
  tipo: LavoroTipo;
  stato: LavoroStato;
  data_ora_inizio: string;
  data_ora_fine: string;
}

export enum LavoroTipo {
  TIPO_ESPORTAZIONE = 'esportazione'
}

export enum LavoroStato {
  IN_CODA = 'in_coda', IN_CORSO = 'in_corso', COMPLETATO = 'completato', ERRORE = 'errore'
}
