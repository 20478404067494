import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {LocalStorage} from 'ngx-webstorage';
import {Georeferenced} from '../../models/georeferenced';
import { Swm } from 'src/models/raw/swm';
import { LetturaAddressPipe } from '../pipes/lettura-address.pipe';

@Injectable({
  providedIn: 'root'
})
export class GeoService {

  @LocalStorage('GeoServiceAddress')
  public address: string;

  @LocalStorage('GeoServicePort', 8080)
  public port: number;

  constructor(private http: HttpClient) {
  }

  static isGeoreferenced(item: Swm): boolean {
    if (item.latitudine && item.longitudine) {
      const preLat = parseFloat(item.latitudine.replace(',', '.'));
      const preLng = parseFloat(item.longitudine.replace(',', '.'));
      return preLat !== null &&
        preLng !== null && preLat > 0 && preLng > 0;
    }
    return false;
  }

  static geoBuild(Swm: Swm): Georeferenced<Swm> {
    const lat = parseFloat(Swm.latitudine.replace(',', '.'));
    const lng = parseFloat(Swm.longitudine.replace(',', '.'));

    return {body: Swm, lat: lat, lng: lng, markerIcon: null};
  }

  async georeference(letture: Swm[]) {
    const promises = letture.map(Swm => this.georeferenceSingle(Swm));
    const responses = await Promise.all(promises);
    const successful = responses.filter(response => !!response);
    const backend = await this.update(successful);
    return successful.filter(Swm => backend.find(value => value === Swm.id));
  }

  private async georeferenceSingle(Swm: Swm): Promise<Swm> {
    const text = new LetturaAddressPipe().transform(Swm);
    try {
      const response = await this.http.get<GeocoderResponse>(`http://${this.address}:${this.port}/geocode?text=${text}`).toPromise();
      if (response.items.length > 0) {
        const latitudine = response.items[0].latitude;
        const longitudine = response.items[0].longitude;
        if (latitudine) {
          Swm.latitudine = `${latitudine}`.replace('.', ',');
        }
        if (longitudine) {
          Swm.longitudine = `${longitudine}`.replace('.', ',');
        }
        return Swm;
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  }

  public update(letture: Swm[]): Promise<number[]> {
    return this.http.patch<number[]>(`${environment.baseUrl}api/client/letture/update`, letture.map(Swm => {
      return {
        id: Swm.id,
        longitudine: Swm.longitudine,
        latitudine: Swm.latitudine,
      };
    })).toPromise();
  }
}


export interface Item {
  address_lines: any;
  admin_area: string;
  country_code: string;
  country_name: string;
  feature_name: string;
  latitude: number;
  locale: string;
  locality: string;
  longitude: number;
  postalCode: string;
  subAdminArea: string;
  thoroughfare: string;
}

export interface GeocoderResponse {
  items: Item[];
  message: string;
}


