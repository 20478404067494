import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Ente } from '../../models/raw/ente';
import { Lavorazione } from '../../models/raw/lavorazione';
import { CaricoFile } from '../../models/raw/carico_file';
import { ImmutableDataLoader } from '../../models/data-loader';
import { Letturista } from '../../models/raw/letturista';
import { Lettura } from '../../models/raw/lettura';
import { Appalto } from '../../models/raw/appalto';
import { CaricoTerminale } from '../../models/raw/carico_terminale';
import { Segnalazione } from '../../models/raw/segnalazione';
import { Ascending, Descending } from '../../models/query-lang/order';
import { Catalogo } from '../../models/raw/catalogo';
import { Equal } from '../../models/query-lang/comparison';
import { And } from '../../models/query-lang/logical';
import { ConfigurazioneImportExport } from '../../models/raw/configurazione_import_export';
import { PosizioneLetturista } from '../../models/raw/posizione_letturista';
import { Magazzino } from '../../models/raw/magazzino';
import { Report } from '../../models/raw/report';
import { Lavoro } from '../../models/raw/lavoro';
import { MagazzinoConsumabile } from '../../models/raw/magazzino-consumabile';
import { StatoMagazzino } from '../../models/raw/stato-magazzino';
import { Swm } from 'src/models/raw/swm';
import { Alarm } from 'src/models/raw/alarm';
import { Import } from 'src/models/raw/import';
import { Export } from 'src/models/raw/export';
import { Frame } from 'src/models/raw/frame';

@Injectable({
  providedIn: 'root'
})
export class DataLoaderProviderService {

  constructor(private http: HttpClient) {
  }

  public getAppaltiLoader(pageSize = 50, requestMethod: 'get' | 'post' = 'post'): ImmutableDataLoader<Appalto> {
    return new ImmutableDataLoader<Appalto>(
      this.http,
      `${environment.baseUrl}api/client/appalti/filter`,
      null,
      pageSize,
      requestMethod
    );
  }

  public getCarichiLoader(pageSize = 50, requestMethod: 'get' | 'post' = 'post'): ImmutableDataLoader<CaricoTerminale> {
    return new ImmutableDataLoader<CaricoTerminale>(
      this.http,
      `${environment.baseUrl}api/client/carichi_terminali/filter`,
      { filters: null, orders: [new Descending('caricoterminali_id')] },
      pageSize,
      requestMethod
    );
  }

  public getCataloghiLoader(pageSize = 50, requestMethod: 'get' | 'post' = 'post'): ImmutableDataLoader<Catalogo> {
    return new ImmutableDataLoader<Catalogo>(
      this.http,
      `${environment.baseUrl}api/client/cataloghi/filter`,
      null,
      pageSize,
      requestMethod
    );
  }

  public getConfigurazioniImportExportLoader(pageSize = 50, requestMethod: 'get' | 'post' = 'post'): ImmutableDataLoader<ConfigurazioneImportExport> {
    return new ImmutableDataLoader<ConfigurazioneImportExport>(
      this.http,
      `${environment.baseUrl}api/client/configurazioni_import_export/filter`,
      { filters: null, orders: [new Ascending('name')] },
      pageSize,
      requestMethod
    );
  }

  public getEntiLoader(pageSize = 50, requestMethod: 'get' | 'post' = 'post'): ImmutableDataLoader<Ente> {
    return new ImmutableDataLoader<Ente>(
      this.http,
      `${environment.baseUrl}api/client/enti/filter`,
      null,
      pageSize,
      requestMethod
    );
  }

  public getFilesLoader(pageSize = 50, requestMethod: 'get' | 'post' = 'post'): ImmutableDataLoader<CaricoFile> {
    return new ImmutableDataLoader<CaricoFile>(
      this.http,
      `${environment.baseUrl}api/client/carichi_file/filter`,
      { filters: null, orders: [new Descending('caricofile_id')] },
      pageSize,
      requestMethod
    );
  }

  public getImportazioneLettureLoader(pageSize = 50, requestMethod: 'get' | 'post' = 'post'): ImmutableDataLoader<Lettura> {
    return new ImmutableDataLoader<Lettura>(
      this.http,
      `${environment.baseUrl}api/client/importazioni_letture/filter`,
      null,
      pageSize,
      requestMethod
    );
  }

  public getLavorazioniLoader(pageSize = 50, requestMethod: 'get' | 'post' = 'post'): ImmutableDataLoader<Lavorazione> {
    return new ImmutableDataLoader<Lavorazione>(
      this.http,
      `${environment.baseUrl}api/client/lavorazioni/filter`,
      { filters: null, orders: [new Descending('lavorazione_id')] },
      pageSize,
      requestMethod
    );
  }

  public getLavoriLoader(pageSize = 50, requestMethod: 'get' | 'post' = 'post'): ImmutableDataLoader<Lavoro> {
    return new ImmutableDataLoader<Lavoro>(
      this.http,
      `${environment.baseUrl}api/client/lavori/filter`,
      { filters: null, orders: [new Ascending('id')] },
      pageSize,
      requestMethod
    );
  }

  public getLetturistiLoader(pageSize = 50, requestMethod: 'get' | 'post' = 'post'): ImmutableDataLoader<Letturista> {
    return new ImmutableDataLoader<Letturista>(
      this.http,
      `${environment.baseUrl}api/client/letturisti/filter`,
      {
        filters: new And([new Equal('letturista_attivo', 1)]),
        orders: [new Ascending('letturista_numero')]
      },
      pageSize,
      requestMethod
    );
  }

  public getLettureLoader(pageSize = 50, requestMethod: 'get' | 'post' = 'post'): ImmutableDataLoader<Lettura> {
    return new ImmutableDataLoader<Lettura>(
      this.http,
      `${environment.baseUrl}api/client/getswm/all`,
      null,
      pageSize,
      requestMethod
    );
  }

  public getMagazzinoLoader(pageSize = 50, requestMethod: 'get' | 'post' = 'post'): ImmutableDataLoader<Magazzino> {
    return new ImmutableDataLoader<Magazzino>(
      this.http,
      `${environment.baseUrl}api/client/magazzino/filter`,
      { filters: null, orders: null },
      pageSize,
      requestMethod
    );
  }

  public getStatoMagazzinoLoader(pageSize = 50, requestMethod: 'get' | 'post' = 'post'): ImmutableDataLoader<StatoMagazzino> {
    return new ImmutableDataLoader<StatoMagazzino>(
      this.http,
      `${environment.baseUrl}api/client/stati_magazzino/filter`,
      { filters: null, orders: null },
      pageSize,
      requestMethod
    );
  }

  public getMagazzinoConsumabileLoader(pageSize = 50, requestMethod: 'get' | 'post' = 'post'): ImmutableDataLoader<MagazzinoConsumabile> {
    return new ImmutableDataLoader<MagazzinoConsumabile>(
      this.http,
      `${environment.baseUrl}api/client/magazzino_consumabili/filter`,
      { filters: null, orders: null },
      pageSize,
      requestMethod
    );
  }

  public getPosizioniLetturistiLoader(pageSize = 50, requestMethod: 'get' | 'post' = 'post'): ImmutableDataLoader<PosizioneLetturista> {
    return new ImmutableDataLoader<PosizioneLetturista>(
      this.http,
      `${environment.baseUrl}api/client/posizioni_letturisti/filter`,
      { filters: null, orders: null },
      pageSize,
      requestMethod
    );
  }

  public getReportsLoader(pageSize = 50, requestMethod: 'get' | 'post' = 'post'): ImmutableDataLoader<Report> {
    return new ImmutableDataLoader<Report>(
      this.http,
      `${environment.baseUrl}api/client/reports/filter`,
      { filters: null, orders: null },
      pageSize,
      requestMethod
    );
  }

  public getSegnalazioniLoader(pageSize = 50, requestMethod: 'get' | 'post' = 'post'): ImmutableDataLoader<Segnalazione> {
    return new ImmutableDataLoader<Segnalazione>(
      this.http,
      `${environment.baseUrl}api/client/segnalazioni/filter`,
      { filters: null, orders: [new Ascending('codice'), new Ascending('descrizione')] },
      pageSize,
      requestMethod
    );
  }

  public getSwmLoader(pageSize = 50, requestMethod: 'get' | 'post' = 'post'): ImmutableDataLoader<Swm> {
    return new ImmutableDataLoader<Swm>(
      this.http,
      `${environment.baseUrl}api/client/lorawan/anagrafica`,
      null,
      pageSize,
      requestMethod
    );
  }

  public getAlarmsLoader(pageSize = 50, requestMethod: 'get' | 'post' = 'post'): ImmutableDataLoader<Alarm> {
    return new ImmutableDataLoader<Alarm>(
      this.http,
      `${environment.baseUrl}api/client/getAllarmi/filter`,
      null,
      pageSize,
      requestMethod
    );
  }

  public getImportsLoader(pageSize = 50, requestMethod: 'get' | 'post' = 'post'): ImmutableDataLoader<Import> {
    return new ImmutableDataLoader<Import>(
      this.http,
      `${environment.baseUrl}api/client/lorawan/files/filter`,
      null,
      pageSize,
      requestMethod
    );
  }

  public getExportsLoader(pageSize = 50, requestMethod: 'get' | 'post' = 'post'): ImmutableDataLoader<Export> {
    return new ImmutableDataLoader<Export>(
      this.http,
      `${environment.baseUrl}api/client/lorawan/export/filter`,
      null,
      pageSize,
      requestMethod
    );
  }

  public getFrameLoader(pageSize = 50, requestMethod: 'get' | 'post' = 'post'): ImmutableDataLoader<Frame> {
    return new ImmutableDataLoader<Frame>(
      this.http,
      `${environment.baseUrl}api/client/lorawan/lastframes`,
      null,
      pageSize,
      requestMethod
    );
  }

  public getReportLoader(pageSize = 50, requestMethod: 'get' | 'post' = 'post', body: any): ImmutableDataLoader<Frame> {
    return new ImmutableDataLoader<any>(
      this.http,
      `${environment.baseUrl}api/client/getLetture/byFilterTable`,
      body,
      pageSize,
      requestMethod
    );
  }

  public getLastTrasmissionLoader(pageSize = 50, requestMethod: 'get' | 'post' = 'post', body: any): ImmutableDataLoader<Frame> {
    return new ImmutableDataLoader<any>(
      this.http,
      `${environment.baseUrl}api/client/getLetture/lastValue`,
      body,
      pageSize,
      requestMethod
    );
  }

  public getNeverSentLoader(pageSize = 50, requestMethod: 'get' | 'post' = 'post', body: any): ImmutableDataLoader<Frame> {
    return new ImmutableDataLoader<any>(
      this.http,
      `${environment.baseUrl}api/client/getLetture/neverSent`,
      body,
      pageSize,
      requestMethod
    );
  }
}
