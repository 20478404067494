<h1 mat-dialog-title>Geolocalizza Attività</h1>
<mat-dialog-content>
    <mat-selection-list (selectionChange)="onSelectionChanged($event)">
        <mat-list-option
                *ngFor="let lettura of toGeoreference"
                [selected]="true"
                [disableRipple]="true"
                [value]="lettura"
                checkboxPosition="after">
            <div class="option-container">
                <button (click)="manualGeo(lettura)" mat-icon-button>
                    <mat-icon>
                        add_location
                    </mat-icon>
                </button>
                <div>
                    <h3 mat-line>PDR: {{lettura.pdr}}</h3>
                    <p mat-line>
                        {{lettura.indirizzo}} {{lettura.civico}},{{lettura.comune}}, {{lettura.localita}}
                        ({{lettura.provincia}})
                    </p>
                </div>
            </div>
        </mat-list-option>
    </mat-selection-list>
</mat-dialog-content>
<mat-dialog-actions>
    <button (click)="startGeoreference()"
            [disabled]="loading || selectedLetture.length <= 0"
            color="accent"
            mat-raised-button>
        Geolocalizzazione automatica ({{selectedLetture.length}})
    </button>
    <mat-spinner *ngIf="loading" diameter="24" color="accent"></mat-spinner>
</mat-dialog-actions>
