<mat-toolbar class="sub-toolbar mat-elevation-z4">
  <app-search-input (search)="onSearchChanged($event)"></app-search-input>

  <div>
    <button mat-icon-button (click)="filtersSidenav.toggle()" matTooltip="Apri/Chiudi pannello filtri">
      <mat-icon>filter_list</mat-icon>
    </button>

    <button (click)="showMap = !showMap" mat-icon-button matTooltip="Apri/Chiudi pannello Mappa">
      <mat-icon>map</mat-icon>
    </button>
    <!-- <button (click)="export('csv')" mat-icon-button id="upload-button" matTooltip="CSV">
      <mat-icon>cloud_download</mat-icon>
    </button>

    <button (click)="export('xlsx')" mat-icon-button id="upload-button" matTooltip="XLSX">
      <mat-icon>cloud_download</mat-icon>
    </button> -->
  </div>
</mat-toolbar>

<mat-sidenav-container>

  <mat-sidenav #filtersSidenav mode="side" position="end">
    <app-filters [filters]="filters" (filtersChanged)="onFiltersUpdated($event)"></app-filters>
  </mat-sidenav>

  <mat-sidenav-content *ngIf="loader">
    <div class="h100">
      <mat-progress-bar *ngIf="(loader.loading | async)" mode="indeterminate" color="accent"></mat-progress-bar>

      <div [style.height]="showMap ? '400px' : '0px'" style="height: 400px;">
        <google-map (mapInitialized)="onMapReady($event)" *ngIf="showMap" style="width: 100%;" #googleMap
          [center]="{lat: mapService.latitude, lng: mapService.longitude}" [zoom]="mapService.zoom"
          [options]="{ styles: mapService.mapStyles }" class="h100">

          <map-marker (mapClick)="openInfo(marker, i)" #marker="mapMarker"
            *ngFor="let coordinate of loader.items.getValue(); let i = index"
            [position]="{lat: +coordinate.latitudine, lng: +coordinate.longitudine}">

            <map-info-window>
              <div class="info-window-grid">
                <div class="grid-item">Descrizione: {{ coordinate.descrizione_allarme }}</div>
                <div class="grid-item">Modello: {{ coordinate.modello_contatore }}</div>
                <div class="grid-item">Matricola: {{ coordinate.matricola }}</div>
                <!-- Aggiungi altre informazioni qui -->
              </div>
            </map-info-window>
          </map-marker>
        </google-map>
      </div>


      <div infiniteScroll [scrollWindow]="false" (scrolled)="onTableScrolled()" [infiniteScrollDistance]="1"
        class="table-container">
        <mat-table matSort matSortActive="id" matSortDirection="asc" (matSortChange)="onSort($event)"
          [dataSource]="loader" class="mat-elevation-z8">

          <ng-container matColumnDef="matricola">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Matricola</mat-header-cell>
            <mat-cell *matCellDef="let file">{{file.matricola}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="modello_contatore">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Modello</mat-header-cell>
            <mat-cell *matCellDef="let file">{{file.modello_contatore}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="marca_contatore">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Marca</mat-header-cell>
            <mat-cell *matCellDef="let file">{{file.marca_contatore}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="localita">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Località</mat-header-cell>
            <mat-cell *matCellDef="let file">{{file.localita}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="zona">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Zona</mat-header-cell>
            <mat-cell *matCellDef="let file"> {{file.zona}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="libro">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Libro</mat-header-cell>
            <mat-cell *matCellDef="let file"> {{file.libro}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="pagina">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Pagina</mat-header-cell>
            <mat-cell *matCellDef="let file"> {{file.pagina}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="data_ora_allarme">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Data</mat-header-cell>
            <mat-cell *matCellDef="let file"> {{getDate(file.data_ora_allarme)}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="descrizione_allarme">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Descrizione</mat-header-cell>
            <mat-cell *matCellDef="let file"> {{file.descrizione_allarme}}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
          <mat-row mat-ripple *matRowDef="let lettura; columns: displayedColumns;" class="selectable-row"
            (click)="onSelected(lettura)"></mat-row>
        </mat-table>
      </div>

    </div>
  </mat-sidenav-content>
</mat-sidenav-container>