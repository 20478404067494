import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {
  public searchFormControl: FormControl = new FormControl('');

  @Input()
  public loading = false;

  @Input()
  public required = false;

  @Output()
  public search = new EventEmitter<string>();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }

  public onSearchChanged(value: string): void {
    this.search.emit(value);
  }

  public clear(): void {
    this.searchFormControl.setValue('');
  }

}
