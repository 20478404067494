import {Component, HostBinding, HostListener, OnInit} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';
import {ImmutableDataLoader} from '../../../../models/data-loader';
import {Letturista} from '../../../../models/raw/letturista';
import {DataLoaderProviderService} from '../../../services/data-loader-provider.service';
import {BehaviorSubject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {And, Or} from '../../../../models/query-lang/logical';
import {Equal, GreaterEqual, LesserEqual, NotEqual} from '../../../../models/query-lang/comparison';
import {Ascending} from '../../../../models/query-lang/order';
import {PosizioneLetturista} from '../../../../models/raw/posizione_letturista';
import {MapService} from '../../../services/map.service';
import { Swm } from 'src/models/raw/swm';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements OnInit {
  @HostBinding('class') classes = 'flex-column-fill';

  public letturista: Letturista;

  public loader: ImmutableDataLoader<PosizioneLetturista>;
  public lettureLoader: ImmutableDataLoader<Swm>;

  public dateSubject = new BehaviorSubject<Moment>(moment());
  public sliderValue: number;
  public posizioneLetturistaMarker: PosizioneLetturista;

  constructor(public dataLoaderProvider: DataLoaderProviderService, public mapService: MapService) { }

  ngOnInit(): void {
    this.sliderValue = Math.round(this.dateSubject.getValue().minute() / 15) + this.dateSubject.getValue().hour() * 4;
    this.dateSubject.pipe(debounceTime(300)).subscribe(() => this.reloadPosizioniLetturista());
  }

  @HostListener('document:keyup', ['$event'])
  public onKeyboardKeyUp(event: KeyboardEvent): void {
    if (!event.ctrlKey) {
      return;
    }
    switch (event.code) {
      case 'ArrowLeft':
        this.previous();
        break;

      case 'ArrowRight':
        this.next();
        break;
    }
  }

  public onLetturistaChanged(letturista: Letturista): void {
    this.letturista = letturista;
    this.reloadPosizioniLetturista();
  }

  public next(): void {
    this.dateSubject.next(this.dateSubject.getValue().add(1, 'day').clone());
  }

  public previous(): void {
    this.dateSubject.next(this.dateSubject.getValue().subtract(1, 'day').clone());
  }

  public onSliderValueChanged(value: number): void {
    this.sliderValue = value;
    this.calculateMarkerPosition();
  }

  private reloadPosizioniLetturista(): void {
    this.mapService.reset();
    this.posizioneLetturistaMarker = null;
    if (this.letturista) {
      this.loader = this.dataLoaderProvider.getPosizioniLetturistiLoader(500).newQuery({
        filters: new And([
          new Equal('terminale', this.letturista.letturista_numero),
          new NotEqual('latitudine', -1),
          new NotEqual('longitudine', -1),
          new GreaterEqual('dataoraposizione', this.dateSubject.getValue().startOf('day').format('YYYY-MM-DD HH:mm:ss')),
          new LesserEqual('dataoraposizione', this.dateSubject.getValue().endOf('day').format('YYYY-MM-DD HH:mm:ss'))
        ]),
        orders: [new Ascending('dataoraposizione')]
      });
      this.lettureLoader = this.dataLoaderProvider.getSwmLoader(500).newQuery({
        filters: new And([
          new Equal('terminale', this.letturista.letturista_numero),
          new NotEqual('latitudine', -1),
          new NotEqual('longitudine', -1),
          new Or([
            new Equal('data_lettura', this.dateSubject.getValue().format('YYYY-MM-DD')),
            new Equal('data_fine', this.dateSubject.getValue().format('YYYY-MM-DD')),
            new And([
              new GreaterEqual('data_ora_appuntamento', this.dateSubject.getValue().format('YYYY-MM-DD 00:00:00')),
              new LesserEqual('data_ora_appuntamento', this.dateSubject.getValue().format('YYYY-MM-DD 23:59:59')),
            ]),
          ])
        ]),
        orders: null
      });
      this.loader.loadNext().then(_ => this.calculateMarkerPosition());
      this.lettureLoader.loadNext().then(() => {
        this.mapService.groupLettureIntoCoordinates(this.lettureLoader.items.getValue());
        this.setMarkerIcons();
      });
    }
  }

  private setMarkerIcons(): void {
    for (const coordinate of this.mapService.coordinates) {
      coordinate.markerIcon = 'assets/markers/letture/monday.png';
    //    coordinate.body.some(l => ['ASS', 'CAR'].includes(l.stato))
    //     ? 'assets/markers/letture/unselected.png'
    //     : 'assets/markers/letture/monday.png';
    }
  }

  private calculateMarkerPosition(): void {
    if (!this.loader.items.getValue().length) {
      this.posizioneLetturistaMarker = null;
      return;
    }

    const datetime = this.dateSubject.getValue().startOf('day').add(15 * this.sliderValue, 'minutes');
    this.posizioneLetturistaMarker = this.loader.items.getValue().reduce((previous, current) =>
      Math.abs(moment(current.dataoraposizione).diff(datetime)) < Math.abs(moment(previous.dataoraposizione).diff(datetime))
        ? current
        : previous
    );
  }
}
