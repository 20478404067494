import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AlarmsService } from 'src/app/services/alarms.service';
import { Router } from '@angular/router';
import { MapService } from 'src/app/services/map.service';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';

@Component({
  selector: 'app-alarms-map',
  templateUrl: './alarms-map.component.html',
  styleUrls: ['../../base-table/base-table.component.scss', './alarms-map.component.scss']
})
export class AlarmsMapComponent implements OnInit {

  @ViewChildren(MapInfoWindow) infoWindows: QueryList<MapInfoWindow>;
  isLoading = false;
  pins = [];

  constructor(
    public alarmsService: AlarmsService,
    private router: Router,
    public mapService: MapService) {

  }

  ngOnInit() {
    this.isLoading = true;
    this.alarmsService.getAlarmsMap()
      .then(res => {
        if (res) {
          console.log(res);
          this.pins = res;
          this.isLoading = false;
        }
      });
  }

  openInfo(marker: MapMarker, windowIndex: number) {

    const window = this.infoWindows.toArray()[windowIndex];
    window.open(marker);
  }
}
