<h1 mat-dialog-title>Gestione Attività</h1>
<mat-dialog-content>
  <mat-selection-list #selectedActivities>
    <mat-list-option *ngFor="let lettura of data.letture" [selected]="true" [value]="lettura">
      {{lettura.pdr}}, {{lettura.utente}}
    </mat-list-option>
  </mat-selection-list>
</mat-dialog-content>
<mat-dialog-actions>
  <div>
    <button mat-raised-button color="accent"
      [disabled]="selectedActivities.selectedOptions.selected.length <= 0 || isLoading"
      (click)="onAssignButtonClicked(selectedActivities.selectedOptions.selected)">
      Assegna ({{selectedActivities.selectedOptions.selected.length}})
    </button>

    <!-- <button mat-raised-button color="accent"
            [disabled]="selectedActivities.selectedOptions.selected.length != 1 || isLoading"
            (click)="onAppointmentButtonClicked(selectedActivities.selectedOptions.selected)">
      Appuntamento
    </button> -->
  </div>

  <!-- <div *ngIf="data.status === 'RIS'">
    <button mat-raised-button color="accent"
            [disabled]="selectedActivities.selectedOptions.selected.length <= 0 || isLoading"
            (click)="onAssignButtonClicked(selectedActivities.selectedOptions.selected)">
      Riassegna ({{selectedActivities.selectedOptions.selected.length}})
    </button>

    <button mat-raised-button color="accent"
            [disabled]="selectedActivities.selectedOptions.selected.length != 1 || isLoading"
            (click)="onAppointmentButtonClicked(selectedActivities.selectedOptions.selected)">
      Appuntamento
    </button>
  </div>

  <div *ngIf="data.status === 'ASS'">
    <button mat-raised-button color="accent"
            [disabled]="selectedActivities.selectedOptions.selected.length <= 0 || isLoading"
            (click)="onAssignButtonClicked(selectedActivities.selectedOptions.selected)">
      Riassegna ({{selectedActivities.selectedOptions.selected.length}})
    </button>

    <button mat-raised-button color="accent"
            [disabled]="selectedActivities.selectedOptions.selected.length != 1 || isLoading"
            (click)="onAppointmentButtonClicked(selectedActivities.selectedOptions.selected)">
      Appuntamento
    </button>
  </div>

  <div *ngIf="data.status === 'CAR'">
    <button mat-raised-button color="accent"
            [disabled]="selectedActivities.selectedOptions.selected.length <= 0 || isLoading"
            (click)="onAssignButtonClicked(selectedActivities.selectedOptions.selected)">
      Riassegna ({{selectedActivities.selectedOptions.selected.length}})
    </button>

    <button mat-raised-button color="accent"
            [disabled]="selectedActivities.selectedOptions.selected.length != 1 || isLoading"
            (click)="onAppointmentButtonClicked(selectedActivities.selectedOptions.selected)">
      Appuntamento
    </button>
  </div>

  <div *ngIf="data.status === 'LET'">
    <button mat-raised-button color="accent"
            [disabled]="selectedActivities.selectedOptions.selected.length <= 0 || isLoading"
            (click)="onAssignButtonClicked(selectedActivities.selectedOptions.selected)">
      Riassegna ({{selectedActivities.selectedOptions.selected.length}})
    </button>

    <button mat-raised-button color="accent"
            [disabled]="selectedActivities.selectedOptions.selected.length != 1 || isLoading"
            (click)="onAppointmentButtonClicked(selectedActivities.selectedOptions.selected)">
      Appuntamento
    </button>
  </div> -->

  <mat-spinner *ngIf="isLoading" diameter="24"></mat-spinner>
</mat-dialog-actions>