<div class="h100 container">
  <mat-toolbar class="sub-toolbar mat-elevation-z4">
    <app-search-input (search)="onSearchChanged($event)"></app-search-input>

  </mat-toolbar>
  <mat-progress-bar *ngIf="loading" mode="indeterminate" color="accent"></mat-progress-bar>

  <div class="filters">
    <form [formGroup]="form" (ngSubmit)="sendData()">
      <mat-form-field appearance="fill" multiple>
        <mat-label>Matricola</mat-label>
        <mat-select formControlName="devEuis" multiple>
          <mat-option *ngFor="let option of matricole" [value]="option.deveui_codice_identificativo_radio_lorawan">
            {{ option.matricola }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Zona</mat-label>
        <input matInput formControlName="zona">
      </mat-form-field>

      <!-- Campo per 'libro' -->
      <mat-form-field appearance="fill">
        <mat-label>Libro</mat-label>
        <input matInput formControlName="libro">
      </mat-form-field>

      <!-- Campo per 'pagina' -->
      <mat-form-field appearance="fill">
        <mat-label>Pagina</mat-label>
        <input matInput formControlName="pagina">
      </mat-form-field>

      <!-- Campo per 'localita' -->
      <mat-form-field appearance="fill">
        <mat-label>Località</mat-label>
        <input matInput formControlName="localita">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Periodo</mat-label>
        <mat-select formControlName="dateRange">
          <mat-option value="daily">Giornaliero</mat-option>
          <mat-option value="weekly">Settimanale</mat-option>
          <mat-option value="monthly">Mensile</mat-option>
          <!-- Aggiungi qui altre opzioni -->
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="form.value.dateRange === 'daily'">
        <mat-label>Data</mat-label>
        <input formControlName="dateTo" matInput [matDatepicker]="picker">
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Tipo</mat-label>
        <mat-select formControlName="reportType">
          <mat-option *ngIf="form.value.dateRange === 'daily'" value="lettura">Letture</mat-option>
          <mat-option value="consumo">Misura di volume (consumo)</mat-option>
          <!-- Aggiungi qui altre opzioni -->
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Aggregato</mat-label>
        <mat-select [disabled]="true" formControlName="aggregate">
          <mat-option value="value1">Si</mat-option>
          <mat-option value="value2">No</mat-option>
        </mat-select>
      </mat-form-field>

      <button type="submit" mat-raised-button color="primary">Cerca</button>
    </form>
  </div>

  <mat-table matSort matSortActive="indice_file" matSortDirection="desc" (matSortChange)="onSort($event)"
    [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="matricola">
      <mat-header-cell mat-sort-header *matHeaderCellDef>Matricola</mat-header-cell>
      <mat-cell *matCellDef="let file">{{file.matricola}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="readings">
      <mat-header-cell mat-sort-header *matHeaderCellDef>{{form.value.reportType === 'lettura' ? 'Letture' : 'Misura di
        volume (consumo)'}}</mat-header-cell>
      <mat-cell style="white-space: pre-line" *matCellDef="let file">{{getJson(file.readings)}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
    <mat-row style="height: auto; padding: 10px;" *matRowDef="let file; columns: displayedColumns;"
      class="selectable-row"></mat-row>
  </mat-table>

  <div class="charts">
    <div fxLayout="column" style="width: 100%; height: 100%;">
      <h4 class="title">Grafici</h4>
      <canvas #chartCanvas></canvas>
    </div>
  </div>

</div>