import {Component, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Lettura} from 'src/models/raw/lettura';
import {GeoService} from '../../../services/geo.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Swm } from 'src/models/raw/swm';

@Component({
    selector: 'app-geolocate-manual-dialog',
    templateUrl: './geolocate-manual-dialog.component.html',
    styleUrls: ['./geolocate-manual-dialog.component.scss']
})
export class GeolocateManualDialogComponent {
    public formControl = new FormGroup(
        {
            latitude: new FormControl('', [
                Validators.required,
                Validators.pattern(new RegExp('^([-+]?\\d{1,2}[.]\\d+)$')),
            ]),
            longitude: new FormControl('', [
                Validators.required,
                Validators.pattern(new RegExp('^([-+]?\\d{1,3}[.]\\d+)$')),
            ])
        }
    );
    public isLoading = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: Payload,
                private geoService: GeoService,
                private snackBar: MatSnackBar,
                private dialogRef: MatDialogRef<GeolocateManualDialogComponent>) {
    }

    public onSubmit(): void {
        this.isLoading = true;
        if (this.formControl.valid) {
            this.data.lettura.latitudine = this.formControl.value.latitude.toString().replace('.', ',');
            this.data.lettura.longitudine = this.formControl.value.longitude.toString().replace('.', ',');
            this.geoService.update([this.data.lettura])
                .then(value => {
                    if (value.includes(this.data.lettura.id)) {
                        this.dialogRef.close(true);
                    } else {
                        this.displayError();
                    }
                })
                .catch(error => this.displayError());
        }
    }

    private displayError(): void {
        this.isLoading = false;
        this.snackBar.open(
            'Errore durante il salvataggio dei dati. Riprova più tardi.',
            null,
            {duration: 5000}
        );
    }
}

export interface Payload {
    lettura: Swm;
}
