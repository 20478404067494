<h1 mat-dialog-title>Assegnazione articoli terminale: {{data.terminale}}</h1>
<mat-dialog-content>
  <mat-table [dataSource]="data.articoli" *ngIf="data.articoli.length > 0; else elseBlock">
    <ng-container matColumnDef="articolo">
      <mat-header-cell *matHeaderCellDef>Articolo</mat-header-cell>
      <mat-cell *matCellDef="let articolo">{{articolo.articolo}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="quantita">
      <mat-header-cell *matHeaderCellDef>Quantita</mat-header-cell>
      <mat-cell *matCellDef="let articolo">{{articolo.quantita}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <ng-template #elseBlock>
    <p>Al Terminale non sono stati associati degli articoli</p>
  </ng-template>

</mat-dialog-content>
<mat-dialog-actions>
  <div>
    <button mat-button mat-dialog-close>Chiudi</button>
  </div>
</mat-dialog-actions>

