import { Component, Inject } from '@angular/core';
import { Lettura } from '../../../../models/raw/lettura';
import { Letturista } from '../../../../models/raw/letturista';
import { Moment } from 'moment';
import { CarichiDialogComponent } from '../carichi-dialog/carichi-dialog.component';
import { SimpleAlertDialogComponent } from '../simple-alert-dialog/simple-alert-dialog.component';
import { DataLoaderProviderService } from '../../../services/data-loader-provider.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatListOption } from '@angular/material/list';
import { Ente } from '../../../../models/raw/ente';
import { AppointmentDialogComponent } from '../appointment-dialog/appointment-dialog.component';

@Component({
  selector: 'app-activity-dialog',
  templateUrl: './activity-dialog.component.html',
  styleUrls: ['./activity-dialog.component.scss']
})
export class ActivityDialogComponent {
  public isLoading = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: PayLoad, private dialogRef: MatDialogRef<CarichiDialogComponent>,
    private dialog: MatDialog, private dataLoaderProvider: DataLoaderProviderService) {
  }

  public async onAssignButtonClicked(selectedItems: MatListOption[]): Promise<void> {
    const ente = await this.getEnte(selectedItems);
    if (ente) {
      const result = await this.dialog.open(CarichiDialogComponent, {
        data: {
          ente: ente,
          letture: selectedItems.map(option => option.value),
          letturista: this.data.letturista
        }
      }).afterClosed().toPromise();
      if (result instanceof Array) {
        this.dialogRef.close(result);
      }
    }
  }

  public async onAppointmentButtonClicked(selectedItems: MatListOption[]): Promise<void> {
    const result = await this.dialog.open(AppointmentDialogComponent, {
      data: {
        lettura: selectedItems[0].value,
        letturista: this.data.letturista,
      }
    }).afterClosed().toPromise();
    if (result instanceof Array) {
      this.dialogRef.close(result);
    }
  }

  private async getEnte(selectedItems: MatListOption[]): Promise<Ente> {
    let enteId = null;
    for (const item of selectedItems) {
      if (enteId === null) {
        enteId = item.value.codice_ente;
      }
      if (enteId !== item.value.codice_ente) {
        this.dialog.open(SimpleAlertDialogComponent, {
          data: {
            title: 'Attenzione!',
            content: 'Per poter assegnare un gruppo di attività ad un carico, devono appartenere allo stesso ente.',
          }
        });
        return;
      }
    }

    const entiLoader = this.dataLoaderProvider.getEntiLoader(1).newQuery({
      filters: null,
      orders: null
    });
    this.isLoading = true;
    await entiLoader.loadNext();
    this.isLoading = false;
    if (entiLoader.items.getValue().length > 0) {
      return entiLoader.items.getValue()[0];
    }
  }
}

export interface PayLoad {
  letture: Lettura[];
  letturista: Letturista;
  status: string;
  date?: Moment;
  timeFrame?: string;
}
