import {Injectable, OnInit, ViewChild} from '@angular/core';
import {ImmutableDataLoader} from '../../../../models/data-loader';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {And, Logical, Or} from '../../../../models/query-lang/logical';
import {Like} from '../../../../models/query-lang/comparison';
import {Ascending, Descending} from '../../../../models/query-lang/order';
import {Sort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';

@Injectable()
export abstract class BaseTableComponent<T> implements OnInit {
  public columns: Column[];
  public displayedColumns: string[];
  public loader: ImmutableDataLoader<T>;

  public total$: Observable<number>;
  public to = 0;
  public numLoaded = 0;

  protected baseQuerySubject = new BehaviorSubject<Logical>(null);
  protected querySubject = new BehaviorSubject<string>('');
  protected sortSubject: BehaviorSubject<Sort>;
  protected selectOnlyColumns = true;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  protected constructor(columns: Column[], defaultSort: Sort) {
    this.columns = columns;
    this.displayedColumns = columns.map(column => column.name);
    this.sortSubject = new BehaviorSubject(defaultSort);
  }

  ngOnInit() {
    combineLatest([this.baseQuerySubject, this.querySubject, this.sortSubject]).pipe(debounceTime(300)).subscribe(value => {
      const or = new Or(
        this.columns.filter(column => column.isFilterable).map(column => new Like(column.name, `%${value[1]}%`))
      );

      const query = {
        fields: this.selectOnlyColumns ? this.columns.map(column => column.name) : null,
        filters: value[0] ? new And([value[0], or]) : or,
        orders: null
      };

      if (value[1] !== null && value[2].direction.length > 0) {
        query.orders = value[2].direction === 'asc' ?
          [new Ascending(value[2].active)] :
          [new Descending(value[2].active)];
      }

      this.loader = this.loader.newQuery(query);
      this.total$ = this.loader.totalObs;
      this.loader.loadNext();
    });
  }

  public onTableScrolled(): void {
    this.loader.loadNext();
  }
  
  public onSearchChanged(value: string): void {    
    this.querySubject.next(value);
  }

  public onSort(event: Sort): void {
    this.sortSubject.next(event);
  }

  public onFiltersUpdated(filters: Logical): void {
    this.baseQuerySubject.next(new And([...filters.args]));
    this.loader.loadNext();
  }



}

export class Column {
  name: string;
  isFilterable: boolean;
}
