<div class="h100 container">
  <div class="h100 flex-column-fill filters-container">
    <form *ngIf="formGroup" [formGroup]="formGroup" fxLayout="column"
          class="filter-form"
          (keydown.enter)="$event.preventDefault(); onConfirmButtonClicked()">

      <ng-container *ngFor="let filter of filters">
        <mat-form-field *ngIf="filter.type === 'string'">
          <mat-label>{{filter.label}}</mat-label>
          <input matInput [formControlName]="filter.column">
        </mat-form-field>

        <mat-form-field *ngIf="filter.type === 'number'">
          <mat-label>{{filter.label}}</mat-label>
          <input matInput type="number" [formControlName]="filter.column">
        </mat-form-field>

        <mat-checkbox *ngIf="filter.type === 'boolean'" [formControlName]="filter.column">
          {{filter.label}}
        </mat-checkbox>

        <mat-form-field *ngIf="filter.type === 'date'">
          <mat-label>{{filter.label}}</mat-label>
          <input matInput [formControlName]="filter.column" [matDatepicker]="datePicker">
          <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
          <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field *ngIf="filter.type === 'array'">
          <mat-label>{{filter.label}}</mat-label>
          <mat-select [formControlName]="filter.column">
            <mat-option *ngFor="let value of filter.values" [value]="value.value">{{value.label}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="filter.type === 'multiple_string'">
          <mat-label>{{filter.label}}</mat-label>
          <mat-chip-grid #chipList aria-label="selection">
            <mat-chip-row *ngFor="let string of chipContents" [selectable]="selectable"
                      [removable]="removable" (removed)="removeChip(string)">
              {{string}}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip-row>
            <mat-chip-row *ngIf="chipContents.length > 0"
                      color="warn"
                      [selectable]="selectable"
                      (click)="clearAllChips()">
              Pulisci Filtro
              <mat-icon matChipRemove>cached</mat-icon>
            </mat-chip-row>
            <input placeholder="Scrivi qui..."
                   [formControlName]="filter.column"
                   [matChipInputFor]="chipList"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   [matChipInputAddOnBlur]="addOnBlur"
                   (matChipInputTokenEnd)="addChip($event)"
                   (paste)="addPasteChip($event)">
          </mat-chip-grid>
        </mat-form-field>

      </ng-container>
    </form>
    <div>
      <button class="submit-button" mat-raised-button (click)="onClearButtonClicked()">RIMUOVI FILTRI</button>
      <button class="submit-button" mat-raised-button type="submit" (click)="onConfirmButtonClicked()" color="accent">
        APPLICA
      </button>
    </div>
  </div>
</div>

