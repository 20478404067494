import { Component, ElementRef, HostBinding, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MapService } from '../../../services/map.service';
import { Swm } from 'src/models/raw/swm';
import { SwmService } from 'src/app/services/swm.service';
import { Chart, BarController, LinearScale, CategoryScale, BarElement } from 'chart.js';
import { SwmDetailService } from 'src/app/services/swm-detail.service';
import { MatTableDataSource } from '@angular/material/table';
Chart.register(BarController, LinearScale, CategoryScale, BarElement);

@Component({
  selector: 'app-swm-detail',
  templateUrl: './swm-detail.component.html',
  styleUrls: ['../base-table/base-table.component.scss', './swm-detail.component.scss']
})
export class SwmDetailComponent implements OnInit {
  @ViewChild('chartCanvas') chartCanvas: ElementRef;
  @HostBinding('class') classes = 'flex-column-fill';

  public isLoadingPreviousAlarms = false;
  public readonly alarmsColumns = ['descrizione', 'trasmissionDate'];
  public alarms = new MatTableDataSource([]);

  public isLoadingPreviousValues = false;
  public readonly valuesColumns = ['modalita', 'trasmissionDate', 'value'];
  public values = new MatTableDataSource([]);

  public swm: Swm;
  public latitude: number;
  public longitude: number;
  public titles: string[];
  keysToRemove = [
    'chiave_decriptazione_walk_by',
    'appeui_chiavi_lorawan',
    'appkey_chiavi_lorawan',
    'chiave_decriptazione_lorawan_aggiuntiva'
  ];

  constructor(
    private route: ActivatedRoute,
    public swmService: SwmService,
    public mapService: MapService,
    private _service: SwmDetailService) {
  }

  async ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this._service.getSwm(id)
      .then(swm => {
        if (swm) {
          const index = swm.data.find(a => a['deveui_codice_identificativo_radio_lorawan'] == id);
          this.swm = index;
          this.titles = Object.keys(this.swm);
          this.titles = this.titles.filter(key => !this.keysToRemove.includes(key.toLowerCase()));
          this.latitude = this.swm?.latitudine ? +this.swm?.latitudine : null;
          this.longitude = this.swm?.longitudine ? +this.swm?.longitudine : null;

          this.isLoadingPreviousValues = true;
          this._service.getLetture(id).then(letture => {
            console.log(letture);

            this.isLoadingPreviousValues = false;
            this.values.data = letture.data;
          });

          this.isLoadingPreviousAlarms = true;
          this._service.getAlarms(id).then(alarms => {
            console.log(alarms);
            this.isLoadingPreviousAlarms = false;
            this.alarms.data = alarms.data;
          });
        }
      });
  }

  getTitle(val: string): string {
    switch (val) {
      case 'deveui_codice_identificativo_radio_lorawan': return 'deveui';
      case 'codice_utente': return 'codice servizio';
      default: return val;
    }
  }

  getDate(col: any) {
    const data = new Date(col);
    const dataString = data.toLocaleDateString();
    const oraString = data.toLocaleTimeString();

    return `${dataString} ${oraString}`;
  }
}