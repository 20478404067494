<mat-toolbar class="sub-toolbar mat-elevation-z4" fxLayout="row" fxLayoutAlign="space-between center">
  {{letturista ? letturista.letturista_nome : ''}}

  <app-entity-input [filterColumns]="['letturista_nome']" [loader]="dataLoaderProvider.getLetturistiLoader(10)"
    [entityTemplate]="letturistaTemplate" (entitySelected)="onLetturistaChanged($event)"></app-entity-input>

  <div></div>

  <ng-template #letturistaTemplate let-entity>
    {{entity.letturista_nome}}
  </ng-template>
</mat-toolbar>

<google-map class="h100" [center]="{ lat: 43.082027, lng: 12.444571 }" [zoom]="7"
  [options]="{ styles: mapService.mapStyles }">

  <!-- Polyline -->
  <map-polyline *ngIf="loader && (loader.items | async)">
    <map-polyline-point *ngFor="let posizioneLetturista of loader.items | async"
      [position]="{ lat: posizioneLetturista.latitudine, lng: posizioneLetturista.longitudine }">
    </map-polyline-point>
  </map-polyline>

  <!-- Marker for posizioneLetturistaMarker -->
  <map-marker *ngIf="posizioneLetturistaMarker"
    [position]="{ lat: posizioneLetturistaMarker.latitudine, lng: posizioneLetturistaMarker.longitudine }"
    [options]="{ icon: 'assets/markers/workloads/saturday/marker_' + posizioneLetturistaMarker.terminale + '.png' }">
    <map-info-window>
      {{posizioneLetturistaMarker.dataoraposizione | momentTime}}
    </map-info-window>
  </map-marker>

  <!-- Markers for mapService.coordinates -->
  <map-marker *ngFor="let coordinate of mapService.coordinates"
    [position]="{ lat: coordinate.lat, lng: coordinate.lng }" [options]="{ icon: coordinate.markerIcon }">
    <map-info-window>
      <app-activity-details [letture]="coordinate.body" [showActions]="false"></app-activity-details>
    </map-info-window>
  </map-marker>
</google-map>

<!-- <agm-map class="h100"
         [latitude]="43.082027"
         [longitude]="12.444571"
         [zoom]="7"
         [fitBounds]="loader && (loader.items | async).length > 0"
         [styles]="mapService.mapStyles">
  <agm-polyline *ngIf="loader && (loader.items | async)">
    <agm-polyline-point *ngFor="let posizioneLetturista of loader.items | async"
                        [latitude]="posizioneLetturista.latitudine"
                        [longitude]="posizioneLetturista.longitudine"
                        [agmFitBounds]="true"></agm-polyline-point>
  </agm-polyline>

  <agm-marker *ngIf="posizioneLetturistaMarker"
              [latitude]="posizioneLetturistaMarker.latitudine"
              [longitude]="posizioneLetturistaMarker.longitudine"
              [iconUrl]="'assets/markers/workloads/saturday/marker_'+posizioneLetturistaMarker.terminale+'.png'">
    <agm-snazzy-info-window border="true" closeOnMapClick="true">
      <ng-template>
        {{posizioneLetturistaMarker.dataoraposizione | momentTime}}
      </ng-template>
    </agm-snazzy-info-window>
  </agm-marker>

  <agm-marker *ngFor="let coordinate of mapService.coordinates"
              [iconUrl]="coordinate.markerIcon"
              [latitude]="coordinate.lat"
              [longitude]="coordinate.lng">
    <agm-snazzy-info-window
      [border]="true"
      [maxHeight]="400"
      [maxWidth]="800"
      [padding]="'0px'">
      <ng-template>
        <app-activity-details [letture]="coordinate.body" [showActions]="false"></app-activity-details>
      </ng-template>
    </agm-snazzy-info-window>
  </agm-marker>
</agm-map> -->

<mat-progress-bar *ngIf="(loader && (loader.loading | async)) || (lettureLoader && (lettureLoader.loading | async))"
  mode="indeterminate" color="accent"></mat-progress-bar>

<h4 *ngIf="!letturista">Seleziona un operatore per vederne il posizionamento.</h4>

<mat-card appearance="outlined" id="slider-container">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-icon-button (click)="previous()" matTooltip="Giorno precedente (CTRL + &larr;)"
      matTooltipPosition="above">
      <mat-icon>chevron_left</mat-icon>
    </button>

    {{(dateSubject | async) | momentDate}}

    <button mat-icon-button (click)="next()" matTooltip="Giorno successivo (CTRL + &rarr;)" matTooltipPosition="above">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>

  <mat-card-content fxLayout="column" fxLayoutAlign="wrap center">
    <!-- TODO: The 'tickInterval' property no longer exists -->
    <mat-slider class="w100" min="0" max="95"
      (valueChange)="onSliderValueChanged($event)"><input matSliderThumb [value]="sliderValue" /></mat-slider>
    <span>{{sliderValue | timeSlider}}</span>
  </mat-card-content>
</mat-card>