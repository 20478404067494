import { Component } from '@angular/core';
import { DataLoaderProviderService } from '../../../services/data-loader-provider.service';
import { BaseTableComponent } from '../base-table/base-table.component';
import { SortDirection } from '@angular/material/sort';
import { Export } from 'src/models/raw/export';
import { Router } from '@angular/router';
import { ExportService } from 'src/app/services/export.service';
import { CaricoFile } from 'src/models/raw/carico_file';
import { MatDialog } from '@angular/material/dialog';
import { CaricofileDialogNewExportComponent } from '../../dialogs/caricofile-dialog-new-export/caricofile-dialog-new.component';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['../base-table/base-table.component.scss', './export.component.scss']
})
export class ExportComponent extends BaseTableComponent<Export> {

  loading = false;

  constructor(
    private _service: ExportService,
    private dataLoaderProvider: DataLoaderProviderService,
    private router: Router,
    private dialog: MatDialog) {
    super(
      [
        { name: 'started_at', isFilterable: true },
        { name: 'finished_at', isFilterable: true },
        { name: 'username_user', isFilterable: true },
        { name: 'nome_esportazione', isFilterable: true },
        { name: 'tipologia_file', isFilterable: true },
        { name: 'stato_esportazione', isFilterable: true },
        { name: 'error', isFilterable: true },
        { name: 'warning', isFilterable: true },
        { name: 'id', isFilterable: true },
      ],
      { active: 'started_at', direction: 'desc' as SortDirection }
    );
    this.displayedColumns.push('button');
    this.loader = this.dataLoaderProvider.getExportsLoader();
  }

  async exportFile(file: Export) {
    this.loading = true;
    await this._service.exportFile(file.id)
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = file['nome_esportazione'] + '.zip';  // Nome del file
        document.body.appendChild(anchor);  // Aggiungi all'HTML
        anchor.click();
        document.body.removeChild(anchor);  // Rimuovi l'elemento
        URL.revokeObjectURL(url);  // Pulisci l'URL temporaneo
      });

    this.loading = false;
  }

  showMore(file: Export) {
    if (file.error) alert(file.error);
    if (file.warning) alert(file.warning);
  }

  public onUploadButtonClicked() {
    this.dialog.open(CaricofileDialogNewExportComponent, {}).afterClosed().toPromise().then((caricoFile: CaricoFile) => {
      if (caricoFile) {
        this.loader = this.loader.newQuery(this.loader.currentQuery);
        this.loader.loadNext();
      }
    });
  }

  getDate(col: any) {
    return new Date(col).toLocaleDateString();
  }
}
