import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { Lettura } from '../../../../models/raw/lettura';
import { DataLoaderProviderService } from '../../../services/data-loader-provider.service';
import { Catalogo } from '../../../../models/raw/catalogo';
import { And } from '../../../../models/query-lang/logical';
import { Equal } from '../../../../models/query-lang/comparison';
import { MapService } from '../../../services/map.service';
import { GeolocateManualDialogComponent, Payload } from '../../dialogs/geolocate-manual-dialog/geolocate-manual-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Swm } from 'src/models/raw/swm';

@Component({
  selector: 'app-activity-details',
  templateUrl: './activity-details.component.html',
  styleUrls: ['./activity-details.component.scss']
})
export class ActivityDetailsComponent implements OnInit {

  public classi = new Map<string, Catalogo>();

  @Input()
  public letture: Lettura[];

  @Input()
  public showActions = false;

  @Output()
  public onLetturaUpdated: EventEmitter<Swm> = new EventEmitter();

  constructor(private dataLoaderProvider: DataLoaderProviderService,
    private dialog: MatDialog,
    private ngZone: NgZone,
    public mapService: MapService) { }

  ngOnInit() {
    for (const lettura of this.letture) {
      if (!this.classi.has(`${lettura.codice_ente}_${lettura.classe}`)) {
        this.classi.set(`${lettura.codice_ente}_${lettura.classe}`, null);
        const loader = this.dataLoaderProvider.getCataloghiLoader().newQuery({
          fields: ['descrizione'],
          filters: null,
        });
        loader.items.subscribe(value => this.classi.set(
          `${lettura.codice_ente}_${lettura.classe}`,
          value !== null && value.length > 0 ? value[0] : null
        ));
        loader.loadNext();
      }
    }
  }

  public onLetturaClicked(lettura: Swm): void {
    if (this.showActions) {
      this.mapService.toggle(lettura);
    }
  }

  public async onEditLocationButtonClicked(lettura: Swm): Promise<void> {
    const result = await this.dialog.open<GeolocateManualDialogComponent, Payload, any>(GeolocateManualDialogComponent, {
      data: {
        lettura: lettura,
      }
    }).afterClosed().toPromise();

    if (result === true) {
      this.onLetturaUpdated.emit(lettura);
    }
  }

  public onOpenMenuButtonClicked(event: MouseEvent): void {
    event.stopImmediatePropagation();
    this.ngZone.run(() => {
      console.log('');
    });
  }
}
