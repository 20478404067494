import {Injectable} from '@angular/core';
import {Appalto} from '../../models/raw/appalto';
import {DataLoaderProviderService} from './data-loader-provider.service';
import {LoaderStatus} from '../../models/data-loader';
import {takeWhile, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppaltiService {
  public appalti: Appalto[];

  constructor(private dataLoaderProviderService: DataLoaderProviderService) { }

  public async load(): Promise<Appalto[]> {
    const dataLoader = this.dataLoaderProviderService.getAppaltiLoader();

    await dataLoader.status.pipe(
      takeWhile(status => status !== LoaderStatus.DONE),
      tap(status => dataLoader.loadNext())
    ).toPromise();

    this.appalti = dataLoader.items.getValue();
    return this.appalti;
  }
}
