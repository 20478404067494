<div class="h100 container">
  <mat-toolbar class="sub-toolbar mat-elevation-z4">
    <app-search-input (search)="onSearchChanged($event)"></app-search-input>
  </mat-toolbar>
  <mat-progress-bar *ngIf="(loader.loading | async)" mode="indeterminate" color="accent"></mat-progress-bar>

  <div infiniteScroll
       [scrollWindow]="false"
       (scrolled)="onTableScrolled()"
       [infiniteScrollDistance]="1"
       class="table-container">
    <mat-table matSort
               matSortActive="id"
               matSortDirection="desc"
               (matSortChange)="onSort($event)"
               [dataSource]="loader"
               class="mat-elevation-z8">
      <ng-container matColumnDef="id">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Id</mat-header-cell>
        <mat-cell *matCellDef="let lavoro">{{lavoro.id}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="nome">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Nome</mat-header-cell>
        <mat-cell *matCellDef="let lavoro">{{lavoro.nome}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="tipo">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Tipo</mat-header-cell>
        <mat-cell *matCellDef="let lavoro">{{lavoro.tipo}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="data_ora_inizio">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Data ora inizio</mat-header-cell>
        <mat-cell *matCellDef="let lavoro">{{lavoro.data_ora_inizio | momentDate}} {{lavoro.data_ora_inizio ? (lavoro.data_ora_inizio | momentTime) : ''}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="data_ora_fine">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Data ora fine</mat-header-cell>
        <mat-cell *matCellDef="let lavoro">{{lavoro.data_ora_fine | momentDate}} {{lavoro.data_ora_fine ? (lavoro.data_ora_fine | momentTime) : ''}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="stato">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Stato</mat-header-cell>
        <mat-cell *matCellDef="let lavoro">
          <mat-icon *ngIf="lavoro.stato === 'in_coda' || lavoro.stato === 'in_corso'">sync</mat-icon>
          <mat-icon *ngIf="lavoro.stato === 'completato'" class="success">check_circle</mat-icon>
          <mat-icon *ngIf="lavoro.stato === 'errore'" class="error">error_outline</mat-icon>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
      <mat-row mat-ripple *matRowDef="let lavoro; columns: displayedColumns;" class="selectable-row" (click)="lavoriService.onLavoroSelected(lavoro)"></mat-row>
    </mat-table>
  </div>
</div>
