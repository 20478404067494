import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../../models/raw/user';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public user: User;
  public userObser = new BehaviorSubject<User>(null);

  constructor(private http: HttpClient) { }

  public async load(): Promise<User> {
    const user = await this.http.get<User>(`${environment.baseUrl}api/user`).toPromise();
    if (!user.ufficio) {
      throw new Error('Your account is not allowed to access this website.');
    }

    this.userObser.next(user);
    this.user = user;
    return this.user;
  }
}
