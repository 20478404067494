import {Pipe, PipeTransform} from '@angular/core';
import {Lettura} from '../../models/raw/lettura';
import { Swm } from 'src/models/raw/swm';

@Pipe({
  name: 'letturaAddress'
})
export class LetturaAddressPipe implements PipeTransform {

  transform(value: Swm, args?: any): string {
    let address = '';
    if (!!value.indirizzo && value.indirizzo.length > 0) {
      address = `${value.indirizzo}`;
    }
    if (!!value.civico && value.civico.length > 0) {
      address = `${address} ${value.civico}`;
    }
    if (!!value.barrato && value.barrato.length > 0) {
      address = `${address} ${value.barrato}`;
    }
    if (!!value.comune && value.comune.length > 0) {
      address = `${address}, ${value.comune}`;
    }
    // if (!!value.cap && value.cap.length > 0) {
    //   address = `${address}, ${value.cap}`;
    // }
    if (!!value.localita && value.localita.length > 0) {
      address = `${address}, ${value.localita}`;
    }
    // if (!!value.provincia && value.provincia.length > 0) {
    //   address = `${address}(${value.provincia})`;
    // }
    return address;
  }

}
