<h1 mat-dialog-title>Dati richiesti</h1>
<form mat-dialog-content [formGroup]="formGroup" fxLayout="column" (keydown.enter)="onConfirmButtonClicked()">
  <ng-container *ngFor="let field of data.fields">
    <mat-form-field *ngIf=" field.type === 'string'" appearance="outline">
      <mat-label>{{field.label}}</mat-label>
      <input matInput [formControlName]="field.column" [required]="field.required">
    </mat-form-field>

    <mat-form-field *ngIf="field.type === 'number'" appearance="outline">
      <mat-label>{{field.label}}</mat-label>
      <input matInput type="number" [formControlName]="field.column" [required]="field.required">
    </mat-form-field>

    <mat-checkbox *ngIf="field.type === 'boolean'" [formControlName]="field.column" [required]="field.required">
      {{field.label}}
    </mat-checkbox>

    <mat-form-field *ngIf="field.type === 'date'" appearance="outline">
      <mat-label>{{field.label}}</mat-label>
      <input matInput [formControlName]="field.column" [matDatepicker]="datePicker" [required]="field.required">
      <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
      <mat-datepicker #datePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field *ngIf="field.type === 'array'" appearance="outline">
      <mat-label>{{field.label}}</mat-label>
      <mat-select [formControlName]="field.column" [required]="field.required">
        <mat-option *ngFor="let value of field.values" [value]="value.value">{{value.label}}</mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
</form>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Annulla</button>
  <button mat-raised-button color="accent" (click)="onConfirmButtonClicked()" [disabled]="formGroup.invalid">Conferma</button>
</mat-dialog-actions>
