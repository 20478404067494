import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ExportService {


    constructor(
        private _http: HttpClient) {
    }

    exportFile(id: number): Promise<any> {
        return this._http.get(environment.baseUrl + 'api/client/lorawan/export/getFiles/' + id, { responseType: 'blob' }).pipe(take(1)).toPromise();
    }
}
