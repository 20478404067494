<div class="h100 flex-column-fill">
  <mat-toolbar class="mat-elevation-z4" color="primary">
    <a mat-button href="#" routerLink="/map">
      <div fxLayout="row" fxLayoutAlign="center center">
        <img id="logo" src="assets/world.svg">
        <div id="line"></div>
        <h2 class="mat-headline-6">GEA Web</h2>
      </div>
    </a>
    <div class="links-container" *ngIf="prerequisitesService.isLoaded" fxLayout="row">
      <a *ngIf="hasValue('Mappa')" mat-button href="#" routerLink="/map" routerLinkActive="active">Mappa</a>
      <a *ngIf="hasValue('Monitor')" mat-button href="#" routerLink="/last-value" routerLinkActive="active">Monitor</a>
      <!-- <a *ngIf="hasValue('Attività')" mat-button href="#" routerLink="/letture" routerLinkActive="active">Attività</a> -->
      <!-- <a *ngIf="hasValue('Carichi')" mat-button href="#" routerLink="/carichi" routerLinkActive="active">Carichi</a> -->
      <a *ngIf="hasValue('Magazzino')" mat-button href="#" routerLink="/magazzino"
        routerLinkActive="active">Magazzino</a>
      <a *ngIf="hasValue('Magazzino Consumabili')" mat-button href="#" routerLink="/magazzino_consumabili"
        routerLinkActive="active">Magazzino Consumabili</a>
      <a *ngIf="hasValue('Tracking')" mat-button href="#" routerLink="/tracking" routerLinkActive="active">Tracking</a>
      <a *ngIf="hasValue('Report')" mat-button href="#" routerLink="/reports" routerLinkActive="active">Report</a>
      <a *ngIf="hasValue('SWM')" mat-button href="#" routerLink="/swm" routerLinkActive="active">SWM</a>
      <a *ngIf="hasValue('Allarmi')" mat-button href="#" routerLink="/alarms" routerLinkActive="active">Allarmi</a>
      <a *ngIf="hasValue('Importazioni')" mat-button href="#" routerLink="/imports"
        routerLinkActive="active">Importazioni</a>
      <a *ngIf="hasValue('Esportazioni')" mat-button href="#" routerLink="/exports"
        routerLinkActive="active">Esportazioni</a>
    </div>

    <span class="toolbar-fill"></span>

    <!-- <app-ente-selector *ngIf="prerequisitesService.isLoaded"></app-ente-selector> -->
    <app-functions *ngIf="prerequisitesService.isLoaded"></app-functions>
    <!-- <a mat-icon-button *ngIf="prerequisitesService.isLoaded" href="#" routerLink="/lavori" matTooltip="Operazioni">
      <mat-icon>access_time</mat-icon>
    </a> -->
    <a mat-icon-button *ngIf="prerequisitesService.isLoaded" href="#" routerLink="/frame" matTooltip="Frame">
      <mat-icon>filter_frames</mat-icon>
    </a>
    <!-- <a mat-icon-button *ngIf="prerequisitesService.isLoaded" href="#" routerLink="/settings" matTooltip="Impostazioni">
      <mat-icon>settings</mat-icon>
    </a> -->
    <button *ngIf="prerequisitesService.isLoaded" (click)="logout()" mat-icon-button matTooltip="Logout">
      <mat-icon>power_settings_new</mat-icon>
    </button>
  </mat-toolbar>

  <router-outlet></router-outlet>
</div>