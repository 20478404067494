<mat-toolbar class="sub-toolbar mat-elevation-z4">
  <!-- <app-search-input (search)="onSearchChanged($event)"></app-search-input> -->

  <div>
    <!-- <button mat-icon-button (click)="filtersSidenav.toggle()" matTooltip="Apri/Chiudi pannello filtri">
      <mat-icon>filter_list</mat-icon>
    </button> -->

    <button routerLink="/alarms" mat-icon-button matTooltip="Apri/Chiudi pannello Mappa">
      <mat-icon>list</mat-icon>
    </button>
  </div>
</mat-toolbar>

<div class="h100 container">
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate" color="accent"></mat-progress-bar>

  <google-map style="width: 100%; height: 1000px;" #googleMap
    [center]="{lat: mapService.latitude, lng: mapService.longitude}" [zoom]="mapService.zoom"
    [options]="{ styles: mapService.mapStyles }" class="h100">

    <map-marker (mapClick)="openInfo(marker, i)" #marker="mapMarker" *ngFor="let coordinate of pins; let i = index"
      [position]="{lat: +coordinate.latitudine, lng: +coordinate.longitudine}">

      <map-info-window>
        <div class="info-window-grid">
          <div class="grid-item">Descrizione: {{ coordinate.descrizione_allarme }}</div>
          <div class="grid-item">Modello: {{ coordinate.modello_contatore }}</div>
          <div class="grid-item">Matricola: {{ coordinate.matricola }}</div>
          <!-- Aggiungi altre informazioni qui -->
        </div>
      </map-info-window>
    </map-marker>
  </google-map>
</div>