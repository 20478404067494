import { Component } from '@angular/core';
import { DataLoaderProviderService } from '../../../services/data-loader-provider.service';
import { BaseTableComponent } from '../base-table/base-table.component';
import { SortDirection } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Frame } from 'src/models/raw/frame';

@Component({
  selector: 'app-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['../base-table/base-table.component.scss', './frame.component.scss']
})
export class FrameComponent extends BaseTableComponent<Frame> {


  constructor(private dataLoaderProvider: DataLoaderProviderService,
    private router: Router) {
    super(
      [
        { name: 'matricola', isFilterable: true },
        { name: 'modalita', isFilterable: true },
        { name: 'raw_message', isFilterable: true }
      ],
      { active: 'id', direction: 'desc' as SortDirection }
    );
    this.loader = this.dataLoaderProvider.getFrameLoader(50, 'get');
    this.displayedColumns.push('button');
  }

  detail(frame: Frame) {
    alert(frame['raw_message']);
  }
}
