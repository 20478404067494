<mat-toolbar class="sub-toolbar mat-elevation-z4" fxLayout="row" fxLayoutAlign="space-between center">
  <div></div>

  <app-search-input (search)="onSearchChanged($event)"></app-search-input>

  <div>
    <span style="margin-right: 20px; font-font: Roboto, 'Helvetica Neue', sans-serif;font-size: 16px ">Records Trovati: {{total$ | async}}</span>

    <button mat-icon-button (click)="onTerminalAssociationButtonClicked()" matTooltip="Verifica Associazioni">
      <mat-icon>search</mat-icon>
    </button>

    <button mat-icon-button (click)="onAssociateButtonClicked()" matTooltip="Nuova associazione">
      <mat-icon>add</mat-icon>
    </button>

    <a mat-icon-button routerLink="/stati_magazzino" matTooltip="Gestisci Stati">
      <mat-icon>assignment</mat-icon>
    </a>

    <button mat-icon-button (click)="filtersSidenav.toggle()" matTooltip="Apri/Chiudi pannello filtri">
      <mat-icon>filter_list</mat-icon>
    </button>

  </div>
</mat-toolbar>

<mat-sidenav-container>

  <mat-sidenav #filtersSidenav mode="side" position="end">
    <app-filters [filters]="filters" (filtersChanged)="onFiltersUpdated($event)"></app-filters>
  </mat-sidenav>

  <mat-sidenav-content *ngIf="loader">

    <div class="h100 container">

      <mat-progress-bar *ngIf="(loader.loading | async)" mode="indeterminate" color="accent"></mat-progress-bar>

      <div infiniteScroll
           [scrollWindow]="false"
           (scrolled)="onTableScrolled()"
           [infiniteScrollDistance]="1"
           class="table-container">
        <mat-table matSort
                   matSortActive="id"
                   matSortDirection="asc"
                   (matSortChange)="onSort($event)"
                   [dataSource]="loader"
                   class="mat-elevation-z8">
          <ng-container matColumnDef="id">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Id</mat-header-cell>
            <mat-cell *matCellDef="let magazzino">{{magazzino.id}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="terminale">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Terminale</mat-header-cell>
            <mat-cell *matCellDef="let magazzino">{{magazzino.terminale}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="articolo">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Articolo</mat-header-cell>
            <mat-cell *matCellDef="let magazzino">{{magazzino.articolo}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="stato">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Stato</mat-header-cell>
            <mat-cell *matCellDef="let magazzino">{{magazzino.stato?.stato}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="descrizione">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Descrizione</mat-header-cell>
            <mat-cell *matCellDef="let magazzino">{{magazzino.descrizione}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="matricola">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Matricola</mat-header-cell>
            <mat-cell *matCellDef="let magazzino">{{magazzino.matricola}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="scatola">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Scatola</mat-header-cell>
            <mat-cell *matCellDef="let magazzino">{{magazzino.scatola}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="bancale">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Bancale</mat-header-cell>
            <mat-cell *matCellDef="let magazzino">{{magazzino.bancale}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let magazzino">
              <mat-menu #menu="matMenu">
                <button *ngIf="!magazzino.stato" mat-menu-item (click)="onTerminalAssociateButtonButtonClicked(magazzino)">Assegna Terminale</button>
                <button mat-menu-item (click)="onChangeStatusButtonButtonClicked(magazzino)">Assegna Stato</button>
                <button *ngIf="magazzino.stato" mat-menu-item (click)="onRemoveStatusClicked(magazzino)">Rimuovi Stato</button>
              </mat-menu>

              <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
          <mat-row *matRowDef="let magazzino; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

