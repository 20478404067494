<h1 mat-dialog-title>Assegnazione letture</h1>
<mat-dialog-content>
    <mat-tab-group (selectedIndexChange)="selectedTabChanged($event)" mat-align-tabs="center">
        <!-- <mat-tab label="Carichi esistenti">
            <form *ngIf="lavorazioni.length === 1" [formGroup]="oldCaricoForm">
                <mat-progress-bar *ngIf="carichiLoader.loading | async" [mode]="'indeterminate'"
                                  color="accent"></mat-progress-bar>
                <mat-radio-group (scrolled)="onScrollDown()"
                                 [infiniteScrollDistance]="2"
                                 [scrollWindow]="false"
                                 class="flex-column-fill"
                                 formControlName="carico"
                                 infiniteScroll>
                    <mat-radio-button *ngFor="let carico of carichiLoader.items | async" [value]="carico">
                        {{carico.caricoterminali_descrizione}}
                    </mat-radio-button>
                </mat-radio-group>
            </form>
            <p *ngIf="lavorazioni.length > 1" class="carichi_error">
                Non puoi utilizzare un carico esistente perché le letture che hai selezionato provengono da lavorazioni
                differenti.
            </p>
        </mat-tab> -->
        <mat-tab label="Nuovo carico">
            <form [formGroup]="newCaricoForm">
                <!-- <div class="container-218">
                    <h3>Pianificazione (Delibera 218)</h3>
                    <small>In caso di pianificazione per delibera 218, i campi "Dal" e "Al" verranno compilati
                        automaticamente e non saranno modificabili.
                    </small>

                    <div class="dates-form-container">
                        <mat-form-field>
                            <input (dateChange)="dateChanged($event)" [matDatepicker]="datePicker"
                                   formControlName="date" matInput
                                   placeholder="Data">
                            <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
                            <mat-datepicker #datePicker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Fascia oraria</mat-label>
                            <mat-select (selectionChange)="timeSlotChanged($event)" formControlName="timeSlot">
                                <mat-option></mat-option>
                                <mat-option *ngFor="let timeSlot of data.ente.fascie_orarie.split(',')"
                                            [value]="timeSlot">{{timeSlot | timeSlot}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div> -->

                <mat-form-field>
                    <input formControlName="description" matInput placeholder="Descrizione" required>
                    <mat-error>Questo campo è obbligatorio.</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <input formControlName="nota" matInput placeholder="Nota">
                </mat-form-field>

                <div class="dates-form-container">
                    <mat-form-field>
                        <input [matDatepickerFilter]="fromDateFilter" [matDatepicker]="startPicker"
                               formControlName="from"
                               matInput placeholder="Dal" required>
                        <mat-datepicker-toggle [for]="startPicker" matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #startPicker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field>
                        <input [matDatepickerFilter]="toDateFilter" [matDatepicker]="endPicker" formControlName="to"
                               matInput placeholder="Al" required>
                        <mat-datepicker-toggle [for]="endPicker" matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #endPicker></mat-datepicker>
                    </mat-form-field>
                </div>
            </form>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions>
    <div>
        <button mat-button mat-dialog-close>Annulla</button>
        <button (click)="confirmButtonClicked()" [disabled]="isConfirmButtonDisabled" color="accent" mat-raised-button>
            Conferma
        </button>
    </div>

    <mat-spinner *ngIf="isLoading" color="accent" diameter="24"></mat-spinner>
</mat-dialog-actions>

