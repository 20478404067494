import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseTableComponent } from '../base-table/base-table.component';
import { Lavoro } from '../../../../models/raw/lavoro';
import { SortDirection } from '@angular/material/sort';
import { EchoService } from 'ngx-laravel-echo';
import { UserService } from '../../../services/user.service';
import { DataLoaderProviderService } from '../../../services/data-loader-provider.service';
import { Subscription } from 'rxjs';
import { LavoriService } from '../../../services/lavori.service';

@Component({
  selector: 'app-lavori',
  templateUrl: './lavori.component.html',
  styleUrls: ['../base-table/base-table.component.scss', './lavori.component.scss']
})
export class LavoriComponent extends BaseTableComponent<Lavoro> implements OnDestroy, OnInit {
  private echoSubscription: Subscription;

  constructor(private dataLoaderProvider: DataLoaderProviderService,
    private echoService: EchoService,
    private userService: UserService,
    public lavoriService: LavoriService) {
    super(
      [
        { name: 'id', isFilterable: false },
        { name: 'nome', isFilterable: true },
        { name: 'tipo', isFilterable: true },
        { name: 'data_ora_inizio', isFilterable: false },
        { name: 'data_ora_fine', isFilterable: false },
        { name: 'stato', isFilterable: false },
      ],
      { active: 'id', direction: 'desc' as SortDirection }
    );
    this.selectOnlyColumns = false;
    this.loader = this.dataLoaderProvider.getLavoriLoader();
  }

  ngOnInit() {
    super.ngOnInit();

    this.echoSubscription = this.echoService.listen(`user.${this.userService.user.id}`, '.lavori').subscribe(event => {
      const index = this.loader.items.value.findIndex(lavoro => lavoro.id === event.lavoro.id);
      const items = this.loader.items.value;
      if (index >= 0) {
        items.splice(index, 1, event.lavoro);
      } else {
        items.splice(0, 0, event.lavoro);
      }
      this.loader.items.next(items);
    });
  }

  ngOnDestroy() {
    this.echoSubscription.unsubscribe();
  }
}
