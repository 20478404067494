import { Component } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import { PrerequisitesService } from './services/prerequisites.service';
import { UserService } from './services/user.service';
import { User } from 'src/models/raw/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  user: User;

  constructor(private oauthService: OAuthService, public prerequisitesService: PrerequisitesService, public router: Router,
    private userService: UserService) {

    const script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=' + environment.googleMapsKey;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    this.oauthService.configure({
      clientId: environment.oauth2ClientId,
      dummyClientSecret: environment.oauth2ClientSecret,
      requireHttps: false,
      tokenEndpoint: environment.baseUrl + 'oauth/token',
      scope: ''
    });

    this.userService.userObser.subscribe(res => {
      if (res) this.user = res;
    });
  }

  logout() {
    this.oauthService.logOut();
    this.prerequisitesService.clear();
    this.router.navigate(['login']).then(() => window.location.reload());
  }

  hasValue(value: string): boolean {
    const lowerCaseValues = this.user?.fotolettura_user?.config.header ? Object.values(this.user.fotolettura_user.config.header).map((val: any) => val.toLowerCase()) : [];
    return lowerCaseValues.includes(value.toLowerCase());
  }
}
