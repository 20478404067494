import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-terminale-association-count',
  templateUrl: './terminale-association-count.component.html',
  styleUrls: ['./terminale-association-count.component.scss']
})
export class TerminaleAssociationCountComponent {

  public displayedColumns: string[] = ['articolo', 'quantita'];

  constructor(@Inject(MAT_DIALOG_DATA) public data: PayLoad) {
  }

}

export interface PayLoad {
  terminale: number;
  articoli: Articolo[];
}

interface Articolo {
  quantita: number;
  articolo: number;
}
