import { Component } from '@angular/core';
import { DataLoaderProviderService } from '../../../services/data-loader-provider.service';
import { LoaderStatus } from '../../../../models/data-loader';
import { CaricoTerminale } from '../../../../models/raw/carico_terminale';
import { Ascending } from '../../../../models/query-lang/order';
import { Equal, Like } from '../../../../models/query-lang/comparison';
import { And, Or } from '../../../../models/query-lang/logical';
import { SimpleAlertDialogComponent } from '../../dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { LoadingOverlayService } from '../../../services/loading-overlay.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { SearchDialogComponent } from '../../dialogs/search-dialog/search-dialog.component';
import { LetturaStato } from '../../../../models/raw/lettura';
import { CarichiDialogComponent } from '../../dialogs/carichi-dialog/carichi-dialog.component';
import { BaseTableComponent } from '../base-table/base-table.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SortDirection } from '@angular/material/sort';
import { EnteService } from '../../../services/ente.service';
import { Ente } from '../../../../models/raw/ente';
import { CaricoExportDialogComponent } from '../../dialogs/carico-export-dialog/carico-export-dialog.component';

@Component({
  selector: 'app-carichi',
  templateUrl: './carichi.component.html',
  styleUrls: ['../base-table/base-table.component.scss', './carichi.component.scss']
})
export class CarichiComponent extends BaseTableComponent<CaricoTerminale> {

  constructor(private dataLoaderProvider: DataLoaderProviderService,
    private http: HttpClient,
    private dialog: MatDialog,
    private enteService: EnteService,
    private loadingOverlay: LoadingOverlayService,
    private snackBar: MatSnackBar) {
    super(
      [
        { name: 'caricoterminali_id', isFilterable: false },
        { name: 'caricoterminali_descrizione', isFilterable: true },
        { name: 'caricoterminali_terminale', isFilterable: true },
        { name: 'caricoterminali_lavorazione_id', isFilterable: true },
        { name: 'caricoterminali_data_ora_appuntamento', isFilterable: false },
        { name: 'caricoterminali_data_in', isFilterable: false },
        { name: 'caricoterminali_data_out', isFilterable: false },
        { name: 'caricoterminali_fascia_oraria', isFilterable: false },
      ],
      { active: 'caricoterminali_id', direction: 'desc' as SortDirection }
    );
    this.loader = this.dataLoaderProvider.getCarichiLoader();
    this.displayedColumns.push('actions');

    this.onEnteChanged(this.enteService.ente.value);
    this.enteService.ente.subscribe(ente => {
      this.onEnteChanged(ente);
    });
  }

  private onEnteChanged(ente: Ente) {
    this.baseQuerySubject.next(new And([new Equal('lavorazione.ente', ente.ente_id)]));
  }

  public onChangeLettureStatusButtonClicked(carico: CaricoTerminale, newStatus: string): void {
    this.loadingOverlay.show();
    this.http.patch(`${environment.baseUrl}api/client/carichi_terminali/${carico.caricoterminali_id}/to_status/${newStatus}`, null)
      .toPromise().then(response => {
        if (response instanceof Array && response.length > 0) {
          this.snackBar.open(
            `${response.length} attività portate in stato ${newStatus}`,
            null,
            { duration: 5000 }
          );
        } else {
          this.snackBar.open(
            `Questo carico non presenta alcuna attività che è possibile portate in stato ${newStatus}`,
            null,
            { duration: 5000 }
          );
        }
      })
      .catch((error: HttpErrorResponse) => {
        this.snackBar.open('Impossibile eseguire questa operazione al momento. Riprova più tardi.', null, { duration: 5000 });
      })
      .finally(() => this.loadingOverlay.hide());
  }

  public onChangeLetturistaButtonClicked(carico: CaricoTerminale): void {
    this.dialog.open(SearchDialogComponent, {
      data: {
        dataLoaderBuilder: search => this.dataLoaderProvider.getLetturistiLoader().newQuery({
          fields: ['letturista_numero', 'letturista_nome'],
          filters: new Or([new Like('letturista_nome', `%${search}%`), new Equal('letturista_numero', search)]),
          orders: [new Ascending('letturista_nome')]
        }),
        columns: [
          { databaseName: 'letturista_numero', label: 'Terminale' },
          { databaseName: 'letturista_nome', label: 'Nome' }
        ],
      }
    }).afterClosed().toPromise().then(letturista => {
      if (!!letturista) {
        this.loadingOverlay.show();
        const lettureLoader = this.dataLoaderProvider.getLettureLoader(500).newQuery({
          fields: ['id', 'progressivo', 'codice_ente'],
          filters: new And([
            new Equal('carico', carico.caricoterminali_id),
            new Or([
              new Equal('stato', LetturaStato.RIS),
              new Equal('stato', LetturaStato.ASS),
              new Equal('stato', LetturaStato.CAR)
            ]),
          ])
        });
        lettureLoader.status.subscribe(status => {
          switch (status) {
            case LoaderStatus.IDLE:
              lettureLoader.loadNext();
              break;

            case LoaderStatus.DONE:
              const letture = lettureLoader.items.value;
              if (letture.length === 0) {
                this.loadingOverlay.hide();
                this.snackBar.open(
                  'Questo carico non presenta alcuna attività che è possibile spostare',
                  null,
                  { duration: 5000 }
                );
                return;
              }
              const enteLoader = this.dataLoaderProvider.getEntiLoader().newQuery({
                filters: new And([new Equal('ente_id', letture[0].codice_ente)])
              });
              enteLoader.loadNext().then(_ => {
                this.loadingOverlay.hide();
                if (enteLoader.items.value.length === 0) {
                  return;
                }
                this.dialog.open(CarichiDialogComponent, {
                  data: {
                    ente: enteLoader.items.value[0],
                    letture: letture,
                    letturista: letturista
                  }
                });
              });
              break;
          }
        });
      }
    });
  }

  public onExportButtonClicked(carico: CaricoTerminale): void {
    this.dialog.open(CaricoExportDialogComponent, { data: carico });
  }

  public onDeleteButtonClicked(carico: CaricoTerminale): void {
    this.dialog.open(SimpleAlertDialogComponent, {
      data: {
        title: 'Attenzione!',
        content: `Sei sicuro di voler eliminare il carico ${carico.caricoterminali_descrizione}?`,
      }
    }).afterClosed().toPromise().then(value => {
      if (value === true) {
        this.loadingOverlay.show();
        this.http.delete(`${environment.baseUrl}api/client/carichi_terminali/${carico.caricoterminali_id}`).toPromise()
          .then(response => {
            if (response instanceof Array && response.length > 0) {
              this.snackBar.open('Carico eliminato con successo!', null, { duration: 5000 });
              const items = this.loader.items.value;
              items.splice(items.indexOf(carico), 1);
              this.loader.items.next(items);
            } else {
              this.snackBar.open('Impossibile eliminare questo carico. Riprova più tardi.', null, { duration: 5000 });
            }
          })
          .catch((error: HttpErrorResponse) => {
            if (error.status === 409) {
              this.snackBar.open(error.error, null, { duration: 5000 });
            } else {
              this.snackBar.open('Impossibile eliminare questo carico. Riprova più tardi.', null, { duration: 5000 });
            }
          })
          .finally(() => this.loadingOverlay.hide());
      }
    });
  }
}
