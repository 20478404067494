import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-articles-of-lettura-dialog',
  templateUrl: './articles-of-lettura-dialog.component.html',
  styleUrls: ['./articles-of-lettura-dialog.component.scss']
})
export class ArticlesOfLetturaDialogComponent {

  public displayedColumns: string[] = ['articolo', 'quantita'];

  constructor(@Inject(MAT_DIALOG_DATA) public data: PayLoad) {
  }

}

export interface PayLoad {
  articoli: Articoli[];
}

export interface Articoli {
  act_description: string;
  act_value: number;
}
