import {Component} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {GeoService} from '../../../services/geo.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
  settingsForm = new FormGroup({
    geoAddress: new FormControl(this.geoService.address),
    geoPort: new FormControl(this.geoService.port),
  });

  constructor(private geoService: GeoService, private snackBar: MatSnackBar) {}

  public save(): void {
    this.geoService.address = this.settingsForm.value.geoAddress;
    this.geoService.port = this.settingsForm.value.geoPort;
    this.snackBar.open('Impostazioni aggiornate con successo!', null, { duration: 2000 });
    this.settingsForm.reset({
      geoAddress: this.settingsForm.value.geoAddress,
      geoPort: this.settingsForm.value.geoPort
    });
  }

}
