<div class="h100 container">
  <mat-toolbar class="sub-toolbar mat-elevation-z4">
    <app-search-input (search)="onSearchChanged($event)"></app-search-input>
  </mat-toolbar>
  <mat-progress-bar *ngIf="(loader.loading | async)" mode="indeterminate" color="accent"></mat-progress-bar>

  <div infiniteScroll
       [scrollWindow]="false"
       (scrolled)="onTableScrolled()"
       [infiniteScrollDistance]="1"
       class="table-container">
    <mat-table matSort
               matSortActive="caricoterminali_id"
               matSortDirection="desc"
               (matSortChange)="onSort($event)"
               [dataSource]="loader"
               class="mat-elevation-z8">
      <ng-container matColumnDef="caricoterminali_id">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Id</mat-header-cell>
        <mat-cell *matCellDef="let carico">{{carico.caricoterminali_id}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="caricoterminali_descrizione">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Descrizione</mat-header-cell>
        <mat-cell *matCellDef="let carico">{{carico.caricoterminali_descrizione}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="caricoterminali_terminale">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Terminale</mat-header-cell>
        <mat-cell *matCellDef="let carico">{{carico.caricoterminali_terminale}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="caricoterminali_lavorazione_id">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Lavorazione</mat-header-cell>
        <mat-cell *matCellDef="let carico">{{carico.caricoterminali_lavorazione_id}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="caricoterminali_data_ora_appuntamento">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Data ora appuntamento</mat-header-cell>
        <mat-cell *matCellDef="let carico">{{carico.caricoterminali_data_ora_appuntamento | momentDate}} {{carico.caricoterminali_data_ora_appuntamento ? (carico.caricoterminali_data_ora_appuntamento | momentTime) : ''}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="caricoterminali_data_in">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Dal</mat-header-cell>
        <mat-cell *matCellDef="let carico">{{carico.caricoterminali_data_in | momentDate}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="caricoterminali_data_out">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Al</mat-header-cell>
        <mat-cell *matCellDef="let carico">{{carico.caricoterminali_data_out | momentDate}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="caricoterminali_fascia_oraria">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Fascia oraria</mat-header-cell>
        <mat-cell *matCellDef="let carico">{{carico.caricoterminali_fascia_oraria | timeSlot}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let carico">
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="onChangeLettureStatusButtonClicked(carico, 'ASS')">Porta attività in stato ASS</button>
            <button mat-menu-item (click)="onChangeLettureStatusButtonClicked(carico, 'RIS')">Porta attività in stato RIS</button>
            <button mat-menu-item (click)="onChangeLetturistaButtonClicked(carico)">Cambia operatore</button>
            <button mat-menu-item (click)="onExportButtonClicked(carico)">Esporta</button>
            <button mat-menu-item (click)="onDeleteButtonClicked(carico)">Elimina carico</button>
          </mat-menu>

          <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
      <mat-row *matRowDef="let carico; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>
</div>
