import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import {isNumeric} from 'rxjs/internal-compatibility';

@Pipe({
  name: 'timeSlider'
})
export class TimeSliderPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (!isNumeric(value)) {
      return '00:00';
    }

    return moment().startOf('day').add(15 * (value as number), 'minutes').format('HH:mm');
  }
}
