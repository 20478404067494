import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'timeSlot'
})
export class TimeSlotPipe implements PipeTransform {

  private readonly regex = new RegExp('^\\d{4}-\\d{4}$');

  transform(value: any, args?: any): any {
    if (this.regex.exec(value as string)) {
      return `${value.substring(0, 2)}:${value.substring(2, 4)} - ${value.substring(5, 7)}:${value.substring(7, 9)}`;
    }

    return value;
  }

}
