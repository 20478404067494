<mat-toolbar class="sub-toolbar mat-elevation-z4" fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="row" fxLayoutAlign="center center">
    <a mat-icon-button href="#" routerLink="/letture">
      <mat-icon>arrow_back</mat-icon>
    </a>
    <h4>{{lettura?.utente}}</h4>
    <button mat-icon-button (click)="fotoSidenav.toggle()" matTooltip="Apri/Chiudi pannello Foto">
      <mat-icon>photo</mat-icon>
    </button>
    <button mat-icon-button (click)="mappaSidenav.toggle()" matTooltip="Apri/Chiudi pannello Mappa">
      <mat-icon>map</mat-icon>
    </button>
  </div>

  <mat-button-toggle-group (change)="onFlagChanged($event)" [value]="lettura?.flag">
    <mat-button-toggle value="L">
      <div class="toggle-container">L</div>
    </mat-button-toggle>
    <mat-button-toggle value="X">
      <div class="toggle-container">X</div>
    </mat-button-toggle>
    <mat-button-toggle value="S">
      <div class="toggle-container">S</div>
    </mat-button-toggle>
    <mat-button-toggle value="0">
      <div class="toggle-container">0</div>
    </mat-button-toggle>
    <mat-button-toggle value="1">
      <div class="toggle-container">1</div>
    </mat-button-toggle>
    <mat-button-toggle value="2">
      <div class="toggle-container">2</div>
    </mat-button-toggle>
    <mat-button-toggle value="3">
      <div class="toggle-container">3</div>
    </mat-button-toggle>
    <mat-button-toggle value="4">
      <div class="toggle-container">4</div>
    </mat-button-toggle>
    <mat-button-toggle value="5">
      <div class="toggle-container">5</div>
    </mat-button-toggle>
    <mat-button-toggle value="6">
      <div class="toggle-container">6</div>
    </mat-button-toggle>
  </mat-button-toggle-group>

  <div>
    <button mat-icon-button (click)="onArticlesOfLetturaButtonClicked()" matTooltip="Visualizza Consumabili Utilizzati">
      <mat-icon>assignment</mat-icon>
    </button>
    <button mat-icon-button [disabled]="detailsFormGroup?.pristine" (click)="resetForm()"
      matTooltip="Annulla modifiche">
      <mat-icon>undo</mat-icon>
    </button>
    <button mat-icon-button [disabled]="lettura?.stato !== 'LET'" (click)="onResetButtonClicked()"
      matTooltip="Reimposta attività">
      <mat-icon>restore</mat-icon>
    </button>
    <button mat-icon-button [disabled]="lettura?.stato !== 'LET'" (click)="onSaveButtonClicked()"
      matTooltip="Salva attività">
      <mat-icon>save</mat-icon>
    </button>
    <mat-progress-spinner *ngIf="lettureService.loader.loading | async" id="loader-spinner" diameter="24"
      mode="indeterminate"></mat-progress-spinner>
    <a mat-icon-button *ngIf="!(lettureService.loader.loading | async) && previousLettura === null" href="#" disabled
      matTooltip="Attività precedente (CTRL + &larr;)">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </a>
    <a mat-icon-button *ngIf="!(lettureService.loader.loading | async) && previousLettura !== null" href="#"
      routerLink="/letture/{{previousLettura?.id}}" matTooltip="Attività precedente (CTRL + &larr;)">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </a>
    <a mat-icon-button *ngIf="!(lettureService.loader.loading | async) && nextLettura === null" href="#" disabled
      matTooltip="Attività successiva (CTRL + &rarr;)">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </a>
    <a mat-icon-button *ngIf="!(lettureService.loader.loading | async) && nextLettura !== null" href="#"
      routerLink="/letture/{{nextLettura?.id}}" matTooltip="Attività successiva (CTRL + &rarr;)">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </a>
  </div>
</mat-toolbar>

<mat-sidenav-container class="flex1">
  <mat-sidenav #fotoSidenav mode="side">
    <!-- Foto -->
    <div class="h100 mat-elevation-z2" style="max-height: 400px;" fxLayout="column">
      <h4 class="title">Foto</h4>

      <div *ngIf="photos?.length === 0" class="content photos-container" fxLayoutAlign="center center">
        <p>Nessuna foto disponibile per questa attività.</p>
      </div>

      <div *ngIf="photos?.length > 0" class="content photos-container" fxLayout="row wrap">
        <app-photo *ngFor="let photo of photos" [url]="photo"></app-photo>
      </div>

      <div class="title">
        <p>Lettura: <strong>{{lettura?.lettura ? lettura.lettura : '/'}}</strong></p>
        <p>Matricola: <strong>{{lettura?.matricola}}</strong></p>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav #mappaSidenav mode="side" position="end">
    <!-- Map -->
    <p *ngIf="latitude === null && longitude === null">Coordinate GPS non disponibili per questa attività.</p>
    <google-map *ngIf="latitude !== null && longitude !== null" #googleMap [center]="{lat: latitude, lng: longitude}"
      [zoom]="14" [options]="{ styles: mapService.mapStyles }" class="h100 mat-elevation-z2" style="width:500px;">

      <map-marker [position]="{lat: latitude, lng: longitude}"
        [options]="{ icon: 'assets/markers/letture/unselected.png' }">
      </map-marker>
    </google-map>
    <!-- <agm-map *ngIf="latitude !== null && longitude !== null"
              [latitude]="latitude"
              [longitude]="longitude"
              [zoom]="14"
              [styles]="mapService.mapStyles"
              class="h100 mat-elevation-z2"
              style="width:500px;">
      <agm-marker iconUrl="assets/markers/letture/unselected.png"
                  [latitude]="latitude"
                  [longitude]="longitude"></agm-marker>
    </agm-map> -->
  </mat-sidenav>

  <mat-sidenav-content>
    <div fxLayout="column">
      <!-- Details -->
      <div id="section-details" style="resize: vertical; overflow: auto; min-height: 100px; height: 250px;">
        <div class="h100 mat-elevation-z2" fxLayout="column">
          <h4 class="title">Dettagli</h4>
          <form *ngIf="detailsFormGroup" class="h100 content details-container" [formGroup]="detailsFormGroup"
            fxLayout="column wrap">
            <div *ngIf="!detailsConfiguration" class="h100" fxLayoutAlign="center center">
              <p>Impossibile trovare la configurazione di questa attività.</p>
            </div>

            <div *ngFor="let configuration of detailsConfiguration" class="field-container" fxLayout="row">
              <div class="field-label-container" fxLayout="column" fxLayoutAlign="center">
                <span class="mat-subtitle-2">{{configuration.label}}</span>
              </div>
              <input class="flex1" type="text" formControlName="{{configuration.field}}">
            </div>
          </form>
        </div>
      </div>

      <!-- Previous -->
      <div id="section-previous">
        <div class="h100 mat-elevation-z2" fxLayout="column">
          <h4 class="title">Storico</h4>
          <mat-progress-bar *ngIf="isLoadingPreviousLetture" mode="indeterminate" color="accent"></mat-progress-bar>
          <mat-table class="h100" [dataSource]="previousLetture">
            <ng-container matColumnDef="progressivo">
              <mat-header-cell *matHeaderCellDef>Progressivo</mat-header-cell>
              <mat-cell *matCellDef="let lettura">{{lettura.progressivo}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="data">
              <mat-header-cell *matHeaderCellDef>Data</mat-header-cell>
              <mat-cell *matCellDef="let lettura">{{lettura.data_lettura | momentDate}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="cifre">
              <mat-header-cell *matHeaderCellDef>Cifre</mat-header-cell>
              <mat-cell *matCellDef="let lettura">{{lettura.cifre}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="lettura">
              <mat-header-cell *matHeaderCellDef>Lettura</mat-header-cell>
              <mat-cell *matCellDef="let lettura">{{lettura.lettura}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="consumo">
              <mat-header-cell *matHeaderCellDef>Consumo</mat-header-cell>
              <mat-cell *matCellDef="let lettura">{{lettura.consumo}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="flag">
              <mat-header-cell *matHeaderCellDef>Flag</mat-header-cell>
              <mat-cell *matCellDef="let lettura">{{lettura.flag}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="segn1">
              <mat-header-cell *matHeaderCellDef>Segnalazione 1</mat-header-cell>
              <mat-cell *matCellDef="let lettura">{{lettura.segn1}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="segn2">
              <mat-header-cell *matHeaderCellDef>Segnalazione 2</mat-header-cell>
              <mat-cell *matCellDef="let lettura">{{lettura.segn2}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="terminale">
              <mat-header-cell *matHeaderCellDef>Terminale</mat-header-cell>
              <mat-cell *matCellDef="let lettura">{{lettura.terminale}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="pdr">
              <mat-header-cell *matHeaderCellDef>PDR</mat-header-cell>
              <mat-cell *matCellDef="let lettura">{{lettura.pdr}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="codice_utente">
              <mat-header-cell *matHeaderCellDef>Codice utente</mat-header-cell>
              <mat-cell *matCellDef="let lettura">{{lettura.codice_utente}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="matricola">
              <mat-header-cell *matHeaderCellDef>Matricola</mat-header-cell>
              <mat-cell *matCellDef="let lettura">{{lettura.matricola}}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="previousLettureColumns; sticky: true;"></mat-header-row>
            <mat-row *matRowDef="let lettura; columns: previousLettureColumns;"></mat-row>
          </mat-table>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>