<div class="h100 container">
  <mat-toolbar class="sub-toolbar mat-elevation-z4">
    <app-search-input (search)="onSearchChanged($event)"></app-search-input>

    <button mat-icon-button id="upload-button" (click)="onUploadButtonClicked()" matTooltip="Nuova importazione">
      <mat-icon>cloud_upload</mat-icon>
    </button>
  </mat-toolbar>
  <mat-progress-bar *ngIf="(loader.loading | async)" mode="indeterminate" color="accent"></mat-progress-bar>

  <div infiniteScroll [scrollWindow]="false" (scrolled)="onTableScrolled()" [infiniteScrollDistance]="1"
    class="table-container">
    <mat-table matSort matSortActive="indice_file" matSortDirection="desc" (matSortChange)="onSort($event)"
      [dataSource]="loader" class="mat-elevation-z8">
      <ng-container matColumnDef="error">
        <mat-header-cell style="display: none;" mat-sort-header *matHeaderCellDef>Data inizio</mat-header-cell>
        <mat-cell style="display: none;" *matCellDef="let file">{{getDate(file.started_at)}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="warning">
        <mat-header-cell style="display: none;" mat-sort-header *matHeaderCellDef>Data inizio</mat-header-cell>
        <mat-cell style="display: none;" *matCellDef="let file">{{getDate(file.started_at)}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="started_at">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Data inizio</mat-header-cell>
        <mat-cell *matCellDef="let file">{{getDate(file.started_at)}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="finished_at">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Date fine</mat-header-cell>
        <mat-cell *matCellDef="let file">{{getDate(file.finished_at)}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="nome_file">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Nome file</mat-header-cell>
        <mat-cell *matCellDef="let file">{{file.nome_file}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="tipologia_file">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Tipologia file</mat-header-cell>
        <mat-cell *matCellDef="let file">{{file.tipologia_file}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="stato_importazione">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Stato importazione</mat-header-cell>
        <mat-cell (click)="showMore(file)" *matCellDef="let file">
          <div [style.width]="file.stato_importazione === 'loading' ? '0px' : '30px'" [style.background]="file.stato_importazione === 'done' ? 'green' : file.stato_importazione === 'failed' ? 'red' : 
            file.stato_importazione === 'warning' ? 'yellow' : ''"
            style="width: 30px; height: 30px; border-radius: 15px;"></div>

          <mat-progress-spinner *ngIf="file.stato_importazione === 'loading'" style="width: 30px; height: 30px;"
            color="primary" mode="indeterminate">
          </mat-progress-spinner>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
      <mat-row *matRowDef="let file; columns: displayedColumns;" class="selectable-row"></mat-row>
    </mat-table>
  </div>
</div>