import {Injectable} from '@angular/core';
import {DataLoaderProviderService} from './data-loader-provider.service';
import {Ente} from '../../models/raw/ente';
import {LocalStorageService} from 'ngx-webstorage';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnteService {
  public ente: BehaviorSubject<Ente>;

  constructor(private dataLoaderProvider: DataLoaderProviderService, private localStorage: LocalStorageService) { }

  public async load(): Promise<void> {
    let ente = this.localStorage.retrieve('ente');
    if (!ente) {
      const dataLoader = this.dataLoaderProvider.getEntiLoader();
      await dataLoader.loadNext();
      if (dataLoader.items.value.length === 0) {
        throw new Error('No ente available.');
      }
      ente = dataLoader.items.value[0];
    }

    this.ente = new BehaviorSubject(ente);
    this.ente.subscribe(value => {
      this.localStorage.store('ente', value);
    });
  }
}
