import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Moment} from 'moment';

@Component({
    selector: 'app-daterange-selection-dialog',
    templateUrl: './daterange-selection-dialog.component.html',
    styleUrls: ['./daterange-selection-dialog.component.scss'],
})
export class DaterangeSelectionDialogComponent {
    public range: Range;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DaterangeSelectionDialogComponent>) { }

    public onRangeChanged(event: any): void {
      this.range = {
        start: event.startDate,
        end: event.endDate
      };
    }

    onSubmit() {
      this.dialogRef.close(this.range);
    }
}

export interface Range {
  start: Moment;
  end: Moment;
}
