import {Injectable} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {PrerequisitesService} from '../services/prerequisites.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private oauthService: OAuthService, private prerequisitesService: PrerequisitesService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.oauthService.hasValidAccessToken()) {
      this.router.navigate(['/login'], {queryParams: {redirectTo: state.url}});
      return false;
    }

    if (!this.prerequisitesService.isLoaded) {
      this.router.navigate(['/splash'], {queryParams: {redirectTo: state.url}});
      return false;
    }

    return true;
  }
}
