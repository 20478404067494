<form [formGroup]="settingsForm" (ngSubmit)="save()">
  <div class="container">
    <h2 class="mat-subtitle-1">Geocoder</h2>
    <mat-card appearance="outlined" class="mat-elevation-z5">
      <mat-card-content>
        <mat-form-field class="w100">
          <input matInput placeholder="Indirizzo" formControlName="geoAddress">
        </mat-form-field>
        <mat-form-field class="w100">
          <input matInput placeholder="Porta" formControlName="geoPort" type="number" maxlength="5">
        </mat-form-field>
      </mat-card-content>
    </mat-card>
  </div>

  <button mat-fab type="submit" color="accent" [disabled]="!settingsForm.dirty">
    <mat-icon>save</mat-icon>
  </button>
</form>
