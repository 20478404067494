<!--<h1 mat-dialog-title>Seleziona un elemento</h1>-->
<mat-form-field mat-dialog-title appearance="outline">
  <input #search matInput type="text" placeholder="Scrivi per cercare…" [formControl]="searchFormControl" (ngModelChange)="onSearchChanged($event)">
  <button *ngIf="!(dataLoader.loading | async)" mat-icon-button matSuffix (click)="searchFormControl.setValue('')">
    <mat-icon>{{search.value.length > 0 ? 'clear' : 'search'}}</mat-icon>
  </button>
  <mat-progress-spinner *ngIf="dataLoader.loading | async" matSuffix mode="indeterminate" diameter="24" color="accent"></mat-progress-spinner>
</mat-form-field>

<mat-dialog-content>
  <div infiniteScroll
       [scrollWindow]="false"
       (scrolled)="onTableScrolled()"
       [infiniteScrollDistance]="1">
    <mat-table [dataSource]="dataLoader">
      <ng-container *ngFor="let column of data.columns" matColumnDef="{{column.databaseName}}">
        <mat-header-cell *matHeaderCellDef>{{column.label}}</mat-header-cell>
        <mat-cell *matCellDef="let item">{{item[column.databaseName]}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true;"></mat-header-row>
      <mat-row mat-ripple *matRowDef="let item; columns: columnsToDisplay;" (click)="onItemSelected(item)"></mat-row>
    </mat-table>
  </div>
</mat-dialog-content>
