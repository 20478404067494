import {Component, OnInit} from '@angular/core';
import {BaseTableComponent} from '../base-table/base-table.component';
import {StatoMagazzino} from '../../../../models/raw/stato-magazzino';
import {SortDirection} from '@angular/material/sort';
import {DataLoaderProviderService} from '../../../services/data-loader-provider.service';
import {FormFieldType, SimpleFormDialogComponent} from '../../dialogs/simple-form-dialog/simple-form-dialog.component';
import {MagazzinoConsumabile} from '../../../../models/raw/magazzino-consumabile';
import {SimpleAlertDialogComponent} from '../../dialogs/simple-alert-dialog/simple-alert-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {LoadingOverlayService} from '../../../services/loading-overlay.service';
import {HttpClient} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {EnteService} from '../../../services/ente.service';
import {And, Logical} from '../../../../models/query-lang/logical';
import {Equal} from '../../../../models/query-lang/comparison';
import {Ente} from '../../../../models/raw/ente';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-stati-magazzino',
  templateUrl: './stati-magazzino.component.html',
  styleUrls: ['./stati-magazzino.component.scss', '../base-table/base-table.component.scss']
})
export class StatiMagazzinoComponent extends BaseTableComponent<StatoMagazzino> implements OnInit {

  constructor(private dataLoaderProvider: DataLoaderProviderService,
              private dialog: MatDialog, private enteService: EnteService,
              private loadingOverlay: LoadingOverlayService,
              private http: HttpClient, private snackBar: MatSnackBar) {
    super([
        {name: 'id', isFilterable: false},
        {name: 'stato', isFilterable: true},
      ],
      {active: 'id', direction: 'asc' as SortDirection});
    this.selectOnlyColumns = false;
    this.loader = this.dataLoaderProvider.getStatoMagazzinoLoader();
    this.displayedColumns.push('actions');

    this.onEnteChanged(this.enteService.ente.value);
    this.enteService.ente.subscribe(ente => {
      this.onEnteChanged(ente);
    });
  }

  public async onAddButtonClicked(): Promise<void> {
    const result = await this.dialog.open(SimpleFormDialogComponent, {
      data: {
        fields: [
          {
            column: 'stato',
            label: 'Stato',
            type: FormFieldType.STRING,
            required: true
          }
        ]
      }
    }).afterClosed().toPromise();

    if (result) {
      this.loadingOverlay.show();

      try {
        await this.http.post(`${environment.baseUrl}api/client/stati_magazzino`, {
          stato: result.stato,
          ente_id: this.enteService.ente.value.ente_id,
        }).toPromise();
        this.snackBar.open('Stato aggiunto con successo!', null, {duration: 5000});

      } catch (e) {
        this.snackBar.open('Impossibili completare l\' operazione dello stato inserito. Riprova più tardi.', null, {duration: 5000});
      }
      this.loadingOverlay.hide();

      this.loader = this.loader.newQuery(this.loader.currentQuery);
      this.loader.loadNext();
    }
  }

  public async onEditButtonButtonClicked(stato: StatoMagazzino): Promise<void> {
    const result = await this.dialog.open(SimpleFormDialogComponent, {
      data: {
        fields: [
          {
            column: 'stato',
            label: 'Stato',
            type: FormFieldType.STRING,
            required: true,
            defaultValue: stato.stato
          }
        ]
      }
    }).afterClosed().toPromise();

    if (result) {
      this.loadingOverlay.show();

      try {
        await this.http.patch(`${environment.baseUrl}api/client/stati_magazzino/${stato.id}`, {
          stato: result.stato,
          ente_id: this.enteService.ente.value.ente_id,
        }).toPromise();
        this.snackBar.open('Stato modificato con successo!', null, {duration: 5000});

      } catch (e) {
        this.snackBar.open('Impossibili completare l\' operazione dello stato selezionato. Riprova più tardi.', null, {duration: 5000});
      }
      this.loadingOverlay.hide();

      this.loader = this.loader.newQuery(this.loader.currentQuery);
      this.loader.loadNext();
    }
  }

  public async onDeleteButtonClicked(consumabile: MagazzinoConsumabile): Promise<void> {
    const result = await this.dialog.open(SimpleAlertDialogComponent, {
      data: {
        title: 'Attenzione!',
        content: 'Sei sicuro di voler eliminare lo stato?'
      }

    }).afterClosed().toPromise();

    if (result) {
      this.loadingOverlay.show();

      try {
        await this.http.delete(`${environment.baseUrl}api/client/stati_magazzino/${consumabile.id}`).toPromise();
        this.snackBar.open('Stato cancellato con successo!', null, {duration: 5000});

      } catch (e) {
        this.snackBar.open('Impossibili completare l\' operazione dello stato selezionato. Riprova più tardi.', null, {duration: 5000});
      }
      this.loadingOverlay.hide();

      this.loader = this.loader.newQuery(this.loader.currentQuery);
      this.loader.loadNext();
    }

  }

  public onFiltersUpdated(filters: Logical): void {
    this.baseQuerySubject.next(new And([...filters.args, new Equal('ente_id', this.enteService.ente.value.ente_id)]));
    this.loader.loadNext();
  }

  private onEnteChanged(ente: Ente): void {
    this.baseQuerySubject.next(new And([new Equal('ente_id', ente.ente_id)]));
  }

}
