import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ChartReportService {


    constructor(
        private _http: HttpClient) {
    }

    getChart(value: any): Promise<any> {
        return this._http.post(environment.baseUrl + 'api/client/getLetture/byFilterChart', value).pipe(take(1)).toPromise();
    }

    getMatricole(): Promise<{ matricola: string, deveui_codice_identificativo_radio_lorawan: string }[]> {
        return this._http.get<{ matricola: string, deveui_codice_identificativo_radio_lorawan: string }[]>(environment.baseUrl + 'api/client/lorawan/getDevEuis').pipe(take(1)).toPromise();
    }

    getTable(value: any): Promise<any> {
        return this._http.post(environment.baseUrl + 'api/client/getLetture/byFilterTable', value).pipe(take(1)).toPromise();
    }
}
