<mat-toolbar class="sub-toolbar mat-elevation-z4" fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="row" fxLayoutAlign="center center">
    <a mat-icon-button href="#" routerLink="/swm">
      <mat-icon>arrow_back</mat-icon>
    </a>
    <h4>{{swm?.id}}</h4>
    <!-- <button mat-icon-button (click)="mappaSidenav.toggle()" matTooltip="Apri/Chiudi pannello Mappa">
      <mat-icon>map</mat-icon>
    </button> -->
  </div>
</mat-toolbar>

<mat-sidenav-container class="flex1">
  <mat-sidenav #mappaSidenav mode="side" position="end" opened="true">
    <!-- Map -->

  </mat-sidenav>

  <mat-sidenav-content>
    <div fxLayout="column">
      <!-- Details -->
      <div id="section-details" style="resize: vertical; overflow: auto; min-height: 100px;">
        <div class="h100 mat-elevation-z2" fxLayout="column">
          <h4 class="title">Dettagli</h4>
          <div style="display: grid; grid-template-columns: 1fr 1fr;">
            <div *ngFor="let val of titles" class="field-container" fxLayout="row">
              <div class="field-label-container" fxLayout="column" fxLayoutAlign="center">
                <span class="mat-subtitle-2">{{getTitle(val)}}</span>
              </div>
              <label>{{swm ? swm[val] : ''}}</label>
            </div>
          </div>
        </div>
      </div>

      <div style="height: 400px; margin: 20px;">
        <p *ngIf="latitude === null && longitude === null">Coordinate GPS non disponibili</p>

        <google-map *ngIf="latitude !== null && longitude !== null" #googleMap
          [center]="{lat: latitude, lng: longitude}" [zoom]="mapService.zoom"
          [options]="{ styles: mapService.mapStyles }" class="h100">

          <map-marker [position]="{lat: latitude, lng: longitude}"
            [options]="{ icon: 'assets/markers/letture/unselected.png' }">
          </map-marker>
        </google-map>
      </div>

      <div id="section-previous">
        <div class="h100 mat-elevation-z2" fxLayout="column">
          <h4 class="title">Allarmi</h4>
          <mat-progress-bar *ngIf="isLoadingPreviousAlarms" style="top: 1071px; width: 49.2%;" mode="indeterminate"
            color="accent"></mat-progress-bar>
          <mat-table class="h100" [dataSource]="alarms">
            <ng-container matColumnDef="trasmissionDate">
              <mat-header-cell *matHeaderCellDef>Data</mat-header-cell>
              <mat-cell *matCellDef="let lettura">{{getDate(lettura.trasmissionDate)}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="descrizione">
              <mat-header-cell *matHeaderCellDef>Descrizione</mat-header-cell>
              <mat-cell *matCellDef="let lettura">{{lettura.descrizione}}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="alarmsColumns; sticky: true;"></mat-header-row>
            <mat-row *matRowDef="let lettura; columns: alarmsColumns;"></mat-row>
          </mat-table>
        </div>

        <div class="h100 mat-elevation-z2" fxLayout="column">
          <h4 class="title">Letture</h4>
          <mat-progress-bar *ngIf="isLoadingPreviousValues" style="top: 1071px; width: 49.2%;" mode="indeterminate"
            color="accent"></mat-progress-bar>
          <mat-table class="h100" [dataSource]="values">
            <ng-container matColumnDef="modalita">
              <mat-header-cell *matHeaderCellDef>Modalità</mat-header-cell>
              <mat-cell *matCellDef="let lettura">{{lettura.modalita}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="trasmissionDate">
              <mat-header-cell *matHeaderCellDef>Data</mat-header-cell>
              <mat-cell *matCellDef="let lettura">{{getDate(lettura.trasmissionDate)}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="value">
              <mat-header-cell *matHeaderCellDef>Valore</mat-header-cell>
              <mat-cell *matCellDef="let lettura">{{lettura.value}}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="valuesColumns; sticky: true;"></mat-header-row>
            <mat-row *matRowDef="let lettura; columns: valuesColumns;"></mat-row>
          </mat-table>
        </div>
      </div>

      <!-- <div id="section-previous" style="display: block;">
        <div class="h100 mat-elevation-z2" fxLayout="column">
          <h4 class="title">Grafici</h4>
          <canvas #chartCanvas></canvas>
        </div>
      </div> -->
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>