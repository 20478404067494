<mat-toolbar class="sub-toolbar mat-elevation-z4">
  <app-search-input (search)="onSearchChanged($event)"></app-search-input>

  <button mat-icon-button id="upload-button" routerLink="/never-sent">
    <mat-icon>flip_to_back</mat-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container>

  <mat-sidenav #filtersSidenav mode="side" position="end">
  </mat-sidenav>

  <mat-sidenav-content *ngIf="loader">
    <div class="h100 container">
      <mat-progress-bar *ngIf="(loader.loading | async)" mode="indeterminate" color="accent"></mat-progress-bar>

      <div infiniteScroll [scrollWindow]="false" (scrolled)="onTableScrolled()" [infiniteScrollDistance]="1"
        class="table-container">
        <mat-table matSort matSortActive="id" matSortDirection="asc" (matSortChange)="onSort($event)"
          [dataSource]="loader" class="mat-elevation-z8">

          <ng-container matColumnDef="matricola">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Matricola</mat-header-cell>
            <mat-cell *matCellDef="let file">{{file.matricola}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="ultimaData">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Data</mat-header-cell>
            <mat-cell *matCellDef="let file">{{getDate(file.ultimaData)}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="ultimaLettura">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Ultima lettura</mat-header-cell>
            <mat-cell *matCellDef="let file">{{file.ultimaLettura}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="diff">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Differenza (gg)</mat-header-cell>
            <mat-cell *matCellDef="let file">{{getLast(file)}}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
          <mat-row mat-ripple *matRowDef="let lettura; columns: displayedColumns;" class="selectable-row"
            (click)="onSelected(lettura)"></mat-row>
        </mat-table>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>