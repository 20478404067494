import { Component, OnInit } from '@angular/core';
import { DataLoaderProviderService } from '../../../services/data-loader-provider.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { CaricoFile } from '../../../../models/raw/carico_file';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-caricofile-dialog-new',
  templateUrl: './caricofile-dialog-new.component.html',
  styleUrls: ['./caricofile-dialog-new.component.scss']
})
export class CaricofileDialogNewComponent implements OnInit {


  public name = '';
  private fileName = '';
  public selectedFiles: Map<string, File> = new Map();

  public isLoading = false;

  constructor(private http: HttpClient,
    private dataLoaderProvider: DataLoaderProviderService,
    private dialog: MatDialogRef<CaricofileDialogNewComponent>,
    private snackBar: MatSnackBar) { }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {
  }

  public onNameChanged(name: string): void {
    this.name = name;
  }

  public onFileAdded(file: File): void {
    this.fileName = file.name;
    this.selectedFiles.set(file.name, file);
  }

  public onConfirmButtonClicked(): void {
    this.isLoading = true;

    const formData = new FormData();
    formData.append('importType', this.name.trim());
    formData.append('file', this.selectedFiles.get(this.fileName));

    this.http.post<CaricoFile>(
      `${environment.baseUrl}api/client/lorawan/files/import`,
      formData
    ).toPromise().then((caricoFile: CaricoFile) => {
      this.dialog.close(caricoFile);
    }).catch(() => {
      this.snackBar.open(
        'Errore durante la creazione di questa importazione. Riprova più tardi.',
        null,
        { duration: 5000 }
      );
    }).finally(() => {
      this.isLoading = false;
    });
  }
}
