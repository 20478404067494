<h1 mat-dialog-title>Esporta carico {{carico.caricoterminali_descrizione}}</h1>
<form mat-dialog-content [formGroup]="formGroup" fxLayout="column" fxLayoutGap="16px">
  <mat-form-field appearance="outline">
    <mat-label>Tipologia tracciato</mat-label>
    <mat-select formControlName="export_type">
      <mat-option value="IN">IN</mat-option>
      <mat-option value="OUT">OUT</mat-option>
    </mat-select>
    <mat-error>Questo campo è obbligatorio.</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Stati attività</mat-label>
    <mat-select formControlName="status" multiple>
      <mat-option value="NUL">NUL</mat-option>
      <mat-option value="RIS">RIS</mat-option>
      <mat-option value="ASS">ASS</mat-option>
      <mat-option value="CAR">CAR</mat-option>
      <mat-option value="LET">LET</mat-option>
      <mat-option value="SPE">SPE</mat-option>
    </mat-select>
    <mat-error>Questo campo è obbligatorio.</mat-error>
  </mat-form-field>
</form>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
  <div>
    <button mat-button mat-dialog-close>Annulla</button>
    <button mat-raised-button color="accent" [disabled]="formGroup.invalid" (click)="onConfirmButtonClicked()">Conferma</button>
  </div>

  <mat-spinner *ngIf="isLoading | async" diameter="24"></mat-spinner>
</mat-dialog-actions>
