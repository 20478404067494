import {Component} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  isLoading = false;
  loginForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });

  constructor(private oauthService: OAuthService, private router: Router, private snackBar: MatSnackBar, private route: ActivatedRoute) { }

  login() {
    this.isLoading = true;
    this.oauthService.fetchTokenUsingPasswordFlow(this.loginForm.value.username, this.loginForm.value.password).then((result) => {
      this.isLoading = false;
      if (result) {
        this.router.navigate([this.route.snapshot.queryParamMap.get('redirectTo') ? this.route.snapshot.queryParamMap.get('redirectTo') : 'swm']);
      }
    }).catch((reason) => {
      this.isLoading = false;
      this.snackBar.open('Username o password errati.', null, { duration: 2000 });
    });
  }
}
