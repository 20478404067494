import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { BaseTableComponent } from '../base-table/base-table.component';
import { SortDirection } from '@angular/material/sort';
import { Alarm } from 'src/models/raw/alarm';
import { And, Logical } from 'src/models/query-lang/logical';
import { ColumnFilter, ColumnFilterType } from '../../partials/filters/filters.component';
import { AlarmsService } from 'src/app/services/alarms.service';
import { Router } from '@angular/router';
import { MapService } from 'src/app/services/map.service';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';

declare const google: any;

enum SelectionModes {
  IDLE, SELECTING, UNSELECTING
}

@Component({
  selector: 'app-alarms',
  templateUrl: './alarms.component.html',
  styleUrls: ['../base-table/base-table.component.scss', './alarms.component.scss']
})
export class AlarmsComponent extends BaseTableComponent<Alarm> implements OnInit {

  @ViewChildren(MapInfoWindow) infoWindows: QueryList<MapInfoWindow>;
  showMap = false;
  public filters: ColumnFilter[] = [
    { column: 'matricola', label: 'Matricola', type: ColumnFilterType.STRING },
    { column: 'zona', label: 'Zona', type: ColumnFilterType.NUMBER },
    { column: 'libro', label: 'Libro', type: ColumnFilterType.NUMBER },
    { column: 'pagina', label: 'Pagina', type: ColumnFilterType.NUMBER },
    { column: 'localita', label: 'Localita', type: ColumnFilterType.STRING },
    { column: 'marca_contatore', label: 'Marca', type: ColumnFilterType.STRING },
    { column: 'modello_contatore', label: 'Modello', type: ColumnFilterType.STRING },
    { column: 'data_ora_allarme', label: 'Data', type: ColumnFilterType.DATE },
    { column: 'descrizione_allarme', label: 'Descrizione', type: ColumnFilterType.ARRAY, values: [] },
  ];

  private selectionMode: SelectionModes = SelectionModes.IDLE;
  private map: google.maps.Map = null;

  constructor(
    public alarmsService: AlarmsService,
    private router: Router,
    public mapService: MapService) {
    super(
      [
        { name: 'matricola', isFilterable: true },
        { name: 'marca_contatore', isFilterable: true },
        { name: 'modello_contatore', isFilterable: true },
        { name: 'localita', isFilterable: true },
        { name: 'descrizione_allarme', isFilterable: true },
        { name: 'zona', isFilterable: true },
        { name: 'libro', isFilterable: true },
        { name: 'pagina', isFilterable: true },
        { name: 'data_ora_allarme', isFilterable: true },
      ],
      { active: 'id', direction: 'desc' as SortDirection }
    );
    this.selectOnlyColumns = false;
    this.loader = this.alarmsService.loader;
  }

  ngOnInit() {
    super.ngOnInit();

    this.alarmsService.getDescList()
      .then(descs => descs.forEach(desc => this.filters[8].values.push({ label: desc, value: desc })));
  }

  public onFiltersUpdated(filters: Logical): void {
    this.baseQuerySubject.next(new And([...filters.args]));
    this.loader.loadNext();
  }

  public onSelected(lettura: Alarm): void {
    console.log(lettura);
    this.alarmsService.loader = this.loader;
    this.router.navigate(['swm', lettura.id]);
  }

  export(type: string) {
    this.alarmsService.export(type);
  }

  showAlert(data: any) {
    alert(JSON.stringify(data));
  }

  getDate(col: any) {
    return new Date(col).toLocaleDateString();
  }

  openInfo(marker: MapMarker, windowIndex: number) {

    const window = this.infoWindows.toArray()[windowIndex];
    window.open(marker);
  }

  public onMapReady(map: google.maps.Map): void {
    this.map = map;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const me = this;

    let bounds;
    let boundingBox = null;
    let mouseIsDown = false;

    google.maps.event.addListener(map, 'mousemove', function (e) {
      if (mouseIsDown && me.selectionMode !== SelectionModes.IDLE) {
        if (boundingBox !== null) {
          bounds.extend(e.latLng);
          boundingBox.setBounds(bounds);
        } else {
          bounds = new google.maps.LatLngBounds();
          bounds.extend(e.latLng);
          boundingBox = new google.maps.Rectangle({
            map: map,
            bounds: bounds,
            fillColor: me.selectionMode === SelectionModes.SELECTING ? 'green' : 'red',
            fillOpacity: 0.15,
            strokeColor: me.selectionMode === SelectionModes.SELECTING ? 'green' : 'red',
            strokeWeight: 0.9,
            clickable: false
          });
        }
      }
    });

    google.maps.event.addListener(map, 'mousedown', function (e) {
      if (me.selectionMode !== SelectionModes.IDLE) {
        mouseIsDown = true;
        me.map.setOptions({
          gestureHandling: 'none'
        });
      }
    });

    google.maps.event.addListener(map, 'mouseup', function (e) {
      if (mouseIsDown) {
        mouseIsDown = false;
        if (boundingBox !== null) {
          boundingBox.setMap(null);
          switch (me.selectionMode) {
            case SelectionModes.SELECTING:
              me.mapService.selectCoordinates(me.mapService.coordinates.filter(c => bounds.contains(c)));
              break;

            case SelectionModes.UNSELECTING:
              me.mapService.unselectCoordinates(me.mapService.coordinates.filter(c => bounds.contains(c)));
              break;
          }
        }
        boundingBox = null;
      }

      me.map.setOptions({
        gestureHandling: 'auto'
      });
    });
  }

}
