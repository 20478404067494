<mat-toolbar class="sub-toolbar mat-elevation-z4" fxLayout="row" fxLayoutAlign="space-between center">
  <button mat-icon-button (click)="readersSidenav.toggle()" matTooltip="Apri/Chiudi pannello operatori">
    <mat-icon>person</mat-icon>
  </button>

  <!-- <app-entity-input (entitySelected)="onLetturaSelected($event)" *ngIf="!mapService.selectedLetture.length"
    [entityTemplate]="letturaTemplate" [filters]="mapService.loader.currentQuery?.filters"
    [filterColumns]="['utente', 'matricola', 'matricola_contatore_padre', 'pdr']"
    [loader]="lettureLoader"></app-entity-input> -->

  <ng-template #letturaTemplate let-entity>
    <div class="lettura-template" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <mat-chip-listbox>
        <mat-chip-option class="pdr-chip">{{entity.pdr}}</mat-chip-option>
      </mat-chip-listbox>
      <div fxFlex fxLayout="column">
        <span>{{entity.utente}}</span>
        <span>{{entity | letturaAddress}}</span>
      </div>
    </div>
  </ng-template>

  <div *ngIf="mapService.selectedLetture.length" fxLayout="row" fxLayoutAlign="center center">
    {{mapService.selectedLetture.length === 1 ? '1 elemento selezionato' : mapService.selectedLetture.length + '
    elementi selezionati'}}

    <button mat-icon-button (click)="mapService.unselectAll()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <div>
    <span matTooltip="Letture con coordinate trovate" *ngIf="mapService.coordinates.length > 0"
      style="margin-right: 20px; font-family: Roboto, 'Helvetica Neue', sans-serif;font-size: 16px ">Trovati su mappa:
      {{mapService.coordinates.length}}</span>
    <button (click)="onGeoReferenceButtonClicked()" [disabled]="!mapService.unlocatedLetture.length" mat-icon-button
      matTooltip="Geolocalizza attività con coordinate mancanti">
      <mat-icon [matBadgeHidden]="!mapService.unlocatedLetture.length" matBadge="{{mapService.unlocatedLetture.length}}"
        matBadgeColor="warn">
        my_location
      </mat-icon>
    </button>
    <button mat-icon-button [satPopoverAnchor]="helpPopOver" (click)="helpPopOver.toggle()" matTooltip="Legenda">
      <mat-icon>help</mat-icon>
    </button>
    <!-- <button mat-icon-button [matMenuTriggerFor]="markerVisualizationMenu" matTooltip="Cambia tema colori dei marker">
      <mat-icon>brush</mat-icon>
    </button> -->
    <button mat-icon-button (click)="filtersSidenav.toggle()" matTooltip="Apri/Chiudi pannello filtri">
      <mat-icon>filter_list</mat-icon>
    </button>
    <mat-menu #markerVisualizationMenu="matMenu">
      <button mat-menu-item (click)="mapService.changeMarkersTheme(0)">Pianificazione <mat-icon
          *ngIf="mapService.markersTheme === 0">check</mat-icon></button>
      <button mat-menu-item (click)="mapService.changeMarkersTheme(1)">Modalità preavviso <mat-icon
          *ngIf="mapService.markersTheme === 1">check</mat-icon></button>
      <button mat-menu-item (click)="mapService.changeMarkersTheme(2)">SMAT <mat-icon
          *ngIf="mapService.markersTheme === 2">check</mat-icon></button>
    </mat-menu>
  </div>
</mat-toolbar>

<sat-popover #helpPopOver hasBackdrop>
  <mat-card appearance="outlined" id="legend-card">
    <mat-card-content>
      <h4>Markers</h4>
      <mat-list>
        <mat-list-item>
          <img class="legend-item" height="24" src="assets/markers/letture/unselected.png" width="24"> Attività
        </mat-list-item>
        <mat-list-item>
          <mat-icon class="legend-item">account_box</mat-icon>
          Operatore
        </mat-list-item>
      </mat-list>

      <h4 id="legend-selection-title">Selezione</h4>
      <p class="mat-caption">CTRL + click e trascinamento per selezionare.<br>ALT + click e trascinamento per
        deselezionare.</p>
      <mat-list>
        <mat-list-item>
          <div class="legend-item legend-color" id="select"></div> Seleziona
        </mat-list-item>
        <mat-list-item>
          <div class="legend-item legend-color" id="deselect"></div> Deseleziona
        </mat-list-item>
      </mat-list>

      <h4>Colori</h4>
      <mat-list *ngIf="mapService.markersTheme === 0">
        <mat-list-item>
          <div class="legend-item legend-color monday"></div>
          Lunedì
        </mat-list-item>
        <mat-list-item>
          <div class="legend-item legend-color tuesday"></div>
          Martedì
        </mat-list-item>
        <mat-list-item>
          <div class="legend-item legend-color wednesday"></div>
          Mercoledì
        </mat-list-item>
        <mat-list-item>
          <div class="legend-item legend-color thursday"></div>
          Giovedì
        </mat-list-item>
        <mat-list-item>
          <div class="legend-item legend-color friday"></div>
          Venerdì
        </mat-list-item>
        <mat-list-item>
          <div class="legend-item legend-color saturday"></div>
          Sabato
        </mat-list-item>
        <mat-list-item>
          <div class="legend-item legend-color sunday"></div>
          Domenica
        </mat-list-item>
      </mat-list>

      <mat-list *ngIf="mapService.markersTheme === 1">
        <mat-list-item>
          <div class="legend-item legend-color sunday"></div>
          Utente già avvisato
        </mat-list-item>
        <mat-list-item>
          <div class="legend-item legend-color thursday"></div>
          SMS
        </mat-list-item>
        <mat-list-item>
          <div class="legend-item legend-color tuesday"></div>
          Email
        </mat-list-item>
        <mat-list-item>
          <div class="legend-item legend-color friday"></div>
          Call
        </mat-list-item>
        <mat-list-item>
          <div class="legend-item legend-color wednesday"></div>
          Avviso cartaceo
        </mat-list-item>
      </mat-list>

      <mat-list *ngIf="mapService.markersTheme === 2">
        <mat-list-item>
          <div class="legend-item legend-color monday"></div>
          Avviso SMAT
        </mat-list-item>
        <mat-list-item>
          <div class="legend-item legend-color wednesday"></div>
          Avviso cartaceo
        </mat-list-item>
        <mat-list-item>
          <div class="legend-item legend-color tuesday"></div>
          Accessibile
        </mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card>
</sat-popover>

<mat-sidenav-container class="flex1">
  <mat-sidenav #readersSidenav mode="side">
    <app-operators-dashboard (operatorClicked)="onLetturistaSelected($event); readersSidenav.close();"
      [orderReference]="mapCenter">
    </app-operators-dashboard>
  </mat-sidenav>

  <mat-sidenav #filtersSidenav mode="side" position="end">
    <!-- <app-map-filters (filters)="onFiltersUpdated($event); filtersSidenav.close();"></app-map-filters> -->
    <app-filters [filters]="filters" (filtersChanged)="onFiltersUpdated($event)"></app-filters>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="h100">

      <google-map #googleMap (centerChange)="onMapCenterChanged($event)" (mapInitialized)="onMapReady($event)"
        (zoomChange)="mapService.zoom = $event" [center]="{lat: mapService.latitude, lng: mapService.longitude}"
        [zoom]="mapService.zoom" [options]="{ styles: mapService.mapStyles }" class="h100">

        <!-- <ng-container *ngFor="let workLoad of (workService.workLoads | async)">
          <map-marker *ngIf="!!workLoad.lat && !!workLoad.lng" [position]="{lat: workLoad.lat, lng: workLoad.lng}"
            [options]="{ icon: workLoad.markerIcon }" (mapClick)="mapService.toggle(coordinate.body)">

            <map-info-window>
              <mat-card appearance="outlined">
                <table class="w100 workload-table">
                  <tr>
                    <th>Data</th>
                    <td>{{workLoad.data_fine | momentDate}}</td>
                  </tr>
                  <tr>
                    <th>Fascia oraria</th>
                    <td>{{workLoad.fascia_oraria | timeSlot}}</td>
                  </tr>
                  <tr>
                    <th>Numero attività</th>
                    <td>{{workLoad.count}}</td>
                  </tr>
                </table>
              </mat-card>
            </map-info-window>
          </map-marker>

          <map-circle *ngIf="(showWorkloadsRadius) && !!workLoad.radius && !!workLoad.lat && !!workLoad.lng"
            [center]="{lat: workLoad.lat, lng: workLoad.lng}" [options]="{
                editable: false,
                fillColor: workLoad.color,
                radius: workLoad.radius
            }">
          </map-circle>
        </ng-container> -->

        <map-marker *ngFor="let coordinate of mapService.coordinates; let i = index"
          [position]="{lat: coordinate.lat, lng: coordinate.lng}" [options]="{ icon: coordinate.markerIcon }"
          (mapClick)="openInfo(marker, i)" #marker="mapMarker">

          <map-info-window #infoWindows>
            <div class="info-window-grid">
              <div class="grid-item">Modello: {{ coordinate.body[0].tipo_contatore_modello_swm }}</div>
              <div class="grid-item">Zona: {{ coordinate.body[0].zona }}</div>
              <div class="grid-item">Pagina: {{ coordinate.body[0].pagina }}</div>
              <div class="grid-item">Libro: {{ coordinate.body[0].libro }}</div>
              <div class="grid-item">Pdr: {{ coordinate.body[0].pdr }}</div>
              <div class="grid-item">Utente: {{ coordinate.body[0].utente }}</div>
              <div class="grid-item">Cod. utente: {{ coordinate.body[0].codice_utente }}</div>
            </div>
            <button mat-button (click)="navigate(coordinate.body[0].deveui_codice_identificativo_radio_lorawan)"
              class="grid-item">Vai a Dettaglio</button>
          </map-info-window>
        </map-marker>
      </google-map>

      <!-- <agm-map id="googleMap"
               (centerChange)="onMapCenterChanged($event)"
               (mapReady)="onMapReady($event)"
               (zoomChange)="mapService.zoom = $event"
               [latitude]="mapService.latitude"
               [longitude]="mapService.longitude"
               [zoom]="mapService.zoom"
               [styles]="mapService.mapStyles"
               class="h100">

        <div *ngFor="let workLoad of (workService.workLoads | async)">
          <agm-marker
            *ngIf="!!workLoad.lat && !!workLoad.lng"
            [iconUrl]="workLoad.markerIcon"
            [latitude]="workLoad.lat"
            [longitude]="workLoad.lng">
            <agm-snazzy-info-window
              [border]="true"
              [maxWidth]="800"
              [padding]="'0px'">
              <ng-template>
                <mat-card>
                  <table class="w100 workload-table">
                    <tr>
                      <th>Data</th>
                      <td>{{workLoad.data_fine | momentDate}}</td>
                    </tr>
                    <tr>
                      <th>Fascia oraria</th>
                      <td>{{workLoad.fascia_oraria | timeSlot}}</td>
                    </tr>
                    <tr>
                      <th>Numero attività</th>
                      <td>{{workLoad.count}}</td>
                    </tr>
                  </table>
                </mat-card>
              </ng-template>
            </agm-snazzy-info-window>
          </agm-marker>

          <agm-circle *ngIf="(showWorkloadsRadius) && !!workLoad.radius && !!workLoad.lat && !!workLoad.lng"
                      [editable]="false"
                      [fillColor]="workLoad.color"
                      [latitude]="workLoad.lat"
                      [longitude]="workLoad.lng"
                      [radius]="workLoad.radius">
          </agm-circle>
        </div>

        <agm-marker *ngFor="let coordinate of mapService.coordinates"
                    [iconUrl]="coordinate.markerIcon"
                    [latitude]="coordinate.lat"
                    [longitude]="coordinate.lng"
                    (markerRightClick)="mapService.toggle(coordinate.body)">
          <agm-snazzy-info-window
            [border]="true"
            [maxHeight]="400"
            [maxWidth]="800"
            [padding]="'0px'">
            <ng-template>
              <app-activity-details showActions="true"
                                    [letture]="coordinate.body"
                                    (onLetturaUpdated)="mapService.lettureUpdated($event)"></app-activity-details>
            </ng-template>
          </agm-snazzy-info-window>
        </agm-marker>

      </agm-map> -->

      <mat-progress-bar *ngIf="mapService.loader.loading | async"
        [mode]="(mapService.loader.loaded | async) === null ? 'indeterminate' : 'determinate'"
        [value]="mapService.loader.loaded | async" color="accent">
      </mat-progress-bar>

      <mat-button-toggle-group id="raggi-toggle-group" [(value)]="showWorkloadsRadius" vertical>
        <mat-button-toggle [value]="true" matTooltip="Abilita visualizzazione raggi fasce orarie">
          <mat-icon>visibility</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle [value]="false" matTooltip="Disbilita visualizzazione raggi fasce orarie">
          <mat-icon>visibility_off</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <mat-button-toggle-group id="map-type-group" [(value)]="mapType" horizzontal>
        <mat-button-toggle (click)="changeMapType('roadmap')" [value]="true" matTooltip="Visualizzazione mappa" ng>
          Mappa
        </mat-button-toggle>
        <mat-button-toggle (click)="changeMapType('satellite')" [value]="false" matTooltip="Visualizzazione satellite">
          Satellite
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>