import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import {isMoment, Moment} from 'moment';

@Pipe({
    name: 'momentDate'
})
export class MomentDatePipe implements PipeTransform {

    private readonly formats: string[] = [
        'YYYY-MM-DD',
        'DD/MM/YYYY',
        'DD\\MM\\YYYY'
    ];

    transform(value: string | Moment): string {
        let parsed: Moment = null;
        if (!isMoment(value)) {
            for (const format of this.formats) {
                parsed = moment(value, format);
                if (parsed.isValid()) {
                    break;
                }
            }
        } else {
            parsed = value;
        }
        parsed.locale('it-IT');
        if (parsed.format('L') == 'Invalid date') {
          return '-';
        }
        return parsed.format('L');
    }

}
