/* eslint-disable linebreak-style */
import { Environment } from './model/environment-model';

export const environment: Environment = {
  // Local
  baseUrl: 'https://backend.testgea.lektor.it/',
  //baseUrl: 'http://backend.sviluppo.amap.lektor.it:85/',
  baseUrlMiddleware: 'http://164.68.116.218:40000/',
  echoForceTLS: false,
  echoHost: 'backend.testgea.lektor.it',
  echoKey: 'TODO',
  echoPort: 85,
  oauth2ClientId: '2',
  oauth2ClientSecret: 'b3cKXsHMcA1uIpWxYYoBpImRDQbEX4tuyAQIHTZc',
  production: true,
  googleMapsKey: 'AIzaSyCRsqkFFWE9xdmNj-5aPLjuuFHUkIm0qFQ'
};
