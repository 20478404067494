import { Injectable } from '@angular/core';
import { AppaltiService } from './appalti.service';
import { UserService } from './user.service';
import { EnteService } from './ente.service';
import { EchoService } from 'ngx-laravel-echo';
import { OAuthService } from 'angular-oauth2-oidc';
import { LavoriService } from './lavori.service';

@Injectable({
  providedIn: 'root'
})
export class PrerequisitesService {
  public isLoaded = false;

  constructor(private appaltiService: AppaltiService,
    private echoService: EchoService,
    private enteService: EnteService,
    private lavoriService: LavoriService,
    private oauthService: OAuthService,
    private userService: UserService) { }

  public async load(): Promise<void> {
    await Promise.all([
      this.appaltiService.load(),
      this.enteService.load(),
      this.userService.load(),
    ]);
    this.echoService.login({ Authorization: this.oauthService.authorizationHeader() }, this.userService.user.id);
    this.lavoriService.subscribe();
    this.isLoaded = true;
  }

  public clear(): void {
    this.isLoaded = false;
    this.appaltiService.appalti = null;
    this.enteService.ente = null;
    this.userService.user = null;
    this.lavoriService.unsubscribe();
    this.echoService.logout();

    window.location.reload();
  }
}
