enum OrderTypes {
    ASCENDING = 'asc',
    DESCENDING = 'desc'
}

export abstract class Order {
    field: string;
    type: OrderTypes;

    constructor(subject: string) {
        this.field = subject;
    }
}

export class Ascending extends Order {
    type = OrderTypes.ASCENDING;
}

export class Descending extends Order {
    type = OrderTypes.DESCENDING;
}
