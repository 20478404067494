import { Pipe, PipeTransform } from '@angular/core';
import {isMoment, Moment} from 'moment';
import * as moment from 'moment';

@Pipe({
  name: 'momentTime'
})
export class MomentTimePipe implements PipeTransform {

  transform(value: string | Moment): string {
    let parsed: Moment = null;
    if (isMoment(value)) {
      parsed = value;
    } else {
      parsed = moment(value);
    }
    parsed.locale('it-IT');
    return parsed.format('LT');
  }

}
