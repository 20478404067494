import { Injectable } from '@angular/core';
import { DataLoaderProviderService } from './data-loader-provider.service';
import { ImmutableDataLoader } from '../../models/data-loader';
import { Swm } from 'src/models/raw/swm';

@Injectable({
    providedIn: 'root'
})
export class SwmService {

    public loader: ImmutableDataLoader<Swm>;

    constructor(private dataLoaderProvider: DataLoaderProviderService) {
        this.loader = this.dataLoaderProvider.getSwmLoader();
    }
}
