<mat-toolbar class="sub-toolbar mat-elevation-z4">
  <app-search-input (search)="onSearchChanged($event)"></app-search-input>

  <button mat-icon-button id="upload-button" routerLink="/last-value" matTooltip="Nuova importazione">
    <mat-icon>flip_to_back</mat-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container>

  <mat-sidenav #filtersSidenav mode="side" position="end">
  </mat-sidenav>

  <mat-sidenav-content *ngIf="loader">
    <div class="h100 container">
      <mat-progress-bar *ngIf="(loader.loading | async)" mode="indeterminate" color="accent"></mat-progress-bar>

      <div infiniteScroll [scrollWindow]="false" (scrolled)="onTableScrolled()" [infiniteScrollDistance]="1"
        class="table-container">
        <mat-table matSort matSortActive="id" matSortDirection="asc" (matSortChange)="onSort($event)"
          [dataSource]="loader" class="mat-elevation-z8">

          <ng-container matColumnDef="tipo_contatore_modello_swm">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Tipo contatore</mat-header-cell>
            <mat-cell *matCellDef="let file">{{file.tipo_contatore_modello_swm}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="latitudine">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Latitudine</mat-header-cell>
            <mat-cell *matCellDef="let file">{{file.latitudine}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="longitudine">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Longitudine</mat-header-cell>
            <mat-cell *matCellDef="let file">{{file.longitudine}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="matricola">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Matricola</mat-header-cell>
            <mat-cell *matCellDef="let file">{{file.matricola}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="comune">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Comune</mat-header-cell>
            <mat-cell *matCellDef="let file">{{file.comune}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="localita">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Località</mat-header-cell>
            <mat-cell *matCellDef="let file">{{file.localita}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="codice_utente">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Codice utente</mat-header-cell>
            <mat-cell *matCellDef="let file">{{file.codice_utente}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="pdr">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Pdr</mat-header-cell>
            <mat-cell *matCellDef="let file">{{file.pdr}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="data_installazione_contatore">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Data installazione contatore</mat-header-cell>
            <mat-cell *matCellDef="let file">{{getDate(file.data_installazione_contatore)}}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
          <mat-row mat-ripple *matRowDef="let lettura; columns: displayedColumns;" class="selectable-row"
            (click)="onSelected(lettura)"></mat-row>
        </mat-table>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>