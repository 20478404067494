import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import {AgmCoreModule} from '@agm/core';
import { AppRoutingModule } from './modules/app-routing.module';
import { AppComponent } from './app.component';
import { MapComponent } from './components/pages/map/map.component';
import { PageNotFoundComponent } from './components/pages/page-not-found/page-not-found.component';
import { LoginComponent } from './components/pages/login/login.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './guards/auth.guard';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './modules/material.module';
import { MapFiltersComponent } from './components/partials/map-filters/map-filters.component';
//import {AgmSnazzyInfoWindowModule} from '@agm/snazzy-info-window';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ActivityDialogComponent } from './components/dialogs/activity-dialog/activity-dialog.component';
import { GeolocateDialogComponent } from './components/dialogs/geolocate-dialog/geolocate-dialog.component';
import { OperatorsDashboardComponent } from './components/partials/operators-dashboard/operators-dashboard.component';
import { DaterangeSelectionDialogComponent } from './components/dialogs/daterange-selection-dialog/daterange-selection-dialog.component';
import { CommonModule } from '@angular/common';
import { TimelineFilterComponent } from './components/partials/timeline-filter/timeline-filter.component';
import { SimpleAlertDialogComponent } from './components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { CarichiDialogComponent } from './components/dialogs/carichi-dialog/carichi-dialog.component';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ActivityDetailsComponent } from './components/partials/activity-details/activity-details.component';
import { GeoOrderPipe } from './pipes/geo-order.pipe';
import { GeoFilterPipe } from './pipes/geo-filter.pipe';
import { MomentDatePipe } from './pipes/moment-date.pipe';
import { GeolocateManualDialogComponent } from './components/dialogs/geolocate-manual-dialog/geolocate-manual-dialog.component';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { WorkloadsDialogComponent } from './components/dialogs/workloads-dialog/workloads-dialog.component';
import { TimeSlotPipe } from './pipes/time-slot.pipe';
import { SettingsComponent } from './components/pages/settings/settings.component';
import { CarichiComponent } from './components/pages/carichi/carichi.component';
import { LetturaAddressPipe } from './pipes/lettura-address.pipe';
import { SearchDialogComponent } from './components/dialogs/search-dialog/search-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SearchInputComponent } from './components/pages/search-input/search-input.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ImportComponent } from './components/pages/import/import.component';
import { CaricofileDialogComponent } from './components/dialogs/caricofile-dialog/caricofile-dialog.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FileDropperComponent } from './components/partials/file-dropper/file-dropper.component';
import { LettureComponent } from './components/pages/letture/letture.component';
import { LetturaComponent } from './components/pages/lettura/lettura.component';
import { PhotoComponent } from './components/partials/photo/photo.component';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { TrackingComponent } from './components/pages/tracking/tracking.component';
import { TimeSliderPipe } from './pipes/time-slider.pipe';
import { EntityInputComponent } from './components/partials/entity-input/entity-input.component';
import { MomentTimePipe } from './pipes/moment-time.pipe';
import { ValidationDialogComponent } from './components/dialogs/validation-dialog/validation-dialog.component';
import { HttpErrorInterceptor } from './interceptors/http-error-interceptor';
import { SplashComponent } from './components/pages/splash/splash.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MagazzinoComponent } from './components/pages/magazzino/magazzino.component';
// tslint:disable-next-line:max-line-length
import { MagazzinoAssociationDialogComponent } from './components/dialogs/magazzino-association-dialog/magazzino-association-dialog.component';
import { ReportComponent } from './components/pages/report/report.component';
import { ReportsComponent } from './components/pages/reports/reports.component';
import { SanitizeUrlPipe } from './pipes/sanitize-url.pipe';
import { AppointmentDialogComponent } from './components/dialogs/appointment-dialog/appointment-dialog.component';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import { EnteSelectorComponent } from './components/partials/ente-selector/ente-selector.component';
import { NgxLaravelEchoModule } from 'ngx-laravel-echo';
import { LavoriComponent } from './components/pages/lavori/lavori.component';
import { FileSaverModule } from 'ngx-filesaver';
import { FunctionsComponent } from './components/partials/functions/functions.component';
import { SimpleFormDialogComponent } from './components/dialogs/simple-form-dialog/simple-form-dialog.component';
import { CaricoExportDialogComponent } from './components/dialogs/carico-export-dialog/carico-export-dialog.component';
import { FiltersComponent } from './components/partials/filters/filters.component';
import { MagazzinoConsumabiliComponent } from './components/pages/magazzino-consumabili/magazzino-consumabili.component';
// tslint:disable-next-line:max-line-length
import { ConsumabiliTerminaliDialogComponent } from './components/dialogs/consumabili-terminali-dialog/consumabili-terminali-dialog.component';
import { StatiMagazzinoEntityDialogComponent } from './components/dialogs/entity-input-dialog/stati-magazzino-entity-dialog.component';
import { StatiMagazzinoComponent } from './components/pages/stati-magazzino/stati-magazzino.component';
import { TerminaleAssociationCountComponent } from './components/dialogs/terminale-association-count/terminale-association-count.component';
import { ArticlesOfLetturaDialogComponent } from './components/dialogs/articles-of-lettura-dialog/articles-of-lettura-dialog.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { SwmComponent } from './components/pages/swm/swm.component';
import { AlarmsComponent } from './components/pages/alarms/alarms.component';
import { ImportNewComponent } from './components/pages/import-new/import-new.component';
import { SwmDetailComponent } from './components/pages/swm-detail/swm-detail.component';
import { CaricofileDialogNewComponent } from './components/dialogs/caricofile-dialog-new/caricofile-dialog-new.component';
import { NgChartsModule } from 'ng2-charts';
import { ExportComponent } from './components/pages/export/export.component';
import { FrameComponent } from './components/pages/frame/frame.component';
import { ChartReportComponent } from './components/pages/chart-report/chart-report.component';
import { CaricofileDialogNewExportComponent } from './components/dialogs/caricofile-dialog-new-export/caricofile-dialog-new.component';
import { MonitorComponent } from './components/pages/monitor/last-value/monitor.component';
import { MonitorNeverSentComponent } from './components/pages/monitor/never-sent/monitor.component';
import { AlarmsMapComponent } from './components/pages/alarms/map/alarms-map.component';

@NgModule({
  // entryComponents: [
  //   ActivityDialogComponent,
  //   AppointmentDialogComponent,
  //   CarichiDialogComponent,
  //   CaricofileDialogComponent,
  //   CaricoExportDialogComponent,
  //   DaterangeSelectionDialogComponent,
  //   GeolocateManualDialogComponent,
  //   GeolocateDialogComponent,
  //   SearchDialogComponent,
  //   MagazzinoAssociationDialogComponent,
  //   SimpleAlertDialogComponent,
  //   SimpleFormDialogComponent,
  //   ValidationDialogComponent,
  //   WorkloadsDialogComponent,
  // ],
  declarations: [
    AppComponent,
    MapComponent,
    PageNotFoundComponent,
    LoginComponent,
    MapFiltersComponent,
    ActivityDialogComponent,
    GeolocateDialogComponent,
    OperatorsDashboardComponent,
    DaterangeSelectionDialogComponent,
    TimelineFilterComponent,
    SimpleAlertDialogComponent,
    CarichiDialogComponent,
    ActivityDetailsComponent,
    GeoOrderPipe,
    GeoFilterPipe,
    MomentDatePipe,
    GeolocateManualDialogComponent,
    WorkloadsDialogComponent,
    TimeSlotPipe,
    SettingsComponent,
    CarichiComponent,
    LetturaAddressPipe,
    SearchDialogComponent,
    SearchInputComponent,
    ImportComponent,
    CaricofileDialogComponent,
    FileDropperComponent,
    LettureComponent,
    LetturaComponent,
    PhotoComponent,
    SplashComponent,
    TrackingComponent,
    TimeSliderPipe,
    EntityInputComponent,
    MomentTimePipe,
    ValidationDialogComponent,
    MagazzinoComponent,
    MagazzinoAssociationDialogComponent,
    ReportComponent,
    ReportsComponent,
    SanitizeUrlPipe,
    AppointmentDialogComponent,
    EnteSelectorComponent,
    LavoriComponent,
    FunctionsComponent,
    SimpleFormDialogComponent,
    CaricoExportDialogComponent,
    FiltersComponent,
    MagazzinoConsumabiliComponent,
    ConsumabiliTerminaliDialogComponent,
    StatiMagazzinoEntityDialogComponent,
    StatiMagazzinoComponent,
    TerminaleAssociationCountComponent,
    ArticlesOfLetturaDialogComponent,
    SwmComponent,
    AlarmsComponent,
    ImportNewComponent,
    SwmDetailComponent,
    CaricofileDialogNewComponent,
    ExportComponent,
    FrameComponent,
    ChartReportComponent,
    CaricofileDialogNewExportComponent,
    MonitorComponent,
    MonitorNeverSentComponent,
    AlarmsMapComponent
  ],
  imports: [
    // AgmCoreModule.forRoot({
    //   apiKey: environment.googleMapsKey,
    // }),
    // AgmSnazzyInfoWindowModule,
    GoogleMapsModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FileSaverModule,
    FlexLayoutModule,
    HttpClientModule,
    InfiniteScrollModule,
    MaterialModule,
    NgxDaterangepickerMd.forRoot(),
    NgxFileDropModule,
    NgxLaravelEchoModule.forRoot({
      userModel: 'user',
      notificationNamespace: null,
      options: {
        broadcaster: 'pusher',
        authEndpoint: `${environment.baseUrl}broadcasting/auth`,
        wsHost: environment.echoHost,
        wsPort: environment.echoPort,
        wssPort: environment.echoPort,
        key: environment.echoKey,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
        forceTLS: environment.echoForceTLS,
      }
    }),
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxWebstorageModule.forRoot(),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.baseUrl + 'api'],
        sendAccessToken: true
      }
    }),
    ReactiveFormsModule,
    SatPopoverModule,
    NgChartsModule
  ],
  providers: [
    AuthGuard,
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    HttpClientModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
