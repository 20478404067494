<table mat-dialog-content>
  <tr>
    <th fxLayout="row" fxLayoutAlign="center center">
      <mat-progress-spinner *ngIf="isLoading | async" diameter="24" mode="indeterminate" color="accent"></mat-progress-spinner>
    </th>
    <th class="date-header" *ngFor="let date of dates | keyvalue" [attr.colspan]="date.value.length">{{date.key | momentDate}}</th>
  </tr>
  <tr>
    <th></th>
    <ng-container *ngFor="let date of dates | keyvalue">
      <th class="timeslot-header" *ngFor="let timeSlot of date.value">{{timeSlot.substring(timeSlot.indexOf('_')+1) | timeSlot}}</th>
    </ng-container>
  </tr>
  <tr *ngFor="let operator of operators">
    <th class="operator-header">{{operator?.letturista_nome}}</th>
    <td *ngFor="let operatorData of operatorsData.get(operator)">
      <span>{{operatorData.letture}}</span>
      <mat-chip-option *ngIf="!(isLoading | async) && getAppointments(operator.letturista_numero, operatorData.date, operatorData.timeSlot) > 0"
                matTooltip="Appuntamenti">
        {{getAppointments(operator.letturista_numero, operatorData.date, operatorData.timeSlot)}}
      </mat-chip-option>
    </td>
  </tr>
</table>
