import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialogRef} from '@angular/material/dialog';
import {Magazzino} from '../../../../models/raw/magazzino';
import {LoadingOverlayService} from '../../../services/loading-overlay.service';

@Component({
  selector: 'app-magazzino-association-dialog',
  templateUrl: './magazzino-association-dialog.component.html',
  styleUrls: ['./magazzino-association-dialog.component.scss']
})
export class MagazzinoAssociationDialogComponent {
  public isLoading = new BehaviorSubject<boolean>(false);
  public formGroup = new FormGroup({
    terminale: new FormControl('', Validators.required),
    codes: new FormControl('', Validators.required)
  });

  constructor(private dialogRef: MatDialogRef<MagazzinoAssociationDialogComponent>,
              private http: HttpClient, private snackBar: MatSnackBar,
              private loadingOverlay: LoadingOverlayService) {
  }

  public async onConfirmButtonClicked(): Promise<void> {
    this.isLoading.next(true);

    try {
      this.loadingOverlay.show();
      const updatedElements = await this.http.post<Magazzino[]>(
        `${environment.baseUrl}api/client/magazzino/associate`,
        this.formGroup.value
      ).toPromise();
      this.loadingOverlay.hide();
      this.snackBar.open(`${updatedElements.length} elementi aggiornati con successo!`, null, {duration: 5000});
      this.dialogRef.close(true);
    } catch (e) {
      this.snackBar.open('Impossibili completare l’associazione dei codici inseriti. Riprova più tardi.', null, {duration: 5000});
    }

    this.isLoading.next(false);
  }
}
