import { Injectable } from '@angular/core';
import { DataLoaderProviderService } from './data-loader-provider.service';
import { ImmutableDataLoader } from '../../models/data-loader';
import { Alarm } from 'src/models/raw/alarm';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AlarmsService {

    public loader: ImmutableDataLoader<Alarm>;

    constructor(
        private dataLoaderProvider: DataLoaderProviderService,
        private _http: HttpClient) {
        this.loader = this.dataLoaderProvider.getAlarmsLoader();
    }

    getDescList(): Promise<any[]> {
        return this._http.get<any[]>(`${environment.baseUrl}api/client/lorawan/getListAllarmi`)
            .toPromise();
    }

    getAlarmsMap(): Promise<any[]> {
        return this._http.post<any[]>(`${environment.baseUrl}api/client/getAllarmi/allNoPaginate`, {})
            .toPromise();
    }

    export(type: string) {
        console.log(type);
    }
}
