/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, HostBinding, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
//import {LatLngLiteral} from '@agm/core';
import { DataLoaderProviderService } from '../../../services/data-loader-provider.service';
import { Lettura } from '../../../../models/raw/lettura';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ActivityDialogComponent } from '../../dialogs/activity-dialog/activity-dialog.component';
import { Letturista } from '../../../../models/raw/letturista';
import { GeolocateDialogComponent } from '../../dialogs/geolocate-dialog/geolocate-dialog.component';
import { WorkloadService } from '../../../services/workload.service';
import { debounceTime } from 'rxjs/operators';
import { MapService } from '../../../services/map.service';
//import {GoogleMap} from '@agm/core/services/google-maps-types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { And, Logical } from '../../../../models/query-lang/logical';
import { ImmutableDataLoader } from '../../../../models/data-loader';
import { ColumnFilter, ColumnFilterType } from '../../partials/filters/filters.component';
import { Equal } from 'src/models/query-lang/comparison';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Router } from '@angular/router';

declare const google: any;

enum SelectionModes {
  IDLE, SELECTING, UNSELECTING
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  providers: [WorkloadService]

})
export class MapComponent implements OnInit {
  @HostBinding('class') classes = 'flex-column-fill';
  @ViewChildren(MapInfoWindow) infoWindows: QueryList<MapInfoWindow>;
  markers: MapMarker[] = [];

  public filters: ColumnFilter[] = [
    { column: 'zona', label: 'Zona', type: ColumnFilterType.STRING },
    { column: 'libro', label: 'Libro', type: ColumnFilterType.STRING },
    { column: 'pagina', label: 'Pagina', type: ColumnFilterType.STRING },
    { column: 'matricola', label: 'Matricola', type: ColumnFilterType.STRING },
    { column: 'data_installazione_contatore', label: 'Data installazione', type: ColumnFilterType.DATE }
  ];

  private selectionMode: SelectionModes = SelectionModes.IDLE;
  public showWorkloadsRadius = false;
  public mapType = true;

  private map: google.maps.Map = null;
  private _mapCenter: BehaviorSubject<google.maps.LatLngLiteral> = new BehaviorSubject(null);
  public mapCenter: BehaviorSubject<google.maps.LatLngLiteral> = new BehaviorSubject(null);

  public lettureLoader: ImmutableDataLoader<Lettura>;

  constructor(private loaderProvider: DataLoaderProviderService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    public mapService: MapService,
    public workService: WorkloadService,
    private router: Router) {
  }

  ngOnInit() {
    this.lettureLoader = this.loaderProvider.getLettureLoader(100);
    this._mapCenter.pipe(debounceTime(500)).subscribe(value => {
      if (!!value) {
        this.mapService.latitude = value.lat;
        this.mapService.longitude = value.lng;
      }
    });
  }

  @HostListener('window:keyup', ['$event'])
  private onKeyUp(event: KeyboardEvent): void {
    this.selectionMode = SelectionModes.IDLE;
  }

  @HostListener('window:keydown', ['$event'])
  private onKeyDown(event: KeyboardEvent): void {
    switch (event.key) {
      case 'Control':
        this.selectionMode = SelectionModes.SELECTING;
        break;

      case 'Alt':
        this.selectionMode = SelectionModes.UNSELECTING;
        break;
    }
  }

  public onMapReady(map: google.maps.Map): void {
    this.map = map;
    const me = this;

    let bounds;
    let boundingBox = null;
    let mouseIsDown = false;

    google.maps.event.addListener(map, 'mousemove', function (e) {
      if (mouseIsDown && me.selectionMode !== SelectionModes.IDLE) {
        if (boundingBox !== null) {
          bounds.extend(e.latLng);
          boundingBox.setBounds(bounds);
        } else {
          bounds = new google.maps.LatLngBounds();
          bounds.extend(e.latLng);
          boundingBox = new google.maps.Rectangle({
            map: map,
            bounds: bounds,
            fillColor: me.selectionMode === SelectionModes.SELECTING ? 'green' : 'red',
            fillOpacity: 0.15,
            strokeColor: me.selectionMode === SelectionModes.SELECTING ? 'green' : 'red',
            strokeWeight: 0.9,
            clickable: false
          });
        }
      }
    });

    google.maps.event.addListener(map, 'mousedown', function (e) {
      if (me.selectionMode !== SelectionModes.IDLE) {
        mouseIsDown = true;
        me.map.setOptions({
          gestureHandling: 'none'
        });
      }
    });

    google.maps.event.addListener(map, 'mouseup', function (e) {
      if (mouseIsDown) {
        mouseIsDown = false;
        if (boundingBox !== null) {
          boundingBox.setMap(null);
          switch (me.selectionMode) {
            case SelectionModes.SELECTING:
              me.mapService.selectCoordinates(me.mapService.coordinates.filter(c => bounds.contains(c)));
              break;

            case SelectionModes.UNSELECTING:
              me.mapService.unselectCoordinates(me.mapService.coordinates.filter(c => bounds.contains(c)));
              break;
          }
        }
        boundingBox = null;
      }

      me.map.setOptions({
        gestureHandling: 'auto'
      });
    });
  }

  public onMapCenterChanged(center: google.maps.LatLngLiteral): void {
    this._mapCenter.next(center);
  }

  public onLetturaSelected(lettura: Lettura): void {
    const index = this.mapService.coordinates.findIndex(c => c.body.findIndex(l => l.id === lettura.id) >= 0);
    if (index >= 0) {
      this.mapService.toggle(this.mapService.coordinates[index].body.find(l => l.id === lettura.id));
    } else {
      this.snackBar.open('Impossibile trovare questa lettura tra quelle presenti in mappa', null, { duration: 5000 });
    }
  }

  public onLetturistaSelected(letturista: Letturista): void {
    const groupedLetture = [];
    /*     for (const lettura of this.mapService.selectedLetture) {
          groupedLetture[lettura.stato] = groupedLetture[lettura.stato] || [];
          groupedLetture[lettura.stato].push(lettura);
        } */

    const keys = Object.keys(groupedLetture);
    // if (keys.length === 1) {
    this.dialog.open(ActivityDialogComponent, {
      data: {
        letture: this.mapService.selectedLetture,
        letturista: letturista,
        //status: 'NUL',
      }
    }).afterClosed().toPromise().then(result => {
      if (result instanceof Array) {
        this.mapService.lettureUpdated(result);
        this.mapService.selectedLetture = this.mapService.selectedLetture.filter(
          value => result.findIndex(updated => updated.id === value.id) < 0
        );
      }
    }
    );
    //  }
  }

  public onFiltersUpdated(filters: Logical) {
    if (filters.args.length === 0)
      return this.mapService.coordinates = [];

    this.mapService.newQuery({
      fields: this.mapService.loader.currentQuery?.fields,
      filters: filters,
      orders: this.mapService.loader.currentQuery?.orders,
    });
  }

  public async onGeoReferenceButtonClicked(): Promise<void> {
    if (this.mapService.unlocatedLetture.length > 0) {
      const dialogRef = this.dialog.open(GeolocateDialogComponent, { data: this.mapService.loader });
      const letture = await dialogRef.afterClosed().toPromise();
      if (letture instanceof Array) {
        this.mapService.lettureUpdated(letture);
      }
    }
  }

  public changeMapType(type: string): void {
    console.log(type);
    let mapOpt = {};
    switch (type) {
      case 'satellite': {
        mapOpt = {
          mapTypeId: google.maps.MapTypeId.HYBRID
        };
        break;
      }
      case 'roadmap': {
        mapOpt = {
          mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        break;
      }
      default: {
        mapOpt = {
          mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        break;
      }
    }

    this.map.setOptions(mapOpt);
  }

  openInfo(marker: MapMarker, windowIndex: number) {

    const window = this.infoWindows.toArray()[windowIndex];
    window.open(marker);
  }

  navigate(id: number) {
    console.log(id, );
    
    this.router.navigate(['swm', id]);
  }
}
