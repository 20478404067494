import {Component, HostBinding, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PrerequisitesService} from '../../../services/prerequisites.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit {
  @HostBinding('class') classes = 'flex-column-fill';

  constructor(private prerequisitesService: PrerequisitesService,
              private router: Router, private snackbar: MatSnackBar,
              private route: ActivatedRoute) {
  }

  public async ngOnInit(): Promise<void> {
    try {
      await this.prerequisitesService.load();
      this.router.navigate([this.route.snapshot.queryParamMap.get('redirectTo')]);

    } catch (e) {
      this.snackbar.open(
        'Errore durante il caricamento dei dati. Verifica che il tuo utente abbia accesso a questo sito e riprova',
        'Torna al login',
        {duration: 10000}
      ).afterDismissed().toPromise().then(_ => this.router.navigate(['/login']));
    }
  }
}
