<mat-toolbar class="sub-toolbar mat-elevation-z4" fxLayout="row" fxLayoutAlign="space-between center">
  <div></div>

  <app-search-input (search)="onSearchChanged($event)"></app-search-input>

  <div>
    <button mat-icon-button (click)="onAddButtonClicked()" matTooltip="Nuovo Articolo">
      <mat-icon>add</mat-icon>
    </button>

    <button mat-icon-button (click)="filtersSidenav.toggle()" matTooltip="Apri/Chiudi pannello filtri">
      <mat-icon>filter_list</mat-icon>
    </button>

  </div>
</mat-toolbar>

<mat-sidenav-container>

  <mat-sidenav #filtersSidenav mode="side" position="end">
    <app-filters [filters]="filters" (filtersChanged)="onFiltersUpdated($event)"></app-filters>
  </mat-sidenav>

  <mat-sidenav-content *ngIf="loader">

    <div class="h100 container">

      <mat-progress-bar *ngIf="(loader.loading | async)" mode="indeterminate" color="accent"></mat-progress-bar>

      <div infiniteScroll
           [scrollWindow]="false"
           (scrolled)="onTableScrolled()"
           [infiniteScrollDistance]="1"
           class="table-container">
        <mat-table matSort
                   matSortActive="id"
                   matSortDirection="asc"
                   (matSortChange)="onSort($event)"
                   [dataSource]="loader"
                   class="mat-elevation-z8">
          <ng-container matColumnDef="id">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Id</mat-header-cell>
            <mat-cell *matCellDef="let magazzino">{{magazzino.id}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="articolo">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Articolo</mat-header-cell>
            <mat-cell *matCellDef="let magazzino">{{magazzino.articolo}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="quantita">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Quantità</mat-header-cell>
            <mat-cell *matCellDef="let magazzino">{{magazzino.quantita}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="quantitaDisponibile">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Quantità Disponibile</mat-header-cell>
            <mat-cell *matCellDef="let magazzino">{{magazzino.quantitaDisponibile}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let magazzino">
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="onEditButtonButtonClicked(magazzino)">Modifica Articolo</button>
                <button mat-menu-item (click)="onDeleteButtonClicked(magazzino)">Elimina Articolo</button>
                <button *ngIf="magazzino.quantitaDisponibile > 0" mat-menu-item (click)="onAddTerminalButtonButtonClicked(magazzino)">Assegna a Terminale</button>
                <button mat-menu-item (click)="onGetArticleTerminalsButtonClicked(magazzino)">Visualizza Assegnazioni</button>
              </mat-menu>

              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
          <mat-row *matRowDef="let magazzino; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

