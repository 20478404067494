import {Injectable} from '@angular/core';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {Subject} from 'rxjs';
import {ComponentPortal} from '@angular/cdk/portal';
import {MatSpinner} from '@angular/material/progress-spinner';

@Injectable({
  providedIn: 'root'
})
export class LoadingOverlayService {
  private overlayRef: OverlayRef;
  private subject: Subject<boolean> = new Subject();

  constructor(private overlay: Overlay) {
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy: this.overlay.position()
          .global()
          .centerHorizontally()
          .centerVertically()
    });

    this.subject
        .asObservable()
        .subscribe(
            (value) => {
              if (value) {
                this.overlayRef.attach(new ComponentPortal(MatSpinner));
              } else if (this.overlayRef.hasAttached()) {
                this.overlayRef.detach();
              }
            }
        );
  }

  public show() {
    this.subject.next(true);
  }

  public hide() {
    this.subject.next(false);
  }
}
