import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {SatPopover} from '@ncstate/sat-popover';
import {BehaviorSubject} from 'rxjs';
import {ImmutableDataLoader} from '../../../../models/data-loader';
import {debounceTime} from 'rxjs/operators';
import {And, Logical, Or} from '../../../../models/query-lang/logical';
import {Like} from '../../../../models/query-lang/comparison';
import {SearchInputComponent} from '../../pages/search-input/search-input.component';
import {MatSelectionList, MatSelectionListChange} from '@angular/material/list';

@Component({
  selector: 'app-entity-input',
  templateUrl: './entity-input.component.html',
  styleUrls: ['./entity-input.component.scss']
})
export class EntityInputComponent<T> implements OnInit {
  @ViewChild('appSearchInput', {static: true})
  private appSearchInput: SearchInputComponent;

  @ViewChild('popOver', {static: true})
  private popOver: SatPopover;

  @ViewChild('list', {static: false})
  private list: MatSelectionList;

  @Input()
  public filterColumns: string[];

  @Input()
  public filters: Logical;

  @Input()
  public loader: ImmutableDataLoader<T>;

  @Input()
  public entityTemplate: TemplateRef<any>;

  @Output()
  public entitySelected = new EventEmitter<T>();

  public entityLoader: ImmutableDataLoader<T>;
  public querySubject: BehaviorSubject<string> = new BehaviorSubject('');

  ngOnInit() {
    this.querySubject.pipe(debounceTime(300)).subscribe(value => {
      this.popOver.close();
      if (!value) {
        return;
      }
      let orders = null;
      if (this.loader.currentQuery != null) {
        orders = this.loader.currentQuery.orders;
      }
      const or = new Or();
      or.args.push(...this.filterColumns.map(column => new Like(column, `%${value}%`)));
      this.entityLoader = this.loader.newQuery({
        filters: this.filters ? new And([this.filters, or]) : or,
        orders: orders
      });
      this.entityLoader.loadNext().then(() => {
        this.popOver.toggle();
      });
    });
  }

  public onAppSearchInputKeyboardKeyUp(event: KeyboardEvent): void {
    if (event.code === 'ArrowDown' && this.entityLoader.items.getValue().length > 0) {
      this.list.focus();
    }
  }

  public onEntitySelected(event: MatSelectionListChange): void {
    this.popOver.close();
    this.appSearchInput.clear();
    this.entitySelected.emit(event.options[0].value);
  }
}
