import { Component, Inject, OnInit } from '@angular/core';
import { Letturista } from '../../../../models/raw/letturista';
import { Lettura } from '../../../../models/raw/lettura';
import { DataLoaderProviderService } from '../../../services/data-loader-provider.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Ente } from '../../../../models/raw/ente';
import { Moment } from 'moment';
import { CaricoTerminale } from '../../../../models/raw/carico_terminale';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { And } from '../../../../models/query-lang/logical';
import { Equal } from '../../../../models/query-lang/comparison';
import { Descending } from '../../../../models/query-lang/order';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-carichi-dialog',
  templateUrl: './carichi-dialog.component.html',
  styleUrls: ['./carichi-dialog.component.scss']
})
export class CarichiDialogComponent implements OnInit {
  public carichiLoader = this.dataLoaderProvider.getCarichiLoader();
  public isLoading = false;

  public lavorazioni = [];
  public groupedLetture = [];
  public oldCaricoForm = new FormGroup({
    carico: new FormControl(null, Validators.required)
  });
  public newCaricoForm = null;
  private selectedTab = 1;

  constructor(@Inject(MAT_DIALOG_DATA) public data: PayLoad, private dialogRef: MatDialogRef<CarichiDialogComponent>,
    private snackBar: MatSnackBar, private http: HttpClient, private dataLoaderProvider: DataLoaderProviderService,
    private dialog: MatDialog) {
    for (const lettura of this.data.letture) {
      console.log(this.data);

      this.groupedLetture[lettura['deveui_codice_identificativo_radio_lorawan']] = this.groupedLetture[lettura['deveui_codice_identificativo_radio_lorawan']] || [];
      this.groupedLetture[lettura['deveui_codice_identificativo_radio_lorawan']].push(lettura);
    }
    this.lavorazioni = Object.keys(this.groupedLetture);
  }

  public get isConfirmButtonDisabled(): boolean {
    if (this.isLoading) {
      return true;
    }

    switch (this.selectedTab) {
      case 0:
        return this.lavorazioni.length !== 1 || this.oldCaricoForm.invalid;

      case 1:
        return this.newCaricoForm.invalid;
    }
    return true;
  }

  static formValidator(form: FormGroup) {
    if (form.controls.to.value < form.controls.from.value) {
      return { notValid: true };
    }
    return null;
  }

  ngOnInit(): void {
    if (this.lavorazioni.length === 1) {
      this.carichiLoader = this.carichiLoader.newQuery({
        filters: new And([
          new Equal('caricoterminali_lavorazione_id', this.lavorazioni[0]),
          new Equal('caricoterminali_terminale', this.data.letturista.letturista_numero),
        ]),
        orders: [new Descending('caricoterminali_id')]
      });
      this.carichiLoader.loadNext();
    }

    this.newCaricoForm = new FormGroup({
      description: new FormControl(),
      from: new FormControl(),
      to: new FormControl(),
      date: new FormControl(),
      timeSlot: new FormControl(),
      nota: new FormControl(),
    }, { validators: CarichiDialogComponent.formValidator });

    this.checkFor218();
  }

  onScrollDown(): void {
    this.carichiLoader.loadNext();
  }

  selectedTabChanged(newTab: number): void {
    this.selectedTab = newTab;
  }

  fromDateFilter = (date: Moment) =>
    this.newCaricoForm.controls.to.value === null || date.isSameOrBefore(this.newCaricoForm.controls.to.value);

  toDateFilter = (date: Moment) =>
    this.newCaricoForm.controls.from.value === null || date.isSameOrAfter(this.newCaricoForm.controls.from.value);

  dateChanged(event: MatDatepickerInputEvent<Moment>): void {
    this.newCaricoForm.controls.from.setValue(event.value);
    this.newCaricoForm.controls.to.setValue(event.value);
    this.checkFor218();
  }

  timeSlotChanged(event: MatSelectChange): void {
    this.checkFor218();
  }

  checkFor218(): void {
    if (this.newCaricoForm !== undefined &&
      (this.newCaricoForm.controls.date.value !== null || this.newCaricoForm.controls.timeSlot.value !== null)) {
      this.newCaricoForm.controls.date.setValidators(Validators.required);
      this.newCaricoForm.controls.timeSlot.setValidators(Validators.required);
      this.newCaricoForm.controls.from.disable();
      this.newCaricoForm.controls.to.disable();
    } else {
      this.newCaricoForm.controls.date.clearValidators();
      this.newCaricoForm.controls.timeSlot.clearValidators();
      this.newCaricoForm.controls.from.enable();
      this.newCaricoForm.controls.to.enable();
    }
    this.newCaricoForm.controls.date.updateValueAndValidity();
    this.newCaricoForm.controls.timeSlot.updateValueAndValidity();
  }

  async confirmButtonClicked(): Promise<void> {
    switch (this.selectedTab) {
      case 0:
        // if (await this.validateCaricoAssignment(this.oldCaricoForm.controls.carico.value, this.data.letture)) {
        //   this.closeDialogWithResult(
        //     await this.assignLettureToCarico(this.oldCaricoForm.controls.carico.value, this.data.letture.map(lettura => lettura.id))
        //   );
        // }
        break;

      case 1:
        if (this.newCaricoForm.valid) {
          this.isLoading = true;
          let letture = [];

          const newCarico = await this.http.post<CaricoTerminale>(
            `${environment.baseUrl}api/client/lorawan/carico_terminale/create`,
            {
              caricoterminali_data_in: this.newCaricoForm.controls.from.value.format('YYYY-MM-DD'),
              caricoterminali_data_out: this.newCaricoForm.controls.to.value.format('YYYY-MM-DD'),
              caricoterminali_fascia_oraria: this.newCaricoForm.controls.timeSlot.value,
              caricoterminali_terminale: this.data.letturista.letturista_numero,
              caricoterminali_nota: this.newCaricoForm.controls.nota.value,
              caricoterminali_descrizione: this.newCaricoForm.controls.description.value
            }
          ).toPromise();

          letture = [...letture, ...(await this.assignLettureToCarico(newCarico, this.lavorazioni))];

          if (letture.length > 0) {
            this.closeDialogWithResult(letture);
          }
        }
        break;
    }
  }

  // private async validateCaricoAssignment(carico: CaricoTerminale, letture: Lettura[]): Promise<boolean> {
  //   this.isLoading = true;
  //   const response = await this.http.post<ValidationResponse>(
  //     `${environment.baseUrl}api/client/carichi_terminali/${carico.caricoterminali_id}/validate_assignment`,
  //     letture.map(l => l.id)
  //   ).toPromise();

  //   if (response.errors.length > 0) {
  //     this.isLoading = false;
  //     this.dialog.open(ValidationDialogComponent, {
  //       data: {
  //         type: Type.ERROR,
  //         messages: response.errors,
  //       }
  //     });
  //     return false;
  //   }

  //   if (response.warnings.length > 0) {
  //     this.isLoading = false;
  //     const result = await this.dialog.open(ValidationDialogComponent, {
  //       data: {
  //         type: Type.WARNING,
  //         messages: response.warnings,
  //       }
  //     }).afterClosed().toPromise();
  //     return result === true;
  //   }

  //   return true;
  // }

  private assignLettureToCarico(carico: CaricoTerminale, letture: number[]): Promise<Lettura[]> {
    this.isLoading = true;
    return this.http.post<Lettura[]>(
      `${environment.baseUrl}api/client/lorawan/carico_terminale/${carico.caricoterminali_id}/assign`,
      letture
    ).toPromise();
  }

  private closeDialogWithResult(letture: Lettura[]) {
    this.snackBar.open(`${letture.length} attività assegnate con successo.`, null, { duration: 2000 });
    this.dialogRef.close(letture);
  }
}

export interface PayLoad {
  ente: Ente;
  letture: Lettura[];
  letturista: Letturista;
}
