<div class="input-container" fxLayout="row" fxLayoutAlign="space-between center">
  <input #search matInput
         class="flex1"
         [formControl]="searchFormControl"
         (ngModelChange)="onSearchChanged($event)"
         autocomplete="off"
         [required]="required"
         type="text">

  <mat-icon matSuffix
            *ngIf="!loading"
            (click)="search.value.length > 0 ? clear() : null"
            [style.cursor]="search.value.length > 0 ? 'pointer' : 'default'">
    {{search.value.length > 0 ? 'clear' : 'search'}}
  </mat-icon>

  <mat-progress-spinner mat-suffix *ngIf="loading" diameter="24" mode="indeterminate"></mat-progress-spinner>
</div>
