import { Component } from '@angular/core';
import { BaseTableComponent } from '../../base-table/base-table.component';
import { SortDirection } from '@angular/material/sort';
import { Alarm } from 'src/models/raw/alarm';
import { Router } from '@angular/router';
import { DataLoaderProviderService } from 'src/app/services/data-loader-provider.service';
import * as moment from 'moment';

@Component({
  selector: 'app-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['../../base-table/base-table.component.scss', './monitor.component.scss']
})
export class MonitorComponent extends BaseTableComponent<Alarm> {

  constructor(
    private dataLoader: DataLoaderProviderService,
    private router: Router) {
    super(
      [
        { name: 'matricola', isFilterable: true },
        { name: 'ultimaData', isFilterable: true },
        { name: 'ultimaLettura', isFilterable: true }
      ],
      { active: 'id', direction: 'desc' as SortDirection }
    );
    this.selectOnlyColumns = false;
    this.displayedColumns.push('diff');
    this.loader = this.dataLoader.getLastTrasmissionLoader(50, 'get', null);
  }

  getLast(file: any): string {
    const now = new Date();
    const date = new Date(file.ultimaData);
    return moment(now).diff(moment(date), 'days').toString();
  }

  getDate(date: string): string {
    return new Date(date).toLocaleDateString();
  }
}
