<div class="h100 container">
  <mat-toolbar class="sub-toolbar mat-elevation-z4">
    <app-search-input (search)="onSearchChanged($event)"></app-search-input>

    <!-- <button mat-icon-button id="upload-button" (click)="onUploadButtonClicked()" matTooltip="Nuova importazione">
      <mat-icon>cloud_upload</mat-icon>
    </button> -->
  </mat-toolbar>
  <mat-progress-bar *ngIf="(loader.loading | async)" mode="indeterminate" color="accent"></mat-progress-bar>

  <div infiniteScroll [scrollWindow]="false" (scrolled)="onTableScrolled()" [infiniteScrollDistance]="1"
    class="table-container">
    <mat-table matSort matSortActive="indice_file" matSortDirection="desc" (matSortChange)="onSort($event)"
      [dataSource]="loader" class="mat-elevation-z8">

      <ng-container matColumnDef="matricola">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Matricola</mat-header-cell>
        <mat-cell *matCellDef="let file">{{file.matricola}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="modalita">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Modalità</mat-header-cell>
        <mat-cell *matCellDef="let file">{{file.modalita}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="raw_message">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Messaggio</mat-header-cell>
        <mat-cell *matCellDef="let file">{{file.raw_message}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="button">
        <mat-header-cell mat-sort-header *matHeaderCellDef>Vedi</mat-header-cell>
        <mat-cell (click)="detail(file)" *matCellDef="let file">
          <mat-icon>remove_red_eye</mat-icon>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
      <mat-row *matRowDef="let file; columns: displayedColumns;" class="selectable-row"></mat-row>
    </mat-table>
  </div>
</div>