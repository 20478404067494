import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataLoaderProviderService } from '../../../services/data-loader-provider.service';
import { Letturista } from '../../../../models/raw/letturista';
import { ImmutableDataLoader } from '../../../../models/data-loader';
import { WorkLoad } from '../../../../models/workload';
import { WorkloadService } from '../../../services/workload.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
//import {LatLngLiteral} from '@agm/core';
import { MatDialog } from '@angular/material/dialog';
import { WorkloadsDialogComponent } from '../../dialogs/workloads-dialog/workloads-dialog.component';
import { DaterangeSelectionDialogComponent } from '../../dialogs/daterange-selection-dialog/daterange-selection-dialog.component';
import { FormControl } from '@angular/forms';
import { EnteService } from '../../../services/ente.service';
import { Ente } from '../../../../models/raw/ente';
import { Or } from '../../../../models/query-lang/logical';
import { Equal } from '../../../../models/query-lang/comparison';

@Component({
    selector: 'app-operators-dashboard',
    templateUrl: './operators-dashboard.component.html',
    styleUrls: ['./operators-dashboard.component.scss'],
    providers: [DataLoaderProviderService]
})
export class OperatorsDashboardComponent implements OnInit {
    private querySub: BehaviorSubject<string> = new BehaviorSubject('');

    public operators: Letturista[] = [];
    public workLoads: WorkLoad[] = [];
    public searchFormControl: FormControl = new FormControl('');
    public operatorsLoader: ImmutableDataLoader<Letturista>;

    @Output()
    public operatorClicked: EventEmitter<Letturista>;
    @Input()
    public orderReference: Observable<google.maps.LatLngLiteral>;

    constructor(private data: DataLoaderProviderService,
        private dialog: MatDialog,
        private enteService: EnteService,
        public workService: WorkloadService) {
        this.operatorClicked = new EventEmitter<Letturista>();
    }

    ngOnInit() {
        this.operatorsLoader = this.data.getLetturistiLoader();
        this.onEnteChanged(this.enteService.ente.value);
        this.enteService.ente.subscribe(ente => {
            this.onEnteChanged(ente);
        });
        this.workService.workLoads.subscribe(value => this.workLoads = value);
    }

    private onEnteChanged(ente: Ente) {
        this.operatorsLoader = this.operatorsLoader.newQuery({
            filters: new Or([new Equal('enti', null), new Equal('enti', '')])
        });
        this.operatorsLoader.loadNext();

        combineLatest([this.operatorsLoader.items, this.querySub])
            .subscribe(value => {
                this.operators = value[0].filter(
                    letturista => value[1].length === 0 || letturista.letturista_nome.toLowerCase().includes(value[1].toLowerCase())
                );
            });
    }

    click(operator: Letturista) {
        this.operatorClicked.next(operator);
    }

    letturistiScrolled() {
        this.operatorsLoader.loadNext();
    }

    letturistasDeadlines(workLoads: WorkLoad[], letturista: Letturista): WorkLoad[] {
        return workLoads.filter(workLoad => workLoad.terminale === letturista.letturista_numero);
    }

    loadForTerminaleData(workLoads: WorkLoad[], terminale: Letturista, data: string, fascia: string) {
        const found = this.workLoads.find(value => value.fascia_oraria === fascia &&
            value.data_fine === data &&
            value.terminale === terminale.letturista_numero);
        return found ? found.count : 0;
    }

    dateDaWorkLoads(workLoads: WorkLoad[], terminale: Letturista) {
        const date = [];
        const filtered = workLoads.filter(value => value.terminale === terminale.letturista_numero);
        for (const workLoad of filtered) {
            if (date.indexOf(workLoad.data_fine) < 0) {
                date.push(workLoad.data_fine);
            }
        }
        return date;
    }

    fasceForTerminale(workLoads: WorkLoad[], terminale: Letturista) {
        const fasce = [];
        for (const workLoad of workLoads.filter(value => value.terminale === terminale.letturista_numero)) {
            if (fasce.indexOf(workLoad.fascia_oraria) < 0) {
                fasce.push(workLoad.fascia_oraria);
            }
        }
        return fasce;
    }

    onRangeButtonClicked(): void {
        this.dialog.open(DaterangeSelectionDialogComponent, { panelClass: 'no-padding-dialog' }).afterClosed().subscribe(value => {
            const parsed = { from: null, to: null };

            if (!!value && !!value.start) {
                parsed.from = value.start.format('YYYY-MM-DD');
            }

            if (!!value && !!value.end) {
                parsed.to = value.end.format('YYYY-MM-DD');
            }
            this.workService.loadRange(parsed);
        });
    }

    onCalendarButtonClicked(): void {
        this.dialog.open(WorkloadsDialogComponent, {
            data: {
                operators: this.operators,
                workLoads: this.workLoads,
            }
        });
    }

    onClearButtonClicked(): void {
        this.workService.clear();
    }

    onSearchChanged(value: string): void {
        this.querySub.next(value);
    }
}
