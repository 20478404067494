import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MapComponent } from '../components/pages/map/map.component';
import { PageNotFoundComponent } from '../components/pages/page-not-found/page-not-found.component';
import { LoginComponent } from '../components/pages/login/login.component';
import { AuthGuard } from '../guards/auth.guard';
import { SettingsComponent } from '../components/pages/settings/settings.component';
import { CarichiComponent } from '../components/pages/carichi/carichi.component';
import { LettureComponent } from '../components/pages/letture/letture.component';
import { LetturaComponent } from '../components/pages/lettura/lettura.component';
import { TrackingComponent } from '../components/pages/tracking/tracking.component';
import { SplashComponent } from '../components/pages/splash/splash.component';
import { MagazzinoComponent } from '../components/pages/magazzino/magazzino.component';
import { ReportComponent } from '../components/pages/report/report.component';
import { LavoriComponent } from '../components/pages/lavori/lavori.component';
import { MagazzinoConsumabiliComponent } from '../components/pages/magazzino-consumabili/magazzino-consumabili.component';
import { StatiMagazzinoComponent } from '../components/pages/stati-magazzino/stati-magazzino.component';
import { SwmComponent } from '../components/pages/swm/swm.component';
import { AlarmsComponent } from '../components/pages/alarms/alarms.component';
import { ImportNewComponent } from '../components/pages/import-new/import-new.component';
import { SwmDetailComponent } from '../components/pages/swm-detail/swm-detail.component';
import { ExportComponent } from '../components/pages/export/export.component';
import { FrameComponent } from '../components/pages/frame/frame.component';
import { ChartReportComponent } from '../components/pages/chart-report/chart-report.component';
import { MonitorComponent } from '../components/pages/monitor/last-value/monitor.component';
import { MonitorNeverSentComponent } from '../components/pages/monitor/never-sent/monitor.component';
import { AlarmsMapComponent } from '../components/pages/alarms/map/alarms-map.component';

const routes: Routes = [
  { path: '', redirectTo: '/swm', pathMatch: 'full' },
  { path: 'carichi', component: CarichiComponent, canActivate: [AuthGuard] },
  { path: 'lavori', component: LavoriComponent, canActivate: [AuthGuard] },
  { path: 'letture', component: LettureComponent, canActivate: [AuthGuard] },
  { path: 'letture/:lettura_id', component: LetturaComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  // {path: 'import', component: ImportComponent, canActivate: [AuthGuard]},
  { path: 'map', component: MapComponent, canActivate: [AuthGuard] },
  { path: 'magazzino', component: MagazzinoComponent, canActivate: [AuthGuard] },
  { path: 'magazzino_consumabili', component: MagazzinoConsumabiliComponent, canActivate: [AuthGuard] },
  { path: 'reports', component: ChartReportComponent, canActivate: [AuthGuard] },
  { path: 'reports/:report_id', component: ReportComponent, canActivate: [AuthGuard] },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
  { path: 'stati_magazzino', component: StatiMagazzinoComponent, canActivate: [AuthGuard] },
  { path: 'splash', component: SplashComponent },
  { path: 'tracking', component: TrackingComponent, canActivate: [AuthGuard] },
  { path: 'swm', component: SwmComponent, canActivate: [AuthGuard] },
  { path: 'swm/:id', component: SwmDetailComponent, canActivate: [AuthGuard] },
  { path: 'alarms', component: AlarmsComponent, canActivate: [AuthGuard] },
  { path: 'alarms/:id', component: AlarmsComponent, canActivate: [AuthGuard] },
  { path: 'imports', component: ImportNewComponent, canActivate: [AuthGuard] },
  { path: 'exports', component: ExportComponent, canActivate: [AuthGuard] },
  { path: 'frame', component: FrameComponent, canActivate: [AuthGuard] },
  { path: 'last-value', component: MonitorComponent, canActivate: [AuthGuard] },
  { path: 'never-sent', component: MonitorNeverSentComponent, canActivate: [AuthGuard] },
  { path: 'alarms-map', component: AlarmsMapComponent, canActivate: [AuthGuard] },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
