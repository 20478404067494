import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Lettura} from '../../../../models/raw/lettura';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {Letturista} from '../../../../models/raw/letturista';
import {CaricoTerminale} from '../../../../models/raw/carico_terminale';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-appointment-dialog',
  templateUrl: './appointment-dialog.component.html',
  styleUrls: ['./appointment-dialog.component.scss']
})
export class AppointmentDialogComponent {
  public formGroup = new FormGroup({
    data_ora_appuntamento: new FormControl('',Validators.required),
    nota: new FormControl(),
  });
  public isLoading = new BehaviorSubject<boolean>(false);
  public dataAppuntamentoError = new BehaviorSubject<boolean>(false);

  constructor(@Inject(MAT_DIALOG_DATA) public data: PayLoad,
              private dialogRef: MatDialogRef<AppointmentDialogComponent>,
              private http: HttpClient,
              private snackBar: MatSnackBar) { }

  public async onConfirmButtonClicked(): Promise<void> {
    if (this.formGroup.invalid) {
      return;
    }
    if(!this.checkDataAppuntamento()){
      this.dataAppuntamentoError.next(true);
    }else{
      this.dataAppuntamentoError.next(false);
    }

    return;

    this.isLoading.next(true);

    const carico = await this.http.post<CaricoTerminale>(
      `${environment.baseUrl}api/client/carichi_terminali`,
      {
        caricoterminali_descrizione: `Appuntamento ${this.data.lettura.utente}`,
        caricoterminali_lavorazione_id: this.data.lettura.lavorazione_id,
        caricoterminali_terminale: this.data.letturista.letturista_numero,
        caricoterminali_nota: this.formGroup.value.nota,
        caricoterminali_data_ora_appuntamento: this.formGroup.value.data_ora_appuntamento,
      }
    ).toPromise();

    if (!carico) {
      return;
    }

    const letture = await this.http.post<Lettura[]>(
      `${environment.baseUrl}api/client/carichi_terminali/${carico.caricoterminali_id}/assign`,
      [this.data.lettura.id]
    ).toPromise();

    if (letture) {
      this.snackBar.open('Appuntamento assegnato con successo!', null, {duration: 2000});
      this.dialogRef.close(letture);
    }
  }


  /**
   * Metodo per controllare che la data dell'appuntamento
   * rispetti le 48 ore previste dalla delibera 609
   */
  public checkDataAppuntamento(): boolean {
    const dataAppuntamento = new Date(this.formGroup.controls['data_ora_appuntamento'].value).getDate();
    const today = new Date();

    return today.getDate() + 4 <= dataAppuntamento;

  }
}



export interface PayLoad {
  lettura: Lettura;
  letturista: Letturista;
}
