<h1 mat-dialog-title>Assegnazione articolo: {{data.consumabile.articolo}}</h1>
<mat-dialog-content>
  <mat-table [dataSource]="data.articoli" *ngIf="data.articoli.length > 0; else elseBlock">
    <ng-container matColumnDef="terminale">
      <mat-header-cell *matHeaderCellDef>Terminale</mat-header-cell>
      <mat-cell *matCellDef="let articolo">{{articolo.terminale}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="quantita">
      <mat-header-cell *matHeaderCellDef>Quantita</mat-header-cell>
      <mat-cell *matCellDef="let articolo">{{articolo.quantita}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let articolo">
          <button mat-icon-button (click)="onRemoveTerminalButtonButtonClicked(articolo)" matTooltip="Rimuovi al Terminale"><mat-icon>close</mat-icon></button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <ng-template #elseBlock>
    <p>Articolo non assegnato ad alcun terminale</p>
  </ng-template>

</mat-dialog-content>
<mat-dialog-actions>
  <div>
    <button mat-button mat-dialog-close>Chiudi</button>
  </div>
</mat-dialog-actions>

