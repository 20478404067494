<h1 mat-dialog-title>Nuovo appuntamento</h1>
<form mat-dialog-content [formGroup]="formGroup" fxLayout="column" fxLayoutGap="16px">
  <mat-form-field appearance="outline">
    <mat-label>Data e ora appuntamento</mat-label>
    <input matInput [ngxMatDatetimePicker]="picker" formControlName="data_ora_appuntamento">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
    <mat-error>Questo campo è obbligatorio.</mat-error>
  </mat-form-field>
  <small *ngIf="dataAppuntamentoError | async">La data impostata non è valida per il rispetto della delibera 609</small>

  <mat-form-field>
    <input formControlName="nota" matInput placeholder="Nota">
  </mat-form-field>
</form>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
  <div>
    <button mat-button mat-dialog-close>Annulla</button>
    <button mat-raised-button color="accent" [disabled]="formGroup.invalid" (click)="onConfirmButtonClicked()">Conferma</button>
  </div>

  <mat-spinner *ngIf="isLoading | async" diameter="24"></mat-spinner>
</mat-dialog-actions>
