import { Component } from '@angular/core';
import { BaseTableComponent } from '../base-table/base-table.component';
import { SortDirection } from '@angular/material/sort';
import { Swm } from 'src/models/raw/swm';
import { And, Logical } from 'src/models/query-lang/logical';
import { EnteService } from 'src/app/services/ente.service';
import { ColumnFilter, ColumnFilterType } from '../../partials/filters/filters.component';
import { SwmService } from 'src/app/services/swm.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-swm',
  templateUrl: './swm.component.html',
  styleUrls: ['../base-table/base-table.component.scss', './swm.component.scss']
})
export class SwmComponent extends BaseTableComponent<Swm> {

  public filters: ColumnFilter[] = [
    { column: 'matricola', label: 'Matricola', type: ColumnFilterType.STRING },
    { column: 'pdr', label: 'Pdr', type: ColumnFilterType.STRING },
    { column: 'zona', label: 'Zona', type: ColumnFilterType.STRING },
    { column: 'libro', label: 'Libro', type: ColumnFilterType.STRING },
    { column: 'pagina', label: 'Pagina', type: ColumnFilterType.STRING },
    { column: 'data_installazione_contatore', label: 'Data installazione', type: ColumnFilterType.DATE },
  ];

  public visibleColumns = [
    'matricola',
    'pdr',
    'indirizzo',
    'data_installazione_contatore',
    'tipo_contatore_modello_swm',
    'zona',
    'libro',
    'pagina',
  ];

  public columns = [
    { name: 'id', isFilterable: false },
    { name: 'matricola', isFilterable: true, title: 'Matricola' },
    { name: 'pdr', isFilterable: true, title: 'Pdr' },
    { name: 'indirizzo', isFilterable: true, title: 'Indirizzo' },
    { name: 'data_installazione_contatore', isFilterable: true, title: 'Data installazione contatore' },
    { name: 'tipo_contatore_modello_swm', isFilterable: true, title: 'Tipo' },
    { name: 'zona', isFilterable: true, title: 'Zona' },
    { name: 'libro', isFilterable: true, title: 'Libro' },
    { name: 'pagina', isFilterable: true, title: 'Pagina' },
    { name: 'longitudine', isFilterable: false },
    { name: 'latitudine', isFilterable: false },
    { name: 'updated_at', isFilterable: false },
    { name: 'created_at', isFilterable: false },
    { name: 'comune', isFilterable: false },
    { name: 'localita', isFilterable: false },
    { name: 'codice_utente', isFilterable: false },
    { name: 'utente', isFilterable: false },
    { name: 'civico', isFilterable: false },
    { name: 'barrato', isFilterable: false },
    { name: 'scala', isFilterable: false },
    { name: 'data_lettura_precedente', isFilterable: false },
    { name: 'lettura_precedente', isFilterable: false },
    { name: 'cifre', isFilterable: false },
    { name: 'minimo', isFilterable: false },
    { name: 'massimo', isFilterable: false },
    { name: 'consumo_giornaliero', isFilterable: false },
    { name: 'locazione', isFilterable: false },
    { name: 'acc', isFilterable: false },
    { name: 'ubicazione', isFilterable: false },
    { name: 'note', isFilterable: false },
    { name: 'versione_firmware_swm', isFilterable: false },
    { name: 'chiave_decriptazione_walk_by', isFilterable: false },
    { name: 'appeui_chiavi_lorawan', isFilterable: false },
    { name: 'appkey_chiavi_lorawan', isFilterable: false },
    { name: 'tipologia_manufatto_swm', isFilterable: false },
    { name: 'piano', isFilterable: false },
    { name: 'interno', isFilterable: false },
  ];

  constructor(
    private enteService: EnteService,
    public swmService: SwmService,
    private router: Router) {
    super(
      [
        { name: 'note', isFilterable: true },
        { name: 'versione_firmware_swm', isFilterable: false },
        { name: 'chiave_decriptazione_walk_by', isFilterable: false },
        { name: 'appeui_chiavi_lorawan', isFilterable: false },
        { name: 'appkey_chiavi_lorawan', isFilterable: false },
        { name: 'tipologia_manufatto_swm', isFilterable: false },
        { name: 'data_lettura_precedente', isFilterable: false },
        { name: 'lettura_precedente', isFilterable: false },
        { name: 'cifre', isFilterable: false },
        { name: 'minimo', isFilterable: false },
        { name: 'massimo', isFilterable: false },
        { name: 'consumo_giornaliero', isFilterable: false },
        { name: 'zona', isFilterable: false },
        { name: 'locazione', isFilterable: false },
        { name: 'acc', isFilterable: false },
        { name: 'ubicazione', isFilterable: false },
        { name: 'piano', isFilterable: false },
        { name: 'interno', isFilterable: false },
        { name: 'barrato', isFilterable: false },
        { name: 'scala', isFilterable: false },
        { name: 'utente', isFilterable: false },
        { name: 'civico', isFilterable: false },
        { name: 'comune', isFilterable: false },
        { name: 'localita', isFilterable: false },
        { name: 'codice_utente', isFilterable: false },
        { name: 'longitudine', isFilterable: false },
        { name: 'latitudine', isFilterable: false },
        { name: 'updated_at', isFilterable: false },
        { name: 'created_at', isFilterable: false },
        { name: 'id', isFilterable: false },
        { name: 'matricola', isFilterable: true },
        { name: 'pdr', isFilterable: false },
        { name: 'indirizzo', isFilterable: false },
        { name: 'data_installazione_contatore', isFilterable: false },
        { name: 'tipo_contatore_modello_swm', isFilterable: false },
        { name: 'libro', isFilterable: false },
        { name: 'pagina', isFilterable: false },
      ],
      { active: 'id', direction: 'desc' as SortDirection }
    );

    this.selectOnlyColumns = false;
    this.loader = this.swmService.loader;
  }

  public onFiltersUpdated(filters: Logical): void {
    this.baseQuerySubject.next(new And([...filters.args]));
    this.loader.loadNext();
  }

  public onSelected(lettura: Swm): void {
    this.swmService.loader = this.loader;
    this.router.navigate(['swm', lettura['deveui_codice_identificativo_radio_lorawan']]);
  }

  public isToShow(val: string): boolean {
    return this.visibleColumns.includes(val);
  }

  getDate(col: any, name: string) {
    if (name == 'data_installazione_contatore') return new Date(col).toLocaleDateString();
    else return col;
  }
}
