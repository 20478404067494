import { Component } from '@angular/core';
import { BaseTableComponent } from '../../base-table/base-table.component';
import { SortDirection } from '@angular/material/sort';
import { Alarm } from 'src/models/raw/alarm';
import { Router } from '@angular/router';
import { DataLoaderProviderService } from 'src/app/services/data-loader-provider.service';

@Component({
  selector: 'app-monitor-never',
  templateUrl: './monitor.component.html',
  styleUrls: ['../../base-table/base-table.component.scss', './monitor.component.scss']
})
export class MonitorNeverSentComponent extends BaseTableComponent<Alarm> {

  constructor(
    private dataLoader: DataLoaderProviderService,
    private router: Router) {
    super(
      [
        { name: 'tipo_contatore_modello_swm', isFilterable: true },
        { name: 'latitudine', isFilterable: true },
        { name: 'longitudine', isFilterable: true },
        { name: 'matricola', isFilterable: true },
        { name: 'comune', isFilterable: true },
        { name: 'localita', isFilterable: true },
        { name: 'codice_utente', isFilterable: true },
        { name: 'pdr', isFilterable: true },
        { name: 'data_installazione_contatore', isFilterable: true }
      ],
      { active: 'id', direction: 'desc' as SortDirection }
    );
    this.selectOnlyColumns = false;
    this.loader = this.dataLoader.getNeverSentLoader(50, 'get', null);
  }

  getDate(date: string): string {
    return new Date(date).toLocaleDateString();
  }
}
