import {Component} from '@angular/core';
import {BaseTableComponent} from '../base-table/base-table.component';
import {Magazzino} from '../../../../models/raw/magazzino';
import {SortDirection} from '@angular/material/sort';
import {DataLoaderProviderService} from '../../../services/data-loader-provider.service';
import {MatDialog} from '@angular/material/dialog';
import {MagazzinoAssociationDialogComponent} from '../../dialogs/magazzino-association-dialog/magazzino-association-dialog.component';
import {EnteService} from '../../../services/ente.service';
import {And, Logical} from '../../../../models/query-lang/logical';
import {Equal} from '../../../../models/query-lang/comparison';
import {Ente} from '../../../../models/raw/ente';
import {ColumnFilter, ColumnFilterType} from '../../partials/filters/filters.component';
import {FormFieldType, SimpleFormDialogComponent} from '../../dialogs/simple-form-dialog/simple-form-dialog.component';
import {LoadingOverlayService} from '../../../services/loading-overlay.service';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {StatiMagazzinoEntityDialogComponent} from '../../dialogs/entity-input-dialog/stati-magazzino-entity-dialog.component';
import {TerminaleAssociationCountComponent} from '../../dialogs/terminale-association-count/terminale-association-count.component';
import {SimpleAlertDialogComponent} from '../../dialogs/simple-alert-dialog/simple-alert-dialog.component';
import {Lettura} from '../../../../models/raw/lettura';

@Component({
  selector: 'app-magazzino',
  templateUrl: './magazzino.component.html',
  styleUrls: ['../base-table/base-table.component.scss', './magazzino.component.scss']
})
export class MagazzinoComponent extends BaseTableComponent<Magazzino> {

  public filters: ColumnFilter[] = [
    {column: 'id', label: 'ID', type: ColumnFilterType.NUMBER},
    {column: 'articolo', label: 'Articolo', type: ColumnFilterType.STRING},
    {column: 'terminale', label: 'Terminale', type: ColumnFilterType.NUMBER},
    {column: 'stato.stato', label: 'Stato', type: ColumnFilterType.STRING},
    {column: 'descrizione', label: 'Descrizione', type: ColumnFilterType.STRING},
    {column: 'matricola', label: 'Matricola', type: ColumnFilterType.STRING},
    {column: 'bancale', label: 'Bancale', type: ColumnFilterType.STRING},
    {column: 'scatola', label: 'Scatola', type: ColumnFilterType.STRING},
  ];

  constructor(private dataLoaderProvider: DataLoaderProviderService,
              private dialog: MatDialog, private enteService: EnteService,
              private loadingOverlay: LoadingOverlayService,
              private http: HttpClient,
              private snackBar: MatSnackBar) {
    super(
      [
        {name: 'id', isFilterable: false},
        {name: 'articolo', isFilterable: true},
        {name: 'terminale', isFilterable: false},
        {name: 'stato', isFilterable: false},
        {name: 'descrizione', isFilterable: false},
        {name: 'matricola', isFilterable: true},
        {name: 'bancale', isFilterable: true},
        {name: 'scatola', isFilterable: true},
      ],
      {active: 'id', direction: 'asc' as SortDirection}
    );
    this.selectOnlyColumns = false;
    this.loader = this.dataLoaderProvider.getMagazzinoLoader();
    this.displayedColumns.push('actions');

    this.onEnteChanged(this.enteService.ente.value);
    this.enteService.ente.subscribe(ente => {
      this.onEnteChanged(ente);
    });
  }

  private onEnteChanged(ente: Ente) {
    this.baseQuerySubject.next(new And([new Equal('ente_id', ente.ente_id)]));
  }

  public onFiltersUpdated(filters: Logical): void {
    this.baseQuerySubject.next(new And([...filters.args, new Equal('ente_id', this.enteService.ente.value.ente_id)]));
    this.loader.loadNext();
  }

  public async onAssociateButtonClicked(): Promise<void> {
    const result = await this.dialog.open(MagazzinoAssociationDialogComponent).afterClosed().toPromise();
    if (result) {
      this.loader = this.loader.newQuery(this.loader.currentQuery);
      this.loader.loadNext();
    }
  }

  public async onTerminalAssociationButtonClicked(): Promise<void> {
    const terminale = await this.dialog.open(SimpleFormDialogComponent, {
      data: {
        fields: [
          {
            column: 'terminale',
            label: 'Numero Terminale',
            type: FormFieldType.NUMBER,
            required: true
          }
        ]
      }
    }).afterClosed().toPromise();
    if (terminale) {
      try {
        this.loadingOverlay.show();
        const result = await this.http.post(`${environment.baseUrl}api/client/magazzino/terminale_association_count`,
          {terminale: terminale.terminale, ente: this.enteService.ente.value.ente_id}).toPromise();
        this.loadingOverlay.hide();
        await this.dialog.open(TerminaleAssociationCountComponent, {
          data: {
            terminale: terminale.terminale,
            articoli: result
          }
        }).afterClosed().toPromise();
      } catch (e) {
        this.snackBar.open('Impossibili completare l\' operazione del terminale selezionato. Riprova più tardi.', null, {duration: 5000});
        this.loadingOverlay.hide();
      }
    }
  }

  public async onTerminalAssociateButtonButtonClicked(magazzino: Magazzino): Promise<void> {
    const result = await this.dialog.open(SimpleFormDialogComponent, {
      data: {
        fields: [
          {
            column: 'terminale',
            label: 'Numero Terminale',
            type: FormFieldType.NUMBER,
            required: true
          }
        ]
      }
    }).afterClosed().toPromise();
    if (result) {
      this.loadingOverlay.show();

      try {
        await this.http.post(`${environment.baseUrl}api/client/magazzino/associate_terminale`, {
          code_id: magazzino.id,
          terminale: result.terminale
        }).toPromise();
        this.snackBar.open('Elemento aggiornato con successo!', null, {duration: 5000});

      } catch (e) {
        this.snackBar.open('Impossibili completare l\' operazione del codice inserito. Riprova più tardi.', null, {duration: 5000});
      }
      this.loadingOverlay.hide();

      this.loader = this.loader.newQuery(this.loader.currentQuery);
      this.loader.loadNext();
    }
  }

  public async onChangeStatusButtonButtonClicked(magazzino: Magazzino): Promise<void> {
    const result = await this.dialog.open(StatiMagazzinoEntityDialogComponent, {
      data: {
        stato: magazzino.stato,
        ente_id: this.enteService.ente.value.ente_id
      }
    }).afterClosed().toPromise();
    if (result) {
      this.loadingOverlay.show();
      try {
        await this.http.post(`${environment.baseUrl}api/client/magazzino/change_status`, {
          code_id: magazzino.id,
          stato_id: result.id
        }).toPromise();
        this.snackBar.open('Elemento aggiornato con successo!', null, {duration: 5000});

      } catch (e) {
        this.snackBar.open('Impossibili completare l\' operazione del codice inserito. Riprova più tardi.', null, {duration: 5000});
      }
      this.loadingOverlay.hide();

      this.loader = this.loader.newQuery(this.loader.currentQuery);
      this.loader.loadNext();
    }
  }

  public async onRemoveStatusClicked(magazzino: Magazzino): Promise<void> {
    const result = await this.dialog.open(SimpleAlertDialogComponent, {
      data: {
        title: 'Attenzione!',
        content: 'Sei sicuro di voler rimuovere lo stato a questo articolo?',
      }
    }).afterClosed().toPromise();

    if (result) {
      try {
        this.loadingOverlay.show();
        await this.http.post<Lettura>(`${environment.baseUrl}api/client/magazzino/${magazzino.id}/remove_status`, null)
          .toPromise();
        this.snackBar.open('Stato rimosso con successo!', null, {duration: 5000});
      } catch (e) {
        this.snackBar.open('Impossibili completare l\' operazione dell\' articolo selezionato. Riprova più tardi.', null, {duration: 5000});
      }
      this.loadingOverlay.hide();

      this.loader = this.loader.newQuery(this.loader.currentQuery);
      this.loader.loadNext();
    }

  }
}
