import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseTableComponent } from '../base-table/base-table.component';
import { SortDirection } from '@angular/material/sort';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ChartReportService } from 'src/app/services/chart-report.service';
import { Chart, BarController, LinearScale, CategoryScale, BarElement } from 'chart.js';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
Chart.register(BarController, LinearScale, CategoryScale, BarElement);

@Component({
  selector: 'app-import',
  templateUrl: './chart-report.component.html',
  styleUrls: ['../base-table/base-table.component.scss', './chart-report.component.scss']
})
export class ChartReportComponent extends BaseTableComponent<any> implements OnInit {

  @ViewChild('chartCanvas') chartCanvas: ElementRef;
  matricolaOptions = [];
  form: FormGroup;
  isLoading = false;
  matricole: { matricola: string, deveui_codice_identificativo_radio_lorawan: string }[] = [];
  private chartInstance: Chart;
  loading = false;
  dataSource = new MatTableDataSource([]);
  ignoreValueChanges: boolean;

  constructor(
    private _formBuilder: FormBuilder,
    private _service: ChartReportService) {
    super(
      [
        { name: 'matricola', isFilterable: true },
        { name: 'readings', isFilterable: true },
      ],
      { active: 'devEui', direction: 'desc' as SortDirection }
    );

    this.selectOnlyColumns = false;
  }

  async ngOnInit() {
    this.form = this._formBuilder.group({
      devEuis: [[]],
      zona: [null],
      libro: [null],
      pagina: [null],
      localita: [null],
      aggregate: [null],
      dateRange: [null],
      reportType: [null],
      dateTo: [new Date()]
    });

    this.form.controls['aggregate'].disable();
    this.form.updateValueAndValidity();

    const matricole = await this._service.getMatricole();
    this.matricole = matricole;

    this.ignoreValueChanges = false;
    this.form.valueChanges.subscribe(() => {
      if (!this.ignoreValueChanges) {
        this.updateFieldStatus();
      }
    });

    this.updateFieldStatus();
  }

  async sendData() {
    if (!this.form.valid) return;

    if (this.chartInstance) {
      this.chartInstance.destroy();
    }

    const period = this.form.value.dateRange;
    const dateTo = moment(this.form.value.dateTo).endOf('day');

    let dateFrom;

    switch (period) {
      case 'daily':
        dateFrom = moment(this.form.value.dateTo).startOf('day');
        break;
      case 'weekly':
        dateFrom = moment(this.form.value.dateTo).subtract(1, 'weeks').startOf('day');
        break;
      case 'monthly':
        dateFrom = moment(this.form.value.dateTo).subtract(1, 'months').add(1, 'day').startOf('day');
        break;
    }
    console.log(dateTo.toDate(), period, dateFrom.toDate());
    this.loading = true;
    try {
      const charts = await this._service.getChart({ ...this.form.value, dateFrom: dateFrom.toDate(), dateTo: dateTo.toDate() });
      const table = await this._service.getTable({ ...this.form.value, dateFrom: dateFrom.toDate(), dateTo: dateTo.toDate() });
      this.dataSource.data = table.data;

      this._makeChart(charts);
    } catch (e) {
      this.loading = false;
    }

    this.loading = false;
  }

  getJson(json: JSON): string {
    return JSON.stringify(json).slice(1, -1).split(',').join('\n');
  }

  updateFieldStatus() {
    this.ignoreValueChanges = true;
    const devEuis = this.form.get('devEuis').value;
    const isMatricolaSelected = devEuis && devEuis.length > 0;
    const { zona, libro, pagina, localita } = this.form.value;
    const isOtherFieldSelected = zona || libro || pagina || localita;

    if (isMatricolaSelected) {
      this.disableFields(['zona', 'libro', 'pagina', 'localita']);
    } else if (isOtherFieldSelected) {
      this.form.get('devEuis').disable({ emitEvent: false });
    } else {
      this.enableFields(['zona', 'libro', 'pagina', 'localita', 'devEuis']);
    }

    this.ignoreValueChanges = false;
  }

  disableFields(fields: string[]) {
    fields.forEach(field => {
      this.form.controls[field].disable();
    });
  }

  enableFields(fields: string[]) {
    fields.forEach(field => {
      this.form.controls[field].enable();
    });
  }

  private _makeChart(response: any) {
    if (response && response[0]) {
      if (this.chartInstance) {
        this.chartInstance.destroy();
      }

      const labels = Object.keys(response[0].readings);
      const datasets = response.map(device => {
        return {
          label: device.matricola,
          data: Object.values(device.readings),
          fill: false,
          borderColor: this._getRandomColor(),
          tension: 0.1
        };
      });

      const ctx = this.chartCanvas.nativeElement.getContext('2d');
      this.chartInstance = new Chart(ctx, {
        type: this.form.value.reportType === 'lettura' ? 'line' : 'bar',  // Cambia il tipo in 'line' per un grafico a linee
        data: {
          labels: labels,
          datasets: datasets
        },
        options: {
          responsive: true
        }
      });
    }
  }

  private _getRandomColor() {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return `rgb(${r}, ${g}, ${b})`;
  }
}
