import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Report} from '../../../../models/raw/report';
import {ReportsService} from '../../../services/reports.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  public report: Report;

  constructor(private reportsService: ReportsService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    const id = parseInt(this.route.snapshot.paramMap.get('report_id'), 10);
    this.report = this.reportsService.loader.items.getValue().find((report) => report.id === id);
  }
}
