<h1 mat-dialog-title>Geolocalizzazione Manuale</h1>
<mat-dialog-content>
    <form [formGroup]="formControl">
        <mat-form-field class="halfish">
            <input matInput type="number" step="any" formControlName="latitude" placeholder="Latitudine" required>
        </mat-form-field>
        <mat-form-field class="halfish">
            <input matInput type="number" step="any" formControlName="longitude" placeholder="Longitudine" required>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button
            color="accent"
            [disabled]="formControl.invalid"
            (click)="onSubmit()">Ok</button>
    <mat-spinner *ngIf="isLoading" diameter="24" color="accent"></mat-spinner>
</mat-dialog-actions>
