export abstract class Comparison {
    field: string;
    value: string | number | Date | null;
    type: ComparisonOperators;

    constructor(field: string,
                value: string | number | Date | null) {
        this.field = field;
        this.value = value;
    }
}

export class Greater extends Comparison {
    type = ComparisonOperators.GREATER;
}

export class GreaterEqual extends Comparison {
    type = ComparisonOperators.GREATER_EQUAL;
}

export class Lesser extends Comparison {
    type = ComparisonOperators.LESSER;
}

export class LesserEqual extends Comparison {
    type = ComparisonOperators.LESSER_EQUAL;
}

export class Like extends Comparison {
    type = ComparisonOperators.LIKE;
}

export class Equal extends Comparison {
    type = ComparisonOperators.EQUAL;
}

export class NotEqual extends Comparison {
    type = ComparisonOperators.NOT_EQUAL;
}

enum ComparisonOperators {
    GREATER = '>',
    LESSER = '<',
    GREATER_EQUAL = '>=',
    LESSER_EQUAL = '<=',
    EQUAL = '=',
    NOT_EQUAL = '!=',
    LIKE = 'like',
}
