import {Injectable} from '@angular/core';
import {ImmutableDataLoader} from '../../models/data-loader';
import {DataLoaderProviderService} from './data-loader-provider.service';
import {Report} from '../../models/raw/report';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  public loader: ImmutableDataLoader<Report>;

  constructor(private dataLoaderProvider: DataLoaderProviderService) {
    this.loader = this.dataLoaderProvider.getReportsLoader();
  }
}
