import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {WorkLoad} from '../../../../models/workload';
import {Letturista} from '../../../../models/raw/letturista';
import {DataLoaderProviderService} from '../../../services/data-loader-provider.service';
import {And} from '../../../../models/query-lang/logical';
import {GreaterEqual, LesserEqual} from '../../../../models/query-lang/comparison';
import {LoaderStatus} from '../../../../models/data-loader';
import {Lettura} from '../../../../models/raw/lettura';
import * as moment from 'moment';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-workloads-dialog',
  templateUrl: './workloads-dialog.component.html',
  styleUrls: ['./workloads-dialog.component.scss']
})
export class WorkloadsDialogComponent implements OnInit {
  private appointments: Lettura[] = [];

  public dates = new Map<string, string[]>();
  public operators: Letturista[] = [];
  public operatorsData = new Map<Letturista, OperatorData[]>();
  public isLoading = new BehaviorSubject<boolean>(true);

  constructor(@Inject(MAT_DIALOG_DATA) public data: PayLoad, private dataLoaderProvider: DataLoaderProviderService) { }

  ngOnInit(): void {
    let firstDate;
    let lastDate;
    console.log(this.data.workLoads);
    for(const workLoad of this.data.workLoads) {
      if (!firstDate) {
        firstDate = workLoad.data_fine;
      }
      if (!lastDate || workLoad.data_fine > lastDate) {
        lastDate = workLoad.data_fine;
      }

      if (!this.dates.has(workLoad.data_fine)) {
        this.dates.set(workLoad.data_fine, []);
      }
      if (!this.dates.get(workLoad.data_fine).includes(workLoad.fascia_oraria)) {
        this.dates.get(workLoad.data_fine).push(workLoad.fascia_oraria);
      }
      const operator = this.data.operators.find(entry => entry.letturista_numero === workLoad.terminale);
      if (operator && !this.operators.includes(operator)) {
        this.operators.push(operator);
      }
    }
    this.operators.sort((a, b) => a.letturista_nome.localeCompare(b.letturista_nome));
    for (const operator of this.operators) {
      this.operatorsData.set(operator, []);
      for (const [date, timeSlots] of this.dates.entries()) {
        console.log(date, timeSlots);
        for (const timeSlot of timeSlots) {
          const workLoad = this.data.workLoads.find(entry => entry.terminale === operator.letturista_numero
                                                                   && entry.data_fine === date
                                                                   && entry.fascia_oraria === timeSlot);
          console.log(workLoad, operator.letturista_numero, timeSlot, date);
          const operatorData: OperatorData = {
            letture: workLoad?.count ?? 0,
            date: date,
            timeSlot: timeSlot,
          };
          this.operatorsData.get(operator).push(operatorData);
        }
        this.operatorsData.get(operator).sort((a, b) => {
          let result = a.date.localeCompare(b.date);
          if (result === 0) {
            result = a.timeSlot.localeCompare(b.timeSlot);
          }
          return result;
        });
      }
    }
    console.log(this.operatorsData);

    const lettureLoader = this.dataLoaderProvider.getLettureLoader(500).newQuery({
      filters: new And([
        new GreaterEqual('data_ora_appuntamento', `${firstDate} 00:00:00`),
        new LesserEqual('data_ora_appuntamento', `${lastDate} 23:59:59`)
      ])
    });
    lettureLoader.status.subscribe(status => {
      switch (status) {
        case LoaderStatus.IDLE:
          lettureLoader.loadNext();
          break;

        case LoaderStatus.DONE:
          this.isLoading.next(false);
          this.appointments = lettureLoader.items.value;
          break;
      }
    });
  }

  public getAppointments(operator: number, date: string, timeSlot: string): number {
    const from = moment(`${date} ${timeSlot.substr(0, 2)}:${timeSlot.substr(2, 2)}`);
    const to = moment(`${date} ${timeSlot.substr(5, 2)}:${timeSlot.substr(7, 2)}`);
    return this.appointments.filter(lettura => {
      const date = moment(lettura.data_ora_appuntamento);
      return lettura.terminale === operator && date.isSameOrAfter(from) && date.isSameOrBefore(to);
    }).length;
  }
}

interface OperatorData {
  letture: number;
  date: string;
  timeSlot: string;
}

export interface PayLoad {
  operators: Letturista[];
  workLoads: WorkLoad[];
}
