<mat-dialog-content>

  <mat-form-field class="w100" appearance="outline">
    <mat-select style="background: red;" (selectionChange)="onNameChanged($event.value)">
      <mat-option value="lorawan-esportazione-scada">Scada</mat-option>
      <mat-option value="lorawan-esportazione-sit">Sit</mat-option>
      <mat-option value="lorawan-esportazione-bilancio-idrico">Bilancio idrico</mat-option>
      <mat-option value="lorawan-esportazione-fatturazione">Fatturazione</mat-option>
      <mat-option value="lorawan-esportazione-allarmi">Allarmi</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="w100" appearance="outline">
    <input matInput placeholder="Zona">
  </mat-form-field>

  <mat-form-field class="w100" appearance="outline">
    <input matInput placeholder="Libro">
  </mat-form-field>

  <mat-form-field class="w100" appearance="outline">
    <input matInput placeholder="Pagina">
  </mat-form-field>

  <div>
    <!-- <app-file-dropper (onFileSelected)="onFileAdded($event)" label="Carica file"></app-file-dropper> -->
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between">
  <mat-progress-spinner color="accent" mode="indeterminate" [diameter]="24"
    [style.visibility]="isLoading ? 'visible' : 'hidden'"></mat-progress-spinner>
  <div>
    <button mat-button mat-dialog-close>Annulla</button>
    <button mat-raised-button (click)="onConfirmButtonClicked()" [disabled]="isLoading" color="accent">
      Conferma
    </button>
  </div>
</mat-dialog-actions>