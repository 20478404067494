//import {LatLngLiteral} from '@agm/core';
import { WorkLoad } from '../../models/workload';
import { Letturista } from '../../models/raw/letturista';
import { computeDistanceBetween } from 'spherical-geometry-js';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'geoOrder'
})
export class GeoOrderPipe implements PipeTransform {

    transform(value: Letturista[], center: google.maps.LatLngLiteral, workLoads: WorkLoad[]): Letturista[] {
        if (!!center && !!center.lng && !!center.lat && !!workLoads) {

            const minDistances = {};

            for (const letturista of value) {
                const filteredWorkloads = workLoads.filter(load => load.terminale === letturista.letturista_numero);
                for (const workLoad of filteredWorkloads) {
                    if (minDistances[letturista.letturista_numero]) {
                        if (minDistances[letturista.letturista_numero] > computeDistanceBetween(workLoad, center)) {
                            minDistances[letturista.letturista_numero] = computeDistanceBetween(workLoad, center);
                        }
                    } else {
                        minDistances[letturista.letturista_numero] = computeDistanceBetween(workLoad, center);
                    }
                }
            }

            return value.sort((a, b) => {
                if (!!minDistances[a.letturista_numero] && !!minDistances[b.letturista_numero]) {
                    return minDistances[a.letturista_numero] >= minDistances[b.letturista_numero] ? 1 : -1;
                } else {
                    if (minDistances[a.letturista_numero]) {
                        return -1;
                    }
                    if (minDistances[b.letturista_numero]) {
                        return 1;
                    }
                }
            });
        }
        return value.sort((a, b) => {
            return a.letturista_nome.localeCompare(b.letturista_nome);
        });
    }

}
