import { Component } from '@angular/core';
import { BaseTableComponent } from '../base-table/base-table.component';
import { SortDirection } from '@angular/material/sort';
import { DataLoaderProviderService } from 'src/app/services/data-loader-provider.service';
import { MatDialog } from '@angular/material/dialog';
import { CaricoFile } from 'src/models/raw/carico_file';
import { CaricofileDialogNewComponent } from '../../dialogs/caricofile-dialog-new/caricofile-dialog-new.component';
import { Import } from 'src/models/raw/import';

@Component({
  selector: 'app-import-new',
  templateUrl: './import-new.component.html',
  styleUrls: ['../base-table/base-table.component.scss', './import-new.component.scss']
})
export class ImportNewComponent extends BaseTableComponent<Import> {

  constructor(
    private dataLoaderProvider: DataLoaderProviderService,
    private dialog: MatDialog) {
    super(
      [
        { name: 'started_at', isFilterable: true },
        { name: 'finished_at', isFilterable: true },
        { name: 'nome_file', isFilterable: true },
        { name: 'tipologia_file', isFilterable: true },
        { name: 'stato_importazione', isFilterable: true },
        { name: 'error', isFilterable: true },
        { name: 'warning', isFilterable: true },
      ],
      { active: 'started_at', direction: 'desc' as SortDirection }
    );
    this.loader = this.dataLoaderProvider.getImportsLoader();
  }

  public onUploadButtonClicked() {
    this.dialog.open(CaricofileDialogNewComponent, {}).afterClosed().toPromise().then((caricoFile: CaricoFile) => {
      if (caricoFile) {
        this.loader = this.loader.newQuery(this.loader.currentQuery);
        this.loader.loadNext();
      }
    });
  }

  showMore(file: Import) {
    if (file.error) alert(file.error);
    if (file.warning) alert(file.warning);
  }

  getDate(col: any) {
    return new Date(col).toLocaleDateString();
  }
}
