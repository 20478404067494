import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { WorkLoad } from '../../models/workload';
import { Range } from '../../models/range';
import { environment } from '../../environments/environment';
import * as moment from 'moment';
import { EnteService } from './ente.service';

@Injectable({
  providedIn: 'root'
})
export class WorkloadService {
  public loading = false;
  workLoads: BehaviorSubject<WorkLoad[]> = new BehaviorSubject([]);

  private static decorateWorkload(workLoad: WorkLoad): void {
    const date = moment(workLoad.data_fine, 'YYYY-MM-DD', true);
    switch (date.weekday()) {
      case 1: // lunedì
        workLoad.color = '#FDA428';
        workLoad.markerIcon = `assets/markers/workloads/monday/marker_${workLoad.terminale}.png`;
        break;
      case 2: // martedì
        workLoad.color = '#FFFF38';
        workLoad.markerIcon = `assets/markers/workloads/tuesday/marker_${workLoad.terminale}.png`;
        break;
      case 3: // mercoledì
        workLoad.color = '#2DFEFD';
        workLoad.markerIcon = `assets/markers/workloads/wednesday/marker_${workLoad.terminale}.png`;
        break;
      case 4: // giovedì
        workLoad.color = '#FEB7C2';
        workLoad.markerIcon = `assets/markers/workloads/thursday/marker_${workLoad.terminale}.png`;
        break;
      case 5: // venerdì
        workLoad.color = '#FC28FC';
        workLoad.markerIcon = `assets/markers/workloads/friday/marker_${workLoad.terminale}.png`;
        break;
      case 6: // sabato
        workLoad.color = '#A9A9A9';
        workLoad.markerIcon = `assets/markers/workloads/saturday/marker_${workLoad.terminale}.png`;
        break;
      default: // domenica o resto
        workLoad.color = '#78909C';
        workLoad.markerIcon = `assets/markers/workloads/sunday/marker_${workLoad.terminale}.png`;
        break;
    }
  }

  constructor(private enteService: EnteService, private http: HttpClient) {
    this.enteService.ente.subscribe(() => {
      this.clear();
    });
  }

  public loadRange(range: Range): void {
    if (!!range && !!range.from && !!range.to) {
      this.loading = true;

      const body = {
        from: range.from,
        to: range.to,
        ente: this.enteService.ente.value.ente_id
      };
      this.http.post<WorkLoad[]>(`${environment.baseUrl}api/client/letturisti/load`, body)
        .toPromise()
        .then(value => {
          for (const workload of value) {
            WorkloadService.decorateWorkload(workload);
          }
          this.workLoads.next(value);
          this.loading = false;
        });
    }
  }

  public clear(): void {
    this.workLoads.next([]);
  }
}
