import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';

@Component({
  selector: 'app-file-dropper',
  templateUrl: './file-dropper.component.html',
  styleUrls: ['./file-dropper.component.scss']
})
export class FileDropperComponent {
  private file: File;

  @Input()
  public label: string;

  @Output()
  public onFileSelected: EventEmitter<File> = new EventEmitter();

  public onFileDropped(files: NgxFileDropEntry[]): void {
    if (files[0].fileEntry.isFile) {
      (files[0].fileEntry as FileSystemFileEntry).file(file => {
        this.file = file;
        this.onFileSelected.emit(this.file);
      });
    }
  }
}
