<h1 mat-dialog-title>Seleziona uno stato</h1>
<mat-dialog-content fxLayout="column">

  <mat-form-field>
    <mat-label>Stato</mat-label>
    <mat-select [(value)]="stato" [compareWith]="compareStato">
      <mat-option *ngFor="let stato of stati" [value]="stato">{{stato?.stato}}</mat-option>
    </mat-select>
    <mat-progress-spinner *ngIf="dataLoader.loading | async" matSuffix diameter="16" mode="indeterminate"></mat-progress-spinner>
  </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-dialog-close>Annulla</button>
  <button mat-raised-button color="accent" (click)="onConfirmButtonClicked()" [disabled]="!stato || (dataLoader.loading | async)">
    Conferma
  </button>
</mat-dialog-actions>
