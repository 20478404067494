import { Injectable } from '@angular/core';
import { Lavoro, LavoroStato, LavoroTipo } from '../../models/raw/lavoro';
import { EchoService } from 'ngx-laravel-echo';
import { UserService } from './user.service';
import { SimpleAlertDialogComponent } from '../components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { environment } from '../../environments/environment';
import { LoadingOverlayService } from './loading-overlay.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { FileSaverService } from 'ngx-filesaver';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LavoriService {
  private subscription: Subscription;

  constructor(private dialog: MatDialog,
    private echoService: EchoService,
    private fileSaver: FileSaverService,
    private http: HttpClient,
    private loadingOverlay: LoadingOverlayService,
    private snackBar: MatSnackBar,
    private userService: UserService) {
  }

  public subscribe(): void {
    this.subscription = this.echoService.listen(`user.${this.userService.user.id}`, '.lavori').subscribe(event => {
      const lavoro: Lavoro = event.lavoro;
      if (lavoro.stato === LavoroStato.COMPLETATO || lavoro.stato === LavoroStato.ERRORE) {
        const snackBar = this.snackBar.open(
          `Elaborazione operazione "${lavoro.nome}" ${lavoro.stato === LavoroStato.COMPLETATO ? 'completata' : 'fallita'}!`,
          'Apri',
          { duration: 5000 }
        );
        const snackBarSubscription = snackBar.onAction().subscribe(() => {
          this.onLavoroSelected(lavoro);
        });
        snackBar.afterDismissed().toPromise().then(() => {
          snackBarSubscription.unsubscribe();
        });
      }
    });
  }

  public unsubscribe(): void {
    this.subscription.unsubscribe();
  }

  public async onLavoroSelected(lavoro: Lavoro): Promise<void> {
    if (lavoro.stato === LavoroStato.ERRORE && lavoro.dati?.errore) {
      this.dialog.open(SimpleAlertDialogComponent, {
        data: {
          title: 'Dettaglio errore',
          content: lavoro.dati.errore,
        }
      });
    }
    if (lavoro.stato !== LavoroStato.COMPLETATO) {
      return;
    }

    switch (lavoro.tipo) {
      case LavoroTipo.TIPO_ESPORTAZIONE:
        await this.downloadExport(lavoro.dati.file);
        break;
    }
  }

  private async downloadExport(fileName: string): Promise<void> {
    const answer = await this.dialog.open(SimpleAlertDialogComponent, {
      data: {
        title: 'Download file',
        content: `Vuoi scaricare il file ${fileName}?`,
      }
    }).afterClosed().toPromise();
    if (answer) {
      try {
        this.loadingOverlay.show();
        const response = await this.http.get(`${environment.baseUrl}api/client/files/exports/${fileName}`, {
          responseType: 'blob'
        }).toPromise();
        this.fileSaver.save(new Blob([response]), fileName);
      } catch (e) {
        this.snackBar.open(`Errore durante il download del file ${fileName}. Riprova più tardi`, null, { duration: 5000 });
      } finally {
        this.loadingOverlay.hide();
      }
    }
  }
}
