import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SwmDetailService {

    constructor(
        private _http: HttpClient) { }

    getSwm(devEui: string) {
        return this._http.post<any>(`${environment.baseUrl}api/client/lorawan/anagrafica`, { devEui })
            .toPromise();
    }

    getLetture(devEui: string): Promise<any> {
        return this._http.post<any>(`${environment.baseUrl}api/client/getLetture/byDevEui`, { devEui })
            .toPromise();
    }

    getAlarms(devEui: string): Promise<any> {
        return this._http.post<any>(`${environment.baseUrl}api/client/getAllarmi/all`, { devEuis: [devEui] })
            .toPromise();
    }
}
