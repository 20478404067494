<mat-toolbar class="sub-toolbar mat-elevation-z4">
  <app-search-input (search)="onSearchChanged($event)"></app-search-input>

  <div>
    <button mat-icon-button (click)="filtersSidenav.toggle()" matTooltip="Apri/Chiudi pannello filtri">
      <mat-icon>filter_list</mat-icon>
    </button>
  </div>
</mat-toolbar>

<mat-sidenav-container>

  <mat-sidenav #filtersSidenav mode="side" position="end">
    <app-filters [filters]="filters" (filtersChanged)="onFiltersUpdated($event)"></app-filters>
  </mat-sidenav>

  <mat-sidenav-content *ngIf="loader">
    <div class="h100 container">
      <mat-progress-bar *ngIf="(loader.loading | async)" mode="indeterminate" color="accent"></mat-progress-bar>

      <div infiniteScroll [scrollWindow]="false" (scrolled)="onTableScrolled()" [infiniteScrollDistance]="1"
        class="table-container">
        <mat-table matSort matSortActive="id" matSortDirection="asc" (matSortChange)="onSort($event)"
          [dataSource]="loader" class="mat-elevation-z8">

          <ng-container *ngFor="let col of columns" [matColumnDef]="col.name">
            <mat-header-cell [style.display]="isToShow(col.name) ? '' : 'none'" mat-sort-header
              *matHeaderCellDef>{{col.title}}</mat-header-cell>
            <mat-cell [style.display]="isToShow(col.name) ? '' : 'none'"
              *matCellDef="let lettura">{{getDate(lettura[col.name], col.name)}}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
          <mat-row mat-ripple *matRowDef="let lettura; columns: displayedColumns;" class="selectable-row"
            (click)="onSelected(lettura)"></mat-row>
        </mat-table>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>