import {Comparison} from './comparison';

export abstract class Logical {
    args: (Comparison | Logical)[];
    type: LogicalTypes;

    constructor(args: (Comparison | Logical)[] = []) {
        this.args = args;
    }
}

enum LogicalTypes {
    AND = 'and',
    OR = 'or'
}

export class And extends Logical {
    type = LogicalTypes.AND;
}

export class Or extends Logical {
    type = LogicalTypes.OR;
}
