import { Injectable } from '@angular/core';
import { DataLoaderProviderService } from './data-loader-provider.service';
import { ImmutableDataLoader } from '../../models/data-loader';
import { Lettura } from '../../models/raw/lettura';

@Injectable({
  providedIn: 'root'
})
export class LettureService {

  public loader: ImmutableDataLoader<Lettura>;

  constructor(private dataLoaderProvider: DataLoaderProviderService) {
    this.loader = this.dataLoaderProvider.getLettureLoader(50, 'get');
  }
}
