import {Component, HostBinding, HostListener, OnInit} from '@angular/core';
import {LettureService} from '../../../services/letture.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Lettura} from '../../../../models/raw/lettura';
import {environment} from '../../../../environments/environment';
import {FormControl, FormGroup} from '@angular/forms';
import {LoaderStatus} from '../../../../models/data-loader';
import {take} from 'rxjs/operators';
import {MapService} from '../../../services/map.service';
import {HttpClient} from '@angular/common/http';
import {LoadingOverlayService} from '../../../services/loading-overlay.service';
import {AppaltiService} from '../../../services/appalti.service';
import {SimpleAlertDialogComponent} from '../../dialogs/simple-alert-dialog/simple-alert-dialog.component';
import {MatButtonToggleChange} from '@angular/material/button-toggle';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ArticlesOfLetturaDialogComponent} from '../../dialogs/articles-of-lettura-dialog/articles-of-lettura-dialog.component';

@Component({
  selector: 'app-lettura',
  templateUrl: './lettura.component.html',
  styleUrls: ['./lettura.component.scss']
})
export class LetturaComponent implements OnInit {
  @HostBinding('class') classes = 'flex-column-fill';

  public lettura: Lettura;
  public photos: string[];
  public latitude: number;
  public longitude: number;

  public previousLettura: Lettura = null;
  public nextLettura: Lettura = null;

  public isLoadingPreviousLetture = false;
  public previousLetture: Lettura[] = [];
  public readonly previousLettureColumns = [
    'progressivo',
    'data',
    'cifre',
    'lettura',
    'consumo',
    'flag',
    'segn1',
    'segn2',
    'terminale',
    'pdr',
    'codice_utente',
    'matricola',
  ];

  public detailsFormGroup: FormGroup;
  public detailsConfiguration: any[];

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private dialog: MatDialog,
              private loadingOverlay: LoadingOverlayService, private appaltiService: AppaltiService,
              public lettureService: LettureService, public mapService: MapService,
              private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      const letture = this.lettureService.loader.items.getValue();

      if (letture) {
        const index = letture.findIndex(
          (lettura: Lettura) => lettura?.id === parseInt(params.lettura_id, 10)
        );
        this.lettura = letture[index];
        if (this.lettura) {
          this.previousLettura = index > 0 ? letture[index - 1] : null;
          this.nextLettura = index < letture.length - 1 ? letture[index + 1] : null;

          this.latitude = this.lettura?.latitudine ? parseFloat(this.lettura?.latitudine.replace(',', '.')) : null;
          this.longitude = this.lettura?.longitudine ? parseFloat(this.lettura?.longitudine.replace(',', '.')) : null;

          this.detailsConfiguration = null;
          const appalto = this.appaltiService.appalti.find(a => a.appalto_id === this.lettura?.appalto_id);
          if (appalto && appalto.configurazione_edita_lettura) {
            this.detailsConfiguration = appalto.configurazione_edita_lettura;
          }

          this.resetForm();
          this.resetPhotos();

          if (this.nextLettura === null) {
            this.lettureService.loader.status.pipe(take(1)).subscribe(status => {
              if (status === LoaderStatus.IDLE) {
                this.lettureService.loader.loadNext().then(() => {
                  this.nextLettura = index < letture.length - 1 ? letture[index + 1] : null;
                });
              }
            });
          }

          this.previousLetture = [];
          this.isLoadingPreviousLetture = true;
          this.http.get<Lettura[]>(`${environment.baseUrl}api/client/letture/${this.lettura?.id}/previous`)
            .toPromise().then(previousLetture => {
            this.isLoadingPreviousLetture = false;
            this.previousLetture = previousLetture;
          });
        }
      }
    });
  }

  @HostListener('document:keyup', ['$event'])
  public onKeyboardKeyDown(event: KeyboardEvent): void {
    if (!event.ctrlKey) {
      return;
    }
    switch (event.code) {
      case 'ArrowLeft':
        if (this.previousLettura) {
          this.router.navigate(['letture', this.previousLettura.id]);
        }
        break;

      case 'ArrowRight':
        if (this.nextLettura) {
          this.router.navigate(['letture', this.nextLettura.id]);
        }
        break;
    }
  }

  public resetForm(): void {
    this.detailsFormGroup = new FormGroup({});
    if (!this.detailsConfiguration) {
      return;
    }

    for (const configuration of this.detailsConfiguration) {
      let value;
      if (configuration.field.startsWith('attivita.')) {
        const attivita = this.lettura.attivita.find(a => a.act_name === configuration.field.substring(
          9, configuration.field.indexOf('.', 9)
        ));
        if (attivita) {
          value = attivita[configuration.field.substring(configuration.field.lastIndexOf('.') + 1)];
        }
      } else if (configuration.field.startsWith('rilevazione.')) {
        const rilevazione = this.lettura.rilevazioni.find(a => a.tipo_rilevazione === configuration.field.substring(
          12, configuration.field.indexOf('.', 12)
        ));
        if (rilevazione) {
          value = rilevazione[configuration.field.substring(configuration.field.lastIndexOf('.') + 1)];
        }
      } else {
        value = this.lettura[configuration.field];
      }

      this.detailsFormGroup.addControl(
        configuration.field,
        new FormControl({value: value, disabled: this.lettura.stato === 'LET' ? !configuration.isEditable : true})
      );
    }
  }

  private resetPhotos(): void {
    this.photos = [];
    for (let i = 1; i <= 6; i++) {
      if (this.lettura[`flag_foto_${i}`] > 0) {
        this.photos.push(`${environment.baseUrl}api/client/letture/${this.lettura.id}/photos/${i}`);
      }
    }
  }

  public onFlagChanged(event: MatButtonToggleChange): void {
    this.lettura.flag = event.value;
  }

  public onResetButtonClicked(): void {
    this.dialog.open(SimpleAlertDialogComponent, {
      data: {
        title: 'Attenzione!',
        content: 'Sei sicuro di voler reimpostare questa attività?',
      }
    }).afterClosed().toPromise().then(value => {
      if (value === true) {
        this.loadingOverlay.show();
        this.http.patch<Lettura>(`${environment.baseUrl}api/client/letture/${this.lettura.id}/reset`, null).toPromise().then(lettura => {
          const letture = this.lettureService.loader.items.getValue();
          letture.splice(letture.indexOf(this.lettura), 1, lettura);
          this.lettureService.loader.items.next(letture);

          this.lettura = lettura;
          this.resetForm();
          this.resetPhotos();
        }).finally(() => {
          this.loadingOverlay.hide();
        });
      }
    });
  }

  public async onSaveButtonClicked(): Promise<void> {
    const body = {};
    for (const configuration of this.detailsConfiguration.filter(conf => conf.isEditable)) {
      if (this.detailsFormGroup.controls[configuration.field].pristine) {
        continue;
      }
      body[configuration.field] = this.detailsFormGroup.controls[configuration.field].value;
    }
    try {
      this.loadingOverlay.show();
      await this.http.post<Lettura>(
        `${environment.baseUrl}api/client/letture/${this.lettura.id}/update?XDEBUG_SESSION_START=debug`,
        body
      ).toPromise();
      this.snackBar.open('Lettura aggiornata con successo!', null, {duration: 5000});
    } catch (e) {
      this.snackBar.open('Impossibili completare l\' operazione per la lettura indicata. Riprova più tardi.', null, {duration: 5000});
    }
    this.loadingOverlay.hide();
  }

  public async onArticlesOfLetturaButtonClicked(): Promise<void> {
    try {
      this.loadingOverlay.show();
      // tslint:disable-next-line:max-line-length
      const articoli = await this.http.get(`${environment.baseUrl}api/client/magazzino_consumabili/${this.lettura?.id}/articles_of_lettura`).toPromise();
      this.loadingOverlay.hide();
      await this.dialog.open(ArticlesOfLetturaDialogComponent, {data: {articoli}}).afterClosed().toPromise();
    } catch (e) {
      this.snackBar.open('Impossibili completare l\' operazione della lettura selezionata. Riprova più tardi.', null, {duration: 5000});
      this.loadingOverlay.hide();
    }
  }
}
