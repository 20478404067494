<app-search-input #appSearchInput (search)="querySubject.next($event)"
  [loading]="entityLoader && (entityLoader.loading | async)" [satPopoverAnchor]="popOver"
  (keyup)="onAppSearchInputKeyboardKeyUp($event)"></app-search-input>


<sat-popover #popOver verticalAlign="below" hasBackdrop>
  <mat-selection-list #list id="results-container" *ngIf="entityLoader && (entityLoader.items | async).length"
    (selectionChange)="onEntitySelected($event)">
    <mat-list-option *ngFor="let entity of entityLoader.items | async" [value]="entity">
      <ng-template [ngTemplateOutlet]="entityTemplate" [ngTemplateOutletContext]="{$implicit: entity}"></ng-template>
    </mat-list-option>
  </mat-selection-list>
</sat-popover>