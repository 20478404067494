<mat-toolbar class="sub-toolbar mat-elevation-z4" fxLayout="row" fxLayoutAlign="space-between center">
  <button mat-icon-button (click)="readersSidenav.toggle()" matTooltip="Apri/Chiudi pannello operatori">
    <mat-icon>person</mat-icon>
  </button>

  <app-search-input (search)="onSearchChanged($event)"></app-search-input>

  <div>
    <span style="margin-right: 20px; font: Roboto, 'Helvetica Neue', sans-serif;font-size: 16px ">Records Trovati:
      {{total$ | async}}</span>
    <button mat-icon-button matTooltip="Cambia visualizzazione" [matMenuTriggerFor]="visualizationsMenu">
      <mat-icon>view_column</mat-icon>
    </button>
    <mat-menu #visualizationsMenu>
      <button mat-menu-item>Default</button>
    </mat-menu>

    <button mat-icon-button (click)="filtersSidenav.toggle()" matTooltip="Apri/Chiudi pannello filtri">
      <mat-icon>filter_list</mat-icon>
    </button>
  </div>
</mat-toolbar>
<mat-sidenav-container>
  <mat-sidenav #readersSidenav mode="side">
    <app-operators-dashboard (operatorClicked)="onLetturistaSelected($event)"></app-operators-dashboard>
  </mat-sidenav>

  <mat-sidenav #filtersSidenav mode="side" position="end">
    <!-- <app-map-filters (filters)="onFiltersUpdated($event)"></app-map-filters> -->
    <app-filters [filters]="filters" (filtersChanged)="onFiltersUpdated($event)"></app-filters>
  </mat-sidenav>

  <mat-sidenav-content *ngIf="loader">
    <div class="h100 container">
      <mat-progress-bar *ngIf="(loader.loading | async)" mode="indeterminate" color="accent"></mat-progress-bar>

      <div infiniteScroll [scrollWindow]="false" (scrolled)="onTableScrolled()" [infiniteScrollDistance]="1"
        class="table-container">
        <mat-table matSort matSortActive="id" matSortDirection="desc" (matSortChange)="onSort($event)"
          [dataSource]="loader" class="mat-elevation-z8">
          <ng-container matColumnDef="id">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Id</mat-header-cell>
            <mat-cell *matCellDef="let lettura">{{lettura.id}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="progressivo">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Progressivo</mat-header-cell>
            <mat-cell *matCellDef="let lettura">{{lettura.progressivo}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="sequenza">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Sequenza</mat-header-cell>
            <mat-cell *matCellDef="let lettura">{{lettura.sequenza}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="utente">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Utente</mat-header-cell>
            <mat-cell *matCellDef="let lettura">{{lettura.utente}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="matricola">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Matricola</mat-header-cell>
            <mat-cell *matCellDef="let lettura">{{lettura.matricola}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="data_ora_appuntamento">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Data Appuntamento</mat-header-cell>
            <mat-cell *matCellDef="let lettura">{{lettura.data_ora_appuntamento | momentDate}}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
          <mat-row mat-ripple *matRowDef="let lettura; columns: displayedColumns;" class="selectable-row"
            (click)="onLetturaSelected(lettura)"></mat-row>
        </mat-table>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>