<form [formGroup]="loginForm" (ngSubmit)="login()" fxLayout="column">
  <img id="logo" src="assets/logo.svg"/>
  <mat-form-field appearance="outline">
    <input matInput placeholder="Username" formControlName="username" required>
    <mat-icon matSuffix color="primary">account_box</mat-icon>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <input matInput placeholder="Password" formControlName="password" type="password" name="password" required>
    <mat-icon matSuffix color="primary">lock</mat-icon>
  </mat-form-field>

  <button mat-raised-button type="submit" color="primary" [disabled]="isLoading">Conferma</button>
  <mat-spinner *ngIf="isLoading" diameter="48"></mat-spinner>
</form>
