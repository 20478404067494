import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MagazzinoConsumabile } from '../../../../models/raw/magazzino-consumabile';
import { environment } from '../../../../environments/environment';
import { LoadingOverlayService } from '../../../services/loading-overlay.service';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-consumabili-terminali-dialog',
  templateUrl: './consumabili-terminali-dialog.component.html',
  styleUrls: ['./consumabili-terminali-dialog.component.scss']
})
export class ConsumabiliTerminaliDialogComponent {

  public displayedColumns: string[] = ['terminale', 'quantita', 'actions'];

  constructor(@Inject(MAT_DIALOG_DATA) public data: PayLoad,
    private dialogRef: MatDialogRef<ConsumabiliTerminaliDialogComponent>,
    private loadingOverlay: LoadingOverlayService,
    private http: HttpClient,
    private snackBar: MatSnackBar) {
  }

  public closeButtonClicked(): void {
    this.dialogRef.close(false);
  }

  public async onRemoveTerminalButtonButtonClicked(articolo: Articolo): Promise<void> {
    try {
      this.loadingOverlay.show();
      await this.http.delete(`${environment.baseUrl}api/client/magazzino_consumabili_terminali/${articolo.id}`).toPromise();
      this.snackBar.open('Articolo rimosso al terminale con successo!', null, { duration: 5000 });

      this.loadingOverlay.hide();
      this.dialogRef.close(true);

    } catch (e) {
      this.snackBar.open('Impossibili completare l\' operazione dell\' articolo selezionato. Riprova più tardi.', null, { duration: 5000 });
    }
  }

}

export interface PayLoad {
  articoli: Articolo[];
  consumabile: MagazzinoConsumabile;
}

interface Articolo {
  id: string;
  magazzino_consumabile_id: number;
  terminale: number;
  quantita: number;
  created_at: string;
  updated_at: string;
}
